/*----------------------------------------*/
/*  29. Brand CSS
/*----------------------------------------*/

/*-- Brand Slider --*/
.brand-slider {
    & .slick-list {
        & .slick-track {
            display: flex;
            align-items: center;
        }
    }
    & .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: -15px;
        z-index: 9;
        border: none;
        background-color: $white;
        color: $theme-color;
        padding: 10px;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        text-align: center;
        box-shadow: 0 5px 10px rgba($black, 0.15);
        opacity: 0;
        & i {
            font-size: 24px;
            line-height: 24px;
            display: block;
        }
        &.slick-prev {
            left: -7px;
            margin-left: 30px;
            // Responsive
            @media #{$laptop-device}{
                left: 8px;
            }
            @media #{$desktop-device}{
                left: 8px;
            }
            @media #{$tablet-device}{
                left: 8px;
            }
            @media #{$large-mobile}{
                left: 8px;
            }
        }
        &.slick-next {
            right: -7px;
            margin-right: 30px;
            // Responsive
            @media #{$laptop-device}{
                right: 8px;
            }
            @media #{$desktop-device}{
                right: 8px;
            }
            @media #{$tablet-device}{
                right: 8px;
            }
            @media #{$large-mobile}{
                right: 8px;
            }
        }
        &:hover {
            background-color: $theme-color;
            color: $white;
        }
    }
    &:hover {
        & .slick-arrow {
            opacity: 1;
            &.slick-prev {
                margin-left: 0;
            }
            &.slick-next {
                margin-right: 0;
            }
        }
    }
    & .slick-dots {
        width: 100%;
        padding: 0;
        margin: 10px 0 0;
        display: flex;
        justify-content: center;
        & li {
            margin: 0 5px;
            & button {
                display: block;
                padding: 0;
                width: 12px;
                height: 12px;
                border: none;
                background-color: $body-color-light;
                text-indent: -9999px;
                border-radius: 50px;
            }
            &.slick-active {
                & button {
                    background-color: $theme-color;
                }
            }
        }
    }
}

/*-- Brand --*/
.brand {
    text-align: center;
    padding: 50px;
    &.brand-two{
        & a{
            & img{
                opacity: 1;
            }
        }
        &:hover{
            & a{
                & img{
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }
    }
    // Responsive
    @media #{$laptop-device}{
        padding: 30px;
    }
    @media #{$large-mobile}{
        padding: 15px;
    }
    & a{
        display: block;
        position: relative;
        overflow: hidden;
        & img {
            max-width: 100%;
            margin: auto;
            transition: all 0.3s ease-in-out;
        }
    }
    &:hover{
        & a{
            & img{
                transform: scale(1.2);
            }
        }
    }
}
