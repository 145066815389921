/*---------------------------------------
    24. Newslatter CSS
-----------------------------------------*/
.newsletter-form{   
    position: relative;
    border-bottom: 1px solid $heading-color;
    width: 620px;
    max-width: 100%;
    margin: 0 auto;
    &.newsletter-form-two{
        width: 780px;
        max-width: 100%;
    }
    &.color-white{
        border-bottom: 1px solid $white;
        input{
            color: $white;
        }
        button{
            color: $white;
        }
    }

    input{
        width: 100%;
        border: none;
        line-height: 30px;
        height: 55px;
        padding: 10px 0px 10px 0px;
        padding-right: 115px;
        background-color: rgba(0,0,0,0);
        color: $heading-color;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.7px;

        @media #{$large-mobile}{
            height: 40px;
            padding-right: 150px;
        }

        @media #{$extra-small-mobile}{
            padding-right: 128px;
            font-size: 11px;
        }
    }

    button{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;   
        background: none;
        border: none;
        width: 200px;
        font-size: 16px;
        font-weight: 700;
        color: $heading-color;
        text-align: right;
        // Responsive
        @media #{$large-mobile}{
            width: 110px;
            font-size: 14px;
        }
        &:hover{
            color: #fc4f4f;
        }
    }
}