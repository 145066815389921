/*----------------------------------------*/
/*  07. Hamburger CSS
/*----------------------------------------*/
.hamburger-area{
    text-align: left;
    transition: all .52s ease;
    position: fixed;
    height: 100% !important;
    top: 0;
    margin: 0;
    z-index: 9;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.09);
    width: 470px;
    padding: 70px;
    color: $heading-color;
    background-color: $white;
    right: 0;
    transform: translateX(470px);
    -webkit-transform: translateX(470px);
    z-index: 99;
    // Responsive
    @media #{$desktop-device} {
        left: -280px;
        width: 280px;
        transform: inherit;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: $heading-color;
        color: $white;
        opacity: 0;
        padding: 0;
        padding-top: 70px;
    }
    @media #{$tablet-device} {
        left: -280px;
        width: 280px;
        transform: inherit;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: $heading-color;
        color: $white;
        opacity: 0;
        padding: 0;
        padding-top: 70px;
    }
    @media #{$large-mobile} {
        left: -280px;
        width: 280px;
        transform: inherit;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: $heading-color;
        color: $white;
        opacity: 0;
        padding: 0;
        padding-top: 70px;
    }

    &.is-visible{
        transform: translateX(0);
        -webkit-transform: translateX(0);


        @media #{$desktop-device} {
            left: 0;
            transform: inherit;
            opacity: 1;
            visibility: visible;
        }
        @media #{$tablet-device} {
            left: 0;
            transform: inherit;
            opacity: 1;
            visibility: visible;
        }
        @media #{$large-mobile} {
            left: 0;
            transform: inherit;
            opacity: 1;
            visibility: visible;
        }
    }
}





/* Hambeger Area */
.hamburger-area{
    .btn-close-search{
        position: absolute;
        left: 30px;
        font-size: 30px;
        line-height: 30px;
        height: 30px;
        top: 50px;
        transition: all .25s ease-out;

        @media #{$desktop-device} {
            top: 35px;
            right: 24px;
            left: auto;
            font-size: 20px;
        }
        @media #{$tablet-device} {
            top: 35px;
            right: 24px;
            left: auto;
            font-size: 20px;
        }
        @media #{$large-mobile} {
            top: 35px;
            right: 24px;
            left: auto;
            font-size: 20px;
        }


        button{
            padding: 0;
            border: 0 none;
            color: $heading-color;
            @media #{$desktop-device} {
                color: $white;
            }
            @media #{$tablet-device} {
                color: $white;
            }
            @media #{$large-mobile} {
                color: $white;
            }
            &:hover{
                transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                color: #fc4f4f;
            }
        }
    }

    .hamburger-menu-main{
        position: relative;
        height: 100%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        -webkit-flex-direction: column;
        .humberger-top{
            flex-grow: 2;
            -webkit-flex-grow: 2;
            .hum-mainmenu{
                ul{
                    margin-top: 20px;
                    margin-bottom: 10px;
                    li{
                        a{
                            font-weight: 400;
                            text-transform: capitalize;
                            font-size: 16px;
                            padding: 10px 0;
                            position: relative;
                            display: block;
                            color: $heading-color;
                            &:hover{
                                color: #fc4f4f;
                            }
                        }
                    }
                }
            }
        }
        .humberger-bottom{
            margin: 20px 0 0;
            .thumb{
                & img{
                    width: 180px;
                }
            }
            p{
                color: $heading-color;
                font-size: 14px;
                margin: 20px 0 30px;
            }
            .hm-address{
                li{
                    color: $heading-color;
                    font-size: 12px;
                    line-height: 22px;
                    a{
                        color: $heading-color;
                    }
                }
            }
            .hamburger-social{
                margin-top: 20px;
                margin-bottom: 10px;
                a{
                    font-size: 18px;
                    margin-right: 24px;
                    &:hover{
                        color: $theme-color-two;
                    }
                }
            }
            .hm-copyright{
                margin: 30px 0 0;
                p{
                    font-size: 12px;
                    line-height: 1.4074;
                    color: $heading-color;
                }
            }
        }
    }

}
.gmap{
    font-size: 12px;
    text-decoration: underline;
}



/* ================================
    Responsive Menu 
==================================*/


.menu-primary-menu-1{
    &.color-white{
        & > li{
            & > a{
                color: $white !important;
            }
            &:hover{
                & > a{
                    color: $theme-color-two !important;
                }
            }
            .sub-menu-two{
                background-color: $heading-color !important;
    
                > li{
                    width: 100%;
                    > a{
                        color: $white;
                        &:hover{
                            color: $theme-color-two !important;
                        }
                    }
                    &.has-dropdown-menu{
                        > a{
                            color: $white;
                            &.is-visiable{
                                color: $theme-color-two !important;
                            }
                        }
                    }
    
                    &.has-label--3 {
                        .lavel--3{
                            background-color: $heading-color !important;
                            li{
                                a{
                                    color: $white;
                                    &:hover{
                                        color: $theme-color-two !important;
                                    }
                                }
                            }
                        }
    
                    }
                    
                }
            }
        }
    }
    &.menu-11{
        & li{
            & a{
                color: $heading-color;
                display: inline-block;
            }
            &.has-dropdown-menu{
                > a{
                    position: relative;
                    &::after{
                        position: absolute;
                        content: "\f107";
                        font-family: FontAwesome;
                        right: -40px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 17px;
                        width: 50px;
                    }
                    &.is-visiable{
                        color: $theme-color-two;
                        &::after{
                            content: "\f106";
                        }
                    }
                }
            }
            .sub-menu-two{
                display: none;
                background-color: rgba(255,255,255,.05);
                padding: 5px 0;
                margin: 5px 0;
    
                > li{
                    width: 100%;
                    > a{
                        padding: 10px 30px;
                        font-size: 1em;
                        display: inline-block;
                        // Responsive 
                        @media #{$laptop-device}{
                            font-size: 14px;
                            line-height: 1;
                        }
                    }
                    &.has-dropdown-menu{
                        > a{
                            position: relative;
                            &::after{
                                position: absolute;
                                content: "\f107";
                                font-family: FontAwesome;
                                right: -10px;
                                top: 50%;
                                transform: translateY(-50%);
                                font-size: 17px;
                                width: 50px;
                            }
                            &.is-visiable{
                                color: $theme-color-two;
                                &::after{
                                    content: "\f106";
                                }
                            }
                        }
                    }
    
                    &.has-label--3 {
                        .lavel--3{
                            display: none;
                            background-color: rgba(255,255,255,.05);
                            padding: 5px 0;
                            margin: 5px 0;
                            li{
                                width: 100%;
                                a{
                                    padding: 10px 30px;
                                    font-size: 1em;
                                    padding-right: 40px;
                                    padding-left: 40px;
                                    display: block;
                                    // Responsive 
                                    @media #{$laptop-device}{
                                        font-size: 14px;
                                        line-height: 1;
                                    }
                                }
                            }
                        }
    
                    }
                    
                }
            }
        }
    }
    li{
        a{
            color: silver;
            &:hover{
                color: $theme-color-two;
            }
        }
        &.has-dropdown-menu{
            > a{
                position: relative;
                &::after{
                    position: absolute;
                    content: "\f107";
                    font-family: FontAwesome;
                    right: 0px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 17px;
                    width: 50px;
                }
                &.is-visiable{
                    color: $theme-color-two;
                    &::after{
                        content: "\f106";
                    }
                }
            }
        }
    }

    > li{
        > a{
            display: block;
            padding: 14px 20px;
            font-size: 16px;
        }
        .sub-menu-two{
            display: none;
            background-color: rgba(255,255,255,.05);
            padding: 5px 0;
            margin: 5px 0;

            > li{
                width: 100%;
                > a{
                    padding: 10px 30px;
                    font-size: 1em;
                    display: block;
                }

                &.has-label--3 {
                    .lavel--3{
                        display: none;
                        background-color: rgba(255,255,255,.05);
                        padding: 5px 0;
                        margin: 5px 0;
                        li{
                            width: 100%;
                            a{
                                padding: 10px 30px;
                                font-size: 1em;
                                padding-right: 40px;
                                padding-left: 40px;
                                display: block;
                                
                            }
                        }
                    }

                }
                
            }
        }
    }
}







































