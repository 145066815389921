/*----------------------------------------*/
/*  22. Instagram CSS
/*----------------------------------------*/

.instafeed  {
    & ul{
        display: flex;
        flex-wrap: wrap;
    }
    & li{
        flex: 0 0 25%;
        max-width: 25%;
        width: 100%;
        position: relative;
        overflow: hidden;
        // Responsive
        @media #{$large-mobile}{
            flex: 0 0 50%;
            max-width: 50%; 
        }
        & > a{
            position: relative;
            display: block;
            &::before{
                background-color: rgba(52, 53, 56, 0.4);
                z-index: 9;
                pointer-events: none;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                content: "";
                right: 0;
                bottom: 0;
                -webkit-transition: all .3s ease-out;
                -o-transition: all .3s ease-out;
                transition: all .3s ease-out;
                opacity: 0;
                visibility: hidden;
            }
            & img{
                width: 100%;
            }
        }
        & .item-info{
            & .inner{
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                color: #fff;
                text-align: center;
                transform: translateY(-50%);
                margin: 0;
                z-index: 99;
                opacity: 0;
                visibility: hidden;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                & a{
                    position: relative;
                    color: #fff;
                    font-size: 16px;
                    display: inline-block;
                    margin: 0 10px;
                    & i{
                        padding-right: 4px;
                    }
                }
            }
        }
        &:hover{
            & a{
                &::before{
                    opacity: 1;
                    visibility: visible;
                }
            }
            & .item-info{
                & .inner{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}