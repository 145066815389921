/*-----------------------------------------------------------------------------------

    Template Name: Raz || Furniture Store HTML5 Template
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Theme default CSS
    02. Header CSS
    03. Hero CSS
    04. About CSS
    05. Feature CSS
    06. Banner CSS
    07. Hamburger CSS
    08. loading CSS
    09. Search CSS
    10. Testimonial CSS
    11. Product CSS
    12. Cart CSS
    13. Checkout CSS
    14. Wishlist CSS
    15. Blog CSS
    16. Comment CSS
    17. Sidebar CSS
    18. Coming Soon CSS
    19. Compare CSS
    20. Countdown CSS
    21. Faq CSS
    22. Instagram CSS
    23. My Account CSS
    24. Newslatter CSS
    25. Order CSS
    26. Portfolio CSS
    27. Team CSS
    28. Warranty CSS
    29. Brand CSS
    30. Contact CSS
    31. 404 CSS
    32. Modal CSS
    33. Footer CSS

-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Arimo:400,400i,700,700i|Playfair+Display:400,400i&display=swap");
/*-- Common Style --*/
*, *::after, *::before {
  box-sizing: border-box;
}

html, body, .site-wrapper {
  height: 100%;
}

body {
  background-color: #ffffff;
  font-size: 16px;
  line-height: 32px;
  font-style: normal;
  font-weight: normal;
  visibility: visible;
  font-family: "Arimo", sans-serif;
  color: #4d4d4d;
  position: relative;
}

body[data-rtl="rtl"] {
  direction: rtl;
  text-align: right;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Arimo", sans-serif;
  color: #1a1a1a;
  font-weight: 400;
  margin-top: 0;
  line-height: 1.2;
}

h1 {
  font-size: 36px;
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 575px) {
  h1 {
    font-size: 30px;
  }
}

h2 {
  font-size: 30px;
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 575px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p:last-child {
  margin-bottom: 0;
}

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a, button, img, input, .contact__form--3 select, .contact__form--3 textarea, span {
  transition: all 0.3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #1A1A1A;
}

button {
  background: transparent;
  border: 1px solid #d4d4d4;
  transition: all 0.4s ease-out 0s;
  color: #555555;
  cursor: pointer;
  line-height: normal;
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline;
}

button, input[type="submit"], .contact__form--3 select[type="submit"], .contact__form--3 textarea[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.coustom-col-4 {
  flex: 0 0 25%;
  max-width: 25%;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .coustom-col-4 {
    flex: 0 0 33%;
    max-width: 33%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coustom-col-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coustom-col-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .coustom-col-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .coustom-col-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .coustom-col-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.bg-image {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.height-150 {
  height: 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .height-150 {
    height: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .height-150 {
    height: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .height-150 {
    height: 100px;
  }
}

.height-100vh {
  height: 100vh;
}

@media only screen and (max-width: 767px) {
  .height-100vh {
    height: 100%;
  }
}

.fz-18 {
  font-size: 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fz-18 {
    font-size: 16px;
    line-height: 26px;
  }
}

.image-fixd {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.plr-300 {
  padding: 0 300px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .plr-300 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .plr-300 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .plr-300 {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .plr-300 {
    padding: 0 15px;
  }
}

.plr-250 {
  padding: 0 250px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .plr-250 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .plr-250 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .plr-250 {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .plr-250 {
    padding: 0 15px;
  }
}

.plr-190 {
  padding: 0 190px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .plr-190 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .plr-190 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .plr-190 {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .plr-190 {
    padding: 0 15px;
  }
}

.col-60 {
  flex: 0 0 60%;
  max-width: 60%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-60 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .col-60 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .col-60 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .col-60 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-40 {
  flex: 0 0 40%;
  max-width: 40%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-40 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .col-40 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .col-40 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .col-40 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

#scrollUp {
  background: #222;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100%;
  bottom: 25px;
  right: 25px;
  color: #fff;
  text-align: center;
  font-size: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#scrollUp:hover {
  background: #1A1A1A;
}

.box-layout {
  width: 1370px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-repeat: repeat;
  background-position: left top;
  background-attachment: scroll;
  background-color: #ffffff;
}

.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section, .main-wrapper {
  float: left;
  width: 100%;
}

.border-bottom {
  border-bottom: 1px solid #D8D8D8 !important;
}

.border-bottom-1 {
  border-bottom: 1px solid #eee !important;
}

.demo-text p {
  line-height: 1.5;
}

.demo-text blockquote {
  border: 2px solid #1A1A1A;
  border-left-width: 10px;
  padding: 20px;
  font-size: 18px;
  margin: 24px 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .demo-text blockquote {
    margin: 20px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .demo-text blockquote {
    margin: 10px 15px;
  }
}

.overlay {
  position: relative;
  z-index: 9;
  background-position: 50% 50%;
}

.overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  z-index: -1;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
  .row-five-column > [class*="col-xl-"] {
    max-width: 20%;
    flex: 0 0 20%;
  }
}

@media only screen and (max-width: 575px) {
  .container {
    max-width: 450px;
  }
}

@media only screen and (max-width: 479px) {
  .container {
    max-width: 300px;
  }
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
  margin: 0 !important;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder, .contact__form--3 select:-moz-placeholder, .contact__form--3 textarea:-moz-placeholder, textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder, .contact__form--3 select::-webkit-input-placeholder, .contact__form--3 textarea::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder, .contact__form--3 select::-moz-placeholder, .contact__form--3 textarea::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder, .contact__form--3 select:-ms-input-placeholder, .contact__form--3 textarea:-ms-input-placeholder, textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/*--
    - Background Color
------------------------------------------*/
.bg-white {
  background-color: #ffffff !important;
}

.bg-gray {
  background-color: #f9f9f9 !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bg-md-gray {
    background-color: #e7e7e7 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-md-gray {
    background-color: #e7e7e7 !important;
  }
}

@media only screen and (max-width: 767px) {
  .bg-md-gray {
    background-color: #e7e7e7 !important;
  }
}

.extra-bg {
  background-color: transparent;
  background-image: linear-gradient(180deg, #eff3ef 80%, #ffffff 0%);
}

.bg-gray-two {
  background-color: #eeeeee !important;
}

.bg-black {
  background-color: #151517;
}

.bg-black-light {
  background-color: #2f2f2f;
}

.bg-dark {
  background-color: #1a1a1a !important;
}

.bg-dark-two {
  background-color: #1c2535 !important;
}

.bg-dark-three {
  background-color: #1d2736 !important;
}

.bg-dark-four {
  background-color: #1d2634 !important;
}

.bg-skyblue {
  background-color: #f3fbff !important;
}

.allbgcolor {
  background-color: #f9f9f9;
}

.bg-theme {
  background-color: #1A1A1A !important;
}

/*-- 
    - Tab Content & Pane Fix
------------------------------------------*/
.tab-content {
  width: 100%;
}

.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/*-- 
    - Main Wrapper
------------------------------------------*/
/*-- 
    - Section Title
------------------------------------------*/
.section-title.color-white h2 {
  color: #ffffff;
}

.section-title.before-none h2::before {
  display: none;
}

.section-title.text-bold h2 {
  font-size: 56px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 78px;
  padding: 0;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.text-bold h2 {
    font-size: 32px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title.text-bold h2 {
    font-size: 26px;
    line-height: 33px;
  }
}

.section-title.text-normal h2 {
  font-size: 56px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 78px;
  padding: 0;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.text-normal h2 {
    font-size: 32px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title.text-normal h2 {
    font-size: 26px;
    line-height: 33px;
  }
}

.section-title h2 {
  position: relative;
  font-size: 46px;
  line-height: 64px;
  padding: 0 0 22px 0;
  margin-bottom: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title h2 {
    font-size: 32px;
    line-height: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h2 {
    font-size: 32px;
    line-height: 45px;
    padding: 0 0 15px 0;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title h2 {
    font-size: 26px;
    line-height: 33px;
    padding: 0 0 12px 0;
    margin-bottom: 15px;
  }
}

.section-title h2::before {
  content: "";
  bottom: 0;
  width: 75px;
  height: 5px;
  background-image: linear-gradient(to right, #DFD3D3, #FEDBC4);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  margin: auto;
}

.section-title p {
  max-width: 750px;
}

@media only screen and (max-width: 767px) {
  .section-title p {
    font-size: 14px;
    line-height: 24px;
  }
}

.section-title.full-width p {
  margin-bottom: 35px;
  max-width: 100%;
}

.section-title.text-center {
  text-align: center;
  background-position: top center;
}

.section-title.text-center p {
  margin-left: auto;
  margin-right: auto;
}

.section-title.text-left {
  text-align: left;
  background-position: top left;
}

.section-title.text-left p {
  margin-left: 0;
  margin-right: auto;
}

.section-title.text-right {
  text-align: right;
  background-position: top right;
}

.section-title.text-right p {
  margin-left: auto;
  margin-right: 0;
}

.section-title-two.color-white h2 {
  color: #ffffff;
}

.section-title-two.color-white p {
  color: #ffffff;
}

.section-title-two h2 {
  font-size: 56px;
  line-height: 78px;
  margin-bottom: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-two h2 {
    font-size: 32px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-two h2 {
    font-size: 26px;
    line-height: 33px;
  }
}

.section-title-two p {
  font-size: 18px;
  line-height: 36px;
  max-width: 530px;
  margin: auto;
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-two p {
    font-size: 16px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-two p {
    font-size: 16px;
    line-height: 32px;
  }
}

.section-title-three {
  position: relative;
}

.section-title-three.color-white h2 {
  color: #ffffff;
}

.section-title-three.before-none::before {
  display: none;
}

.section-title-three::before {
  position: absolute;
  height: 30px;
  width: 4px;
  left: calc(50% - 2px);
  background: #1a1a1a;
  content: "";
  bottom: 0;
}

.section-title-three h2 {
  font-size: 56px;
  line-height: 78px;
  margin-bottom: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-three h2 {
    font-size: 38px;
    line-height: 53px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-three h2 {
    font-size: 32px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-three h2 {
    font-size: 26px;
    line-height: 33px;
  }
}

.section-title-three p {
  font-size: 18px;
  line-height: 36px;
  max-width: 530px;
  margin: auto;
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-three p {
    font-size: 16px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-three p {
    font-size: 16px;
    line-height: 32px;
  }
}

.section-title-four h2 {
  font-size: 48px;
  line-height: 64px;
  margin-bottom: 30px;
  position: relative;
}

.section-title-four h2::before {
  content: '';
  position: absolute;
  top: 85px;
  left: 0;
  right: 0;
  text-align: center;
  width: 80px;
  height: 2px;
  background: #c2c2c2;
  margin: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-four h2::before {
    top: 65px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-four h2::before {
    top: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-four h2::before {
    top: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-four h2 {
    font-size: 38px;
    line-height: 53px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-four h2 {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-four h2 {
    font-size: 26px;
    line-height: 33px;
    margin-bottom: 20px;
  }
}

.section-title-four p {
  font-size: 18px;
  line-height: 36px;
  max-width: 530px;
  margin: auto;
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-four p {
    font-size: 16px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-four p {
    font-size: 14px;
    line-height: 26px;
  }
}

.section-title-five .section-title-icon {
  margin-bottom: 15px;
}

.section-title-five .section-title-icon i {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 26px;
  text-align: center;
  border: 1px solid #1a1a1a;
  border-radius: 50%;
}

.section-title-five h2 {
  font-size: 32px;
  line-height: 45px;
  display: inline-block;
  position: relative;
  padding: 0 140px;
}

@media only screen and (max-width: 767px) {
  .section-title-five h2 {
    font-size: 20px;
    line-height: 28px;
    padding: 0 50px;
  }
}

.section-title-five h2::before {
  background-color: #D8D8D8;
  width: 70px;
  height: 2px;
  content: "";
  position: absolute;
  top: .7em;
  left: 0px;
}

@media only screen and (max-width: 767px) {
  .section-title-five h2::before {
    width: 30px;
    top: .6em;
  }
}

.section-title-five h2::after {
  background-color: #D8D8D8;
  width: 70px;
  height: 2px;
  content: "";
  position: absolute;
  top: .7em;
  right: 0px;
}

@media only screen and (max-width: 767px) {
  .section-title-five h2::after {
    width: 30px;
    top: .6em;
  }
}

.section-title-six h2 {
  font-size: 36px;
  line-height: 50px;
  margin-bottom: 30px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-six h2 {
    font-size: 36px;
    line-height: 53px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-six h2 {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-six h2 {
    font-size: 26px;
    line-height: 33px;
    margin-bottom: 20px;
  }
}

/*-- 
    - Tab  Menu
------------------------------------------*/
.tab-menu ul li {
  margin: 0 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .tab-menu ul li {
    margin: 0 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tab-menu ul li {
    margin: 0 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-menu ul li {
    margin: 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-menu ul li {
    margin: 0 25px;
  }
}

.tab-menu ul li a {
  position: relative;
  width: 210px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 46px;
  font-weight: 400;
  line-height: 51px;
  padding-bottom: 22px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .tab-menu ul li a {
    width: 100%;
    text-align: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tab-menu ul li a {
    font-size: 32px;
    line-height: 35px;
    width: 100%;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-menu ul li a {
    font-size: 32px;
    line-height: 35px;
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .tab-menu ul li a {
    font-size: 24px;
    line-height: 30px;
    width: 100%;
    text-align: center;
    padding: 10px 0px;
  }
}

.tab-menu ul li a::before {
  content: "";
  bottom: 0;
  width: 25px;
  height: 5px;
  background-image: linear-gradient(to right, #DFD3D3, #FEDBC4);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  margin: auto;
  opacity: 0;
  transition: all 0.3s;
}

.tab-menu ul li a:hover::before, .tab-menu ul li a.active::before {
  opacity: 1;
  width: 75px;
  height: 5px;
}

.tab-menu-two {
  margin-top: 20px;
}

.tab-menu-two.border-radius ul li a {
  font-size: 16px;
  padding: 15px 30px 15px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tab-menu-two.border-radius ul li a {
    font-size: 14px;
    padding: 12px 25px 12px 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-menu-two.border-radius ul li a {
    font-size: 14px;
    padding: 10px 20px 10px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-menu-two.border-radius ul li a {
    font-size: 14px;
    padding: 8px 15px 8px 15px;
  }
}

.tab-menu-two.border-radius ul li a.active {
  border: 1px solid #b4b4b4;
  border-radius: 50px;
  font-weight: 400;
}

.tab-menu-two.color-white ul li a {
  color: #b4b4b4;
}

.tab-menu-two.color-white ul li a.active {
  color: #ffffff;
}

.tab-menu-two.border-radius-bold ul li a {
  font-size: 18px;
  font-weight: 700;
  padding: 20px 40px 20px 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .tab-menu-two.border-radius-bold ul li a {
    padding: 15px 35px 15px 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tab-menu-two.border-radius-bold ul li a {
    font-size: 14px;
    padding: 12px 25px 12px 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-menu-two.border-radius-bold ul li a {
    font-size: 14px;
    padding: 10px 20px 10px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-menu-two.border-radius-bold ul li a {
    font-size: 14px;
    padding: 8px 15px 8px 15px;
  }
}

.tab-menu-two.border-radius-bold ul li a.active {
  border: 1px solid #b4b4b4;
  border-radius: 50px;
  font-weight: 700;
}

.tab-menu-two.text-bold ul li a {
  padding: 20px 30px 20px 30px;
  font-weight: 700;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .tab-menu-two.text-bold ul li a {
    font-size: 14px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tab-menu-two.text-bold ul li a {
    font-size: 14px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-menu-two.text-bold ul li a {
    font-size: 14px;
    line-height: 24px;
    padding: 8px 15px 8px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-menu-two.text-bold ul li a {
    font-size: 14px;
    line-height: 24px;
    padding: 8px 15px 8px 15px;
  }
}

.tab-menu-two ul li a {
  padding: 20px 45px 20px 45px;
  color: #1a1a1a;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .tab-menu-two ul li a {
    padding: 15px 35px 15px 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tab-menu-two ul li a {
    padding: 10px 20px 10px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-menu-two ul li a {
    padding: 8px 15px 8px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-menu-two ul li a {
    padding: 8px 15px 8px 15px;
  }
}

.tab-menu-two ul li a.active {
  border: 1px solid #b4b4b4;
}

.tab-menu-three ul li {
  padding: 20px 40px 20px 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .tab-menu-three ul li {
    margin: 0 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-menu-three ul li {
    padding: 10px 20px 10px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-menu-three ul li {
    padding: 5px 10px 5px 10px;
  }
}

.tab-menu-three ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 46px;
  font-weight: 400;
  line-height: 78px;
  color: #b4b4b4;
  padding-bottom: 22px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .tab-menu-three ul li a {
    width: 100%;
    text-align: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tab-menu-three ul li a {
    font-size: 32px;
    line-height: 35px;
    width: 100%;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-menu-three ul li a {
    font-size: 32px;
    line-height: 35px;
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .tab-menu-three ul li a {
    font-size: 16px;
    line-height: 30px;
    width: 100%;
    text-align: center;
    padding: 10px 0px;
  }
}

.tab-menu-three ul li a::before {
  content: "";
  bottom: 0;
  width: 25px;
  height: 2px;
  background-color: #C2C2C2;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  margin: auto;
  opacity: 0;
  transition: all 0.3s;
}

.tab-menu-three ul li a:hover, .tab-menu-three ul li a.active {
  color: #1a1a1a;
}

.tab-menu-three ul li a:hover::before, .tab-menu-three ul li a.active::before {
  opacity: 1;
  width: 80px;
  height: 2px;
}

@media only screen and (max-width: 767px) {
  .tab-menu-three ul li a:hover::before, .tab-menu-three ul li a.active::before {
    width: 45px;
  }
}

.filter-menu {
  margin-top: 20px;
}

.filter-menu ul li button {
  padding: 20px 45px 20px 45px;
  color: #1a1a1a;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 30px;
  font-weight: 700;
  border: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .filter-menu ul li button {
    padding: 15px 35px 15px 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .filter-menu ul li button {
    padding: 10px 20px 10px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .filter-menu ul li button {
    padding: 8px 15px 8px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .filter-menu ul li button {
    padding: 8px 15px 8px 15px;
  }
}

.filter-menu ul li button.is-checked {
  border: 1px solid #b4b4b4;
  background-color: #f9f9f9;
  border-radius: 50px;
}

/*-- 
    - Read  Button
------------------------------------------*/
.read-btn {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  padding: 0px 140px 0px 0px;
}

.read-btn.border {
  border: 0 !important;
  padding: 0px 0px 0px 140px;
}

.read-btn.border::before {
  right: auto;
  left: 0;
}

@media only screen and (max-width: 767px) {
  .read-btn.border-none::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .read-btn {
    font-size: 14px;
    line-height: 24px;
    padding-right: 0;
  }
}

.read-btn::before {
  position: absolute;
  right: 0;
  top: 9px;
  content: "";
  background: #1a1a1a;
  width: 120px;
  height: 2px;
}

.read-btn:hover {
  text-decoration: underline;
}

.view-more-btn {
  text-align: center;
}

.view-more-btn a {
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  color: #1a1a1a;
}

.view-more-btn a:hover {
  color: #fc4f4f;
}

.load-more-btn a {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1em;
  color: #1a1a1a;
  background-color: rgba(0, 0, 0, 0);
  padding: 15px 40px 15px 40px;
  border: 1px solid rgba(0, 0, 0, 0.31);
}

.load-more-btn a:hover {
  color: #ffffff;
  background-color: #1a1a1a;
}

.load-more-btn-two.color-white a {
  border-color: rgba(255, 255, 255, 0.3);
  color: #ffffff;
  font-size: 14px;
  line-height: 14px;
}

.load-more-btn-two.color-white a:hover {
  color: #000000;
  background-color: #ffe49e;
  border-color: #ffe49e;
}

.load-more-btn-two a {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1em;
  color: #1a1a1a;
  background-color: rgba(0, 0, 0, 0);
  padding: 25px 60px 25px 60px;
  border: 2px solid rgba(0, 0, 0, 0.31);
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .load-more-btn-two a {
    padding: 20px 50px 20px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .load-more-btn-two a {
    padding: 15px 40px 15px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .load-more-btn-two a {
    font-size: 14px;
    padding: 15px 40px 15px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .load-more-btn-two a {
    font-size: 12px;
    padding: 10px 30px 10px 30px;
  }
}

.load-more-btn-two a:hover {
  color: #ffffff;
  background-color: #1a1a1a;
}

.work-btn {
  padding: 45px 0px 45px 0px;
  display: block;
  background-color: #7e7e7e;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
}

.work-btn:hover {
  color: #ffffff;
}

.animate-btn {
  position: relative;
  background: rgba(0, 0, 0, 0) !important;
  border: 2px solid rgba(0, 0, 0, 0.3);
  padding: 25px 60px 25px 60px;
  font-size: 16px;
  text-transform: uppercase;
  color: #1a1a1a;
  line-height: 1;
  font-weight: 700;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .animate-btn {
    padding: 20px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .animate-btn {
    padding: 20px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .animate-btn {
    padding: 15px 30px 15px 30px;
  }
}

.animate-btn::after {
  content: "";
  position: absolute;
  height: 0%;
  left: 50%;
  top: 50%;
  width: 150%;
  z-index: -1;
  transition: all 0.75s ease 0s;
}

.animate-btn::before {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: '';
  background: #1a1a1a;
  color: #ffffff;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

.animate-btn:hover {
  background: transparent !important;
  border-color: #1a1a1a;
  color: #ffffff;
}

.animate-btn:hover::before {
  right: 0%;
  left: auto;
  width: 100%;
}

.shop-btn {
  position: relative;
  background: #1a1a1a;
  border: 2px solid #1a1a1a;
  padding: 20px 40px;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 1;
  font-weight: 700;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-btn {
    padding: 20px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-btn {
    padding: 20px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-btn {
    padding: 15px 30px 15px 30px;
  }
}

.shop-btn:hover {
  background: #ffffff;
  border-color: #ffffff;
  color: #1a1a1a;
}

.submit-btn {
  min-width: 120px;
  padding: 15px 18px;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  text-align: center;
}

.submit-btn:hover {
  background-color: #fc4f4f;
  border-color: #fc4f4f;
  color: #ffffff;
}

.cart-btn {
  border: 0;
  padding: 0 10px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: #1a1a1a;
}

.cart-btn:hover {
  color: #fc4f4f;
}

.load-more-area {
  text-align: center;
}

.load-more-area a {
  font-size: 20px;
  line-height: 34px;
  color: #1a1a1a;
  display: inline-block;
}

.load-more-area a:hover {
  color: #fc4f4f;
  text-decoration: underline;
}

/*-- 
    - Button
------------------------------------------*/
.btn {
  background-color: rgba(0, 0, 0, 0);
  font-family: "Arimo", sans-serif;
  color: #1a1a1a;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  padding: 20px 60px 20px 60px;
  text-transform: capitalize;
  border: 2px solid rgba(0, 0, 0, 0.34);
  border-radius: 30px;
  position: relative;
  transition: all 0.3s ease 0s;
}

.btn:focus {
  box-shadow: none;
  outline: none;
}

.btn i {
  margin-left: 5px;
  font-size: 14px;
}

.btn:hover {
  background-color: #1a1a1a;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .btn {
    font-size: 13px;
    line-height: 1.2;
    padding: 10px 15px;
  }
  .btn:hover::before {
    left: 6px;
    top: 6px;
  }
  .btn:hover::after {
    left: -6px;
    top: -6px;
  }
}

/* Pentagon Icon CSS */
.pentagon-icon .icon {
  position: relative;
  width: 50px;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: #1A1A1A;
  border-radius: 6px;
  margin: 0 20px;
}

@media only screen and (max-width: 767px) {
  .pentagon-icon .icon {
    width: 38px;
    height: 57px;
  }
}

.pentagon-icon .icon::before, .pentagon-icon .icon::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #1A1A1A;
  transform: rotate(60deg);
  z-index: -1;
  border-radius: 6px;
}

.pentagon-icon .icon::after {
  transform: rotate(-60deg);
}

.pentagon-icon .icon i {
  font-size: 26px;
  color: #1a1a1a;
}

/*-- 
    - Page Banner Section
------------------------------------------*/
.breadcrumb-section {
  padding: 70px 0 72px;
  background-repeat: repeat;
  background-position: left top;
  background-color: #f9f9f9;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-section {
    padding: 60px 0 62px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-section {
    padding: 55px 0 52px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-section {
    padding: 50px 0 42px;
  }
}

.breadcrumb-section-two {
  padding-top: 260px;
  padding-bottom: 190px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .breadcrumb-section-two {
    padding-top: 200px;
    padding-bottom: 130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-section-two {
    padding: 60px 0 62px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-section-two {
    padding: 55px 0 52px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-section-two {
    padding: 50px 0 42px;
  }
}

.breadcrumb-title h1 {
  font-size: 46px;
  line-height: 54px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-title h1 {
    font-size: 36px;
    line-height: 1;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-title h1 {
    font-size: 30px;
    line-height: 1;
  }
}

@media only screen and (max-width: 575px) {
  .breadcrumb-title h1 {
    font-size: 24px;
    line-height: 1;
  }
}

.breadcrumb-title-two h1 {
  font-size: 56px;
  line-height: 65px;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-title-two h1 {
    font-size: 36px;
    line-height: 1;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-title-two h1 {
    font-size: 30px;
    line-height: 1;
  }
}

@media only screen and (max-width: 575px) {
  .breadcrumb-title-two h1 {
    font-size: 24px;
    line-height: 1;
  }
}

.page-breadcrumb {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.page-breadcrumb li {
  color: #1a1a1a;
  font-size: 14px;
  font-family: "Arimo", sans-serif;
  line-height: 24px;
  margin-top: 10px;
}

.page-breadcrumb li::after {
  content: ">";
  margin: 0 10px;
}

.page-breadcrumb li:last-child::after {
  display: none;
}

.page-breadcrumb li a:hover {
  color: #fc4f4f;
}

.page-breadcrumb-two {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.page-breadcrumb-two li {
  color: #ffffff;
  font-size: 14px;
  font-family: "Arimo", sans-serif;
  line-height: 24px;
  margin-top: 10px;
}

.page-breadcrumb-two li::after {
  content: ">";
  margin: 0 10px;
}

.page-breadcrumb-two li:last-child::after {
  display: none;
}

.page-breadcrumb-two li a:hover {
  color: #fc4f4f;
}

.page-breadcrumb-three {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px 0;
}

.page-breadcrumb-three li {
  color: #1a1a1a;
  font-size: 16px;
  font-family: "Arimo", sans-serif;
  line-height: 27px;
  margin-top: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-breadcrumb-three li {
    font-size: 14px;
    line-height: 18px;
  }
}

.page-breadcrumb-three li::after {
  content: ">";
  margin: 0 10px;
}

.page-breadcrumb-three li:last-child::after {
  display: none;
}

.page-breadcrumb-three li a:hover {
  color: #fc4f4f;
}

/*-- 
    - Page Pagination
------------------------------------------*/
.page-pagination {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 20px 0;
}

.page-pagination li {
  margin: 0 5px 0 0;
}

.page-pagination li a {
  font-size: 16px;
  text-align: center;
  display: block;
  position: relative;
  background: #fff;
  padding: 15px 10px;
  line-height: 20px;
  min-width: 50px;
  transition: all .3s;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #535353;
}

.page-pagination li:hover a {
  color: #ffffff;
  background-color: #1a1a1a;
}

.page-pagination li.active a {
  color: #ffffff;
  background-color: #1a1a1a;
}

.grid-filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.grid-filter button {
  background-color: transparent;
  color: #1a1a1a;
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  margin: 0 10px 10px;
  text-transform: capitalize;
  line-height: 1;
  padding-bottom: 5px;
  position: relative;
}

.grid-filter button::before {
  content: "";
  height: 6px;
  width: 0;
  position: absolute;
  left: 0;
  bottom: 4px;
  background-color: #1A1A1A;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.grid-filter button:hover::before, .grid-filter button.active::before {
  width: 100%;
}

.grid-filter.center {
  justify-content: center;
}

.grid-filter.center button {
  margin: 0 10px 10px;
}

.grid-filter.left {
  justify-content: flex-start;
}

.grid-filter.left button {
  margin-left: 0;
  margin-right: 20px;
  margin-bottom: 10px;
}

.grid-filter.left button:last-child {
  margin-right: 0;
}

.grid-filter.right {
  justify-content: flex-end;
}

.grid-filter.right button {
  margin-left: 20px;
  margin-right: 0;
  margin-bottom: 10px;
}

.grid-filter.right button:last-child {
  margin-left: 0;
}

.slick-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  border: none;
  background-color: white;
  color: #1a1a1a;
  padding: 10px;
  width: 60px;
  height: 60px;
  border-radius: 0;
  text-align: center;
  opacity: 0;
}

.slick-slider .slick-arrow i {
  font-size: 24px;
  line-height: 30px;
  display: block;
}

.slick-slider .slick-arrow.slick-prev {
  left: 40px;
  margin-left: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .slick-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slick-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slick-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .slick-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

.slick-slider .slick-arrow.slick-next {
  right: 40px;
  margin-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .slick-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slick-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slick-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .slick-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

.slick-slider .slick-arrow:hover {
  background-color: rgba(255, 255, 255, 0.75);
  color: #1a1a1a;
}

.slick-slider:hover .slick-arrow {
  opacity: 1;
}

.slick-slider:hover .slick-arrow.slick-prev {
  margin-left: 0;
}

.slick-slider:hover .slick-arrow.slick-next {
  margin-right: 0;
}

.slick-slider .slick-dots {
  width: 100%;
  padding: 0;
  margin: 35px 0 0;
  display: flex;
  justify-content: center;
}

.slick-slider .slick-dots li {
  margin: 0 15px;
}

.slick-slider .slick-dots li button {
  width: 14px;
  height: 14px;
  position: absolute;
  background: rgba(153, 153, 153, 0);
  border: 2px solid #b4b4b4;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: content-box;
  box-shadow: 0px 0px 2px 1px rgba(130, 130, 130, 0.3);
  padding: 0;
  text-indent: -9999px;
}

.slick-slider .slick-dots li.slick-active button {
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.gallery-item {
  position: relative;
}

.gallery-item::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #1a1a1a;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.gallery-item img {
  width: 100%;
}

.gallery-item .plus {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 3;
  opacity: 0;
}

.gallery-item .plus::before, .gallery-item .plus::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #ffffff;
  transition: all 0.3s ease 0s;
}

.gallery-item .plus::before {
  width: 150px;
  height: 1px;
}

.gallery-item .plus::after {
  width: 1px;
  height: 150px;
}

.gallery-item:hover::before {
  opacity: 0.75;
}

.gallery-item:hover .plus {
  opacity: 1;
}

.gallery-item:hover .plus::before {
  width: 40px;
}

.gallery-item:hover .plus::after {
  height: 40px;
}

blockquote.blockquote {
  background-color: #f1f2f3;
  padding: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

blockquote.blockquote::before {
  position: absolute;
  content: url(../images/icons/quote-left.png);
  left: -5px;
  top: -10px;
  z-index: -1;
  opacity: 0.07;
}

blockquote.blockquote p {
  font-size: 18px;
  font-style: italic;
}

blockquote.blockquote .author {
  font-size: 14px;
  display: block;
  line-height: 18px;
}

/*----------------------------------------*/
/*  02. Header CSS
/*----------------------------------------*/
header.header {
  background-color: #ffffff;
  position: static;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
}

header.header.is-sticky {
  position: fixed;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: slideInDown;
  animation-duration: 0.5s;
}

header.header.is-sticky .header-top {
  display: none;
}

header.header.is-sticky .header-middle {
  display: none;
}

header.header.is-sticky .main-menu.main-menu-two {
  position: static;
  margin-bottom: 0;
}

.header-transparent {
  background-color: transparent !important;
}

.header-absolute {
  position: absolute !important;
  background: transparent !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-absolute {
    position: static !important;
    background: #ffffff !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-absolute {
    position: static !important;
    background: #ffffff !important;
  }
}

@media only screen and (max-width: 767px) {
  .header-absolute {
    position: static !important;
    background: #ffffff !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bg-transparent {
    background: transparent !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-transparent {
    background: transparent !important;
  }
}

@media only screen and (max-width: 767px) {
  .bg-transparent {
    background: transparent !important;
  }
}

/* Header Top */
.header-info ul li {
  display: inline-block;
}

.header-info ul li:last-child {
  margin-left: 50px;
}

.header-info ul li span {
  font-size: 16px;
  color: #1a1a1a;
}

.header-search-area form {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin-left: auto;
  max-width: 350px;
  position: relative;
}

.header-search-area form input, .header-search-area form .contact__form--3 select, .contact__form--3 .header-search-area form select, .header-search-area form .contact__form--3 textarea, .contact__form--3 .header-search-area form textarea {
  padding: 10px 0;
  padding-right: 40px;
  border: none;
  font-size: 18px;
  font-weight: 300;
  color: #1a1a1a;
  background: transparent;
  width: 100%;
  height: 100%;
}

.header-search-area form button {
  text-align: right;
  background-color: transparent;
  border: none;
  color: #1a1a1a;
  width: 60px;
  height: 100%;
  right: 0;
  font-size: 20px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
}

/* Header Bottom */
.logo a {
  display: block;
}

.logo a img {
  width: 125px;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .logo a img {
    width: 90px;
  }
}

/*-- Main Menu --*/
.main-menu.color-white > ul > li::before {
  color: #ffffff;
}

.main-menu.color-white > ul > li > a {
  color: #ffffff;
}

.main-menu.color-white > ul > li:hover::before {
  color: #fc4f4f;
}

.main-menu.color-white > ul > li:hover > a {
  color: #fc4f4f;
}

.main-menu > ul {
  display: flex;
}

.main-menu > ul > li {
  position: relative;
  margin: 0 10px;
  padding: 0 25px;
}

.main-menu > ul > li::before {
  content: "\f107";
  font-family: 'FontAwesome';
  font-size: 18px;
  color: inherit;
  font-weight: 600;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.main-menu > ul > li > a {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #1a1a1a;
  display: flex;
  align-items: center;
  position: relative;
  height: 150px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .main-menu > ul > li > a {
    margin: 0;
  }
}

.main-menu > ul > li > a::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 60%;
  width: 20%;
  border-bottom: 2px solid;
  content: "";
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
}

.main-menu > ul > li:hover > .sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
  z-index: 99;
}

.main-menu > ul > li:hover > .mega-menu {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
  z-index: 99;
}

.main-menu > ul > li:last-child .sub-menu {
  left: auto;
  right: 0;
}

.main-menu > ul > li:last-child .sub-menu .sub-menu .sub-menu {
  left: 100%;
  right: auto;
}

.main-menu > ul > li:last-child .sub-menu .sub-menu .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}

.main-menu > ul > li:nth-last-child(-n+3) .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}

.main-menu > ul > li:nth-last-child(-n+3) .sub-menu .sub-menu .sub-menu {
  left: 100%;
  right: auto;
}

.main-menu > ul > li.active, .main-menu > ul > li:hover {
  border-bottom-color: #1A1A1A;
}

.main-menu > ul > li.active > a, .main-menu > ul > li:hover > a {
  color: #1a1a1a;
}

.main-menu > ul > li.active > a::after, .main-menu > ul > li:hover > a::after {
  width: 100%;
  opacity: .8;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .main-menu.home-five-menu > ul > li {
    margin: 0 8px;
    padding: 0 20px;
  }
}

.main-menu.main-menu-10 > ul > li:first-child {
  margin-left: 0;
  padding-left: 0;
}

.main-menu.main-menu-10 > ul > li > a {
  height: 75px;
}

/*-- Sub Menu --*/
.sub-menu {
  position: absolute;
  left: 0;
  top: 120%;
  background-color: #1a1a1a;
  transition: all 0.3s ease 0s;
  width: 260px;
  padding: 30px 0;
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}

.sub-menu > li {
  position: relative;
  padding: 0px 40px;
  color: #B4B4B4;
}

.sub-menu > li > a {
  color: #B4B4B4;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0px;
  line-height: 20px;
  display: block;
}

.sub-menu > li.has-dropdown > a::after {
  content: "\f105";
  font-family: Fontawesome;
  line-height: 20px;
  float: right;
}

.sub-menu > li.active > a {
  color: #1A1A1A;
}

.sub-menu > li .sub-menu {
  left: 100%;
  top: 0;
}

.sub-menu > li:hover > a {
  color: #ffffff;
}

.sub-menu > li:hover > .sub-menu {
  margin-top: -3px;
  opacity: 1;
  visibility: visible;
  z-index: 99;
}

.sub-menu > li .sub-menu {
  left: 100%;
  margin-left: 0;
  top: 0;
}

.sub-menu > li .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}

.sub-menu > li .sub-menu .sub-menu .sub-menu {
  left: 100%;
  right: auto;
}

/*-- Mega Menu --*/
.mega-menu.three-column {
  width: 680px;
}

.mega-menu.three-column > li {
  width: 33.33%;
}

.mega-menu.four-column {
  width: 1170px;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mega-menu.four-column {
    width: 900px;
  }
}

.mega-menu.four-column > li {
  width: 20%;
}

.mega-menu.four-column .mega-menu-banner {
  width: 40%;
}

.mega-menu.left-40 {
  left: 40%;
  transform: translateX(-40%);
}

.mega-menu.left-35 {
  left: 35%;
  transform: translateX(-35%);
}

.mega-menu.left-0 {
  left: 0;
}

.mega-menu {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 30px;
  background-color: #1a1a1a;
  text-align: left;
  z-index: 999;
  width: 800px;
  padding-left: 45px;
  padding-right: 45px;
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  z-index: 99;
  transition: all 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.mega-menu > li {
  width: 25%;
  padding: 55px 20px;
  float: left;
}

.mega-menu > li:first-child {
  border-left: 0;
}

.mega-menu > li > a {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
  position: relative;
  color: #ffffff;
  padding: 10px 0px;
}

.mega-menu > li > ul > li {
  display: block;
}

.mega-menu > li > ul > li > a {
  color: #B4B4B4;
  font-size: 12px;
  font-weight: 400;
  padding: 10px 0px;
  line-height: 20px;
}

.mega-menu > li > ul > li > a:hover {
  color: #ffffff;
}

.header-icon {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
}

.header-icon.color-white a {
  color: #ffffff;
}

.header-icon.color-white a span.cart-count {
  background-color: #ffffff !important;
  color: #1a1a1a !important;
  z-index: 9;
}

.header-icon.color-white a:hover {
  color: #fc4f4f;
}

.header-icon a {
  display: inline-block;
  line-height: 30px;
  transition: all .2s ease;
  vertical-align: middle;
  color: #1a1a1a;
}

.header-icon a.popup-round {
  background-color: #fff;
  color: #111;
  height: 80px;
  line-height: 80px;
  width: 80px;
  text-align: center;
  border-radius: 100%;
  transition: all 0.3s ease-in-out;
}

.header-icon a.popup-round:hover {
  background-color: #1A1A1A;
  color: #fff;
}

.header-icon a.popup-round i {
  font-size: 28px;
}

.header-icon a i {
  display: inline-block;
  font-size: 24px;
  margin: 0 20px;
  vertical-align: middle;
}

@media only screen and (max-width: 767px) {
  .header-icon a i {
    margin: 0 10px;
  }
}

.header-icon.cart {
  justify-content: center;
}

.header-icon.cart a {
  display: block;
}

.header-icon.cart a span.cart-count {
  position: absolute;
  top: -15px;
  font-size: 10px;
  width: 25px;
  height: 25px;
  display: inline-block;
  line-height: 25px;
  color: #fff;
  text-align: center;
  right: 0;
  border-radius: 50px;
  background-color: #1A1A1A;
}

.header-icon.hamburger-menu.color-white > a i {
  color: #ffffff;
}

.header-icon.hamburger-menu.color-white > a:hover i {
  color: #fc4f4f;
}

.header-icon.hamburger-menu i {
  font-size: 35px;
  margin-right: 0;
}

.header-icon .hamburger-trigger.open i::before {
  content: '\ed2b';
}

/* Flash Sale CSS */
.flash-sale-area {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flash-sale-area span {
  font-size: 24px;
  line-height: 41px;
  color: #1a1a1a;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .flash-sale-area span {
    font-size: 22px;
    line-height: 38px;
  }
}

.flash-sale-area a {
  font-size: 24px;
  line-height: 41px;
  font-weight: 700;
  color: #fc4f4f;
  margin-left: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .flash-sale-area a {
    font-size: 22px;
    line-height: 38px;
  }
}

.flash-sale-area a:hover {
  color: #1a1a1a;
}

/* =====================
Popup Area 
======================*/
.popup-fly-over-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(8, 8, 8, 0.9);
  z-index: 999;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-fly-over-wrapper.is-visiable {
  opacity: 1;
  visibility: visible;
  transition: opacity .5s;
}

.popup-fly-over-wrapper .close-button {
  position: fixed;
  top: 15%;
  right: 6%;
}

.popup-fly-over-wrapper .close-button .close_btn {
  padding: 0;
  border: 0 none;
  font-size: 40px;
  background: transparent;
}

.popup-fly-over-wrapper .close-button .close_btn i {
  color: #ffffff;
}

.popup-fly-over-wrapper .popup-menu {
  max-width: 1330px;
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .popup-fly-over-wrapper .popup-menu {
    max-width: 1100px;
  }
}

.popup-fly-over-wrapper .popup-menu .mainmenu li {
  font-weight: 400;
  transition: all .3s ease;
  position: relative;
  color: #ffffff;
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.popup-fly-over-wrapper .popup-menu .mainmenu li.title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 5px;
}

.popup-fly-over-wrapper .popup-menu .mainmenu li a {
  color: #ffffff;
}

.popup-fly-over-wrapper .popup-menu .mainmenu li a:hover {
  color: #fc4f4f;
}

.popup-fly-over-wrapper .popup-menu .mainmenu li.drdropdown > a {
  position: relative;
  display: inline-block;
}

.popup-fly-over-wrapper .popup-menu .mainmenu li.drdropdown > a::before {
  position: absolute;
  content: "\f107";
  font-size: 12px;
  left: 100%;
  font-family: FontAwesome;
  padding-left: 15px;
  top: 3px;
}

.popup-fly-over-wrapper .popup-menu .mainmenu li.drdropdown > a.is-visiable {
  color: #fc4f4f;
}

.popup-fly-over-wrapper .popup-menu .mainmenu li.drdropdown > a.is-visiable::before {
  content: "\f106";
  color: #fc4f4f;
}

.popup-fly-over-wrapper .popup-menu .mainmenu li.drdropdown .drlabel2 {
  display: none;
  padding-top: 20px;
  padding-bottom: 20px;
}

/*=========================
    Golobal DropDown MEnu 
===========================*/
.drdropdown > a {
  position: relative;
  display: inline-block;
}

.drdropdown > a::before {
  position: absolute;
  content: "\f107";
  font-size: 12px;
  left: 100%;
  font-family: FontAwesome;
  padding-left: 15px;
  top: 3px;
}

.drdropdown > a.is-visiable::before {
  content: "\f106";
}

.drdropdown .drlabel2 {
  display: none;
  padding-top: 20px;
  padding-bottom: 20px;
}

/*===========================
    Sidebar Header 
=============================*/
.has-sidebarHeader {
  margin-left: 410px;
}

@media only screen and (max-width: 1199px) {
  .has-sidebarHeader {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .has-sidebarHeader {
    margin-left: 240px;
  }
}

.haeder-sidebar {
  position: relative;
  width: 100%;
  z-index: 4;
}

.haeder-sidebar .header-wrapper {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  padding: 90px 60px 50px;
  background-color: #ffffff;
  box-shadow: 0 0 60px 0 rgba(41, 44, 58, 0.06);
  width: 410px;
  z-index: 3;
  overflow-y: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .haeder-sidebar .header-wrapper {
    padding: 70px 30px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    width: 240px;
  }
}

@media only screen and (max-width: 1199px) {
  .haeder-sidebar .header-wrapper {
    width: 100%;
    padding: 0;
    position: static;
    height: 120px;
    top: 0;
    left: 0;
    right: 0;
  }
}

.haeder-sidebar .header-top {
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .haeder-sidebar .header-top {
    top: 35px;
    margin-left: 40px;
    position: absolute;
  }
}

.haeder-sidebar .header-top .logo a img {
  width: 120px;
}

@media only screen and (max-width: 1199px) {
  .haeder-sidebar .header-top .logo a img {
    width: 60px;
  }
}

.haeder-sidebar .header-right {
  position: absolute;
  right: 40px;
  top: 43px;
}

.haeder-sidebar .header-right i {
  font-size: 32px;
}

.haeder-sidebar .menu-primary-menu-1 {
  text-align: center;
}

.haeder-sidebar .menu-primary-menu-1 > li > a {
  padding: 20px 0;
  display: block;
  font-size: 32px;
  color: #262626;
  font-weight: 400;
  display: inline-block;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .haeder-sidebar .menu-primary-menu-1 > li > a {
    font-size: 24px;
    padding: 10px 0;
  }
}

.haeder-sidebar .menu-primary-menu-1 > li > a::before {
  position: absolute;
  left: 0;
  right: 0px;
  margin: auto;
  bottom: 20px;
  width: 20%;
  border-bottom: 2px solid;
  content: "";
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .haeder-sidebar .menu-primary-menu-1 > li > a::before {
    bottom: 10px;
  }
}

.haeder-sidebar .menu-primary-menu-1 > li > a.is-visiable:hover > a {
  color: #1A1A1A;
}

.haeder-sidebar .menu-primary-menu-1 > li:hover > a {
  color: #1A1A1A;
}

.haeder-sidebar .menu-primary-menu-1 > li:hover > a::before {
  width: 100%;
  opacity: .8;
}

.haeder-sidebar .menu-primary-menu-1 > li .sub-menu li a {
  padding: 8px 30px;
  color: #2f2f2f;
  padding-left: 80px;
}

.haeder-sidebar .menu-primary-menu-1 > li .sub-menu .lavel--3 li a {
  padding: 8px 30px;
  padding-left: 115px !important;
}

.header-social {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.header-social.color-white a i {
  color: #ffffff;
}

.header-social a {
  font-size: 21px;
  width: 38px;
  height: 38px;
  line-height: 21px;
  margin: 0 10px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .header-social a {
    margin-right: 10px;
  }
}

.header-social a:last-child {
  margin-right: 0;
}

.header-social a i {
  color: #1a1a1a;
}

.header-social a:hover i {
  color: #fc4f4f;
}

/*===========================
    MIni SIdebar Header 
=============================*/
.has-minisidebarHeader {
  margin-left: 120px;
}

@media only screen and (max-width: 1199px) {
  .has-minisidebarHeader {
    margin-left: 0;
  }
}

header.draven-minisidebar {
  position: relative;
  width: 100%;
  z-index: 4;
}

header.draven-minisidebar .header-wrapper {
  box-shadow: 0px 0px 60px 0px rgba(41, 44, 58, 0.06);
  background-color: #ffffff;
  width: 120px;
  height: 100%;
  position: fixed;
  left: 0;
  text-align: center;
  z-index: 2;
}

@media only screen and (max-width: 1199px) {
  header.draven-minisidebar .header-wrapper {
    width: 100%;
    height: 120px;
  }
}

header.draven-minisidebar .header-top {
  width: 60px;
  transform: none;
  margin: 0 auto;
  position: absolute;
  top: 40px;
  margin-left: 30px;
}

@media only screen and (max-width: 1199px) {
  header.draven-minisidebar .header-top {
    top: 35px;
    margin-left: 40px;
    width: 60px;
  }
}

header.draven-minisidebar .header-top .logo a img {
  width: 100%;
}

header.draven-minisidebar .header-center {
  position: absolute;
  top: 45%;
  font-size: 40px;
  left: calc(50% - 20px);
}

@media only screen and (max-width: 1199px) {
  header.draven-minisidebar .header-center {
    top: 33px;
    font-size: 40px;
    left: auto;
    right: 40px;
  }
}

header.draven-minisidebar .header-center .vertical-toggle-trigger {
  position: relative;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

header.draven-minisidebar .header-center .vertical-toggle-trigger::before {
  position: absolute;
  content: "\edb3";
  color: #555555;
  font-family: 'dliconoutline';
  transition: all 0.3s ease-in-out;
}

header.draven-minisidebar .header-center .vertical-toggle-trigger.is_visible::before {
  content: "\ed2b";
}

header.draven-minisidebar .minisidebar__menu {
  transition: all .52s ease;
  transform: translateX(-370px);
  -webkit-transform: translateX(-370px);
  padding: 40px;
  padding-left: 80px;
  padding-right: 80px;
  position: fixed;
  width: 370px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  min-height: 100%;
}

header.draven-minisidebar .minisidebar__menu.is_visible {
  transform: translateX(120px);
  -webkit-transform: translateX(120px);
}

header.draven-minisidebar .menu-primary-menu-1 {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

header.draven-minisidebar .menu-primary-menu-1 li a {
  color: #2f2f2f;
  font-size: 18px;
  font-weight: 700;
}

header.draven-minisidebar .menu-primary-menu-1 li.has-label--3 a {
  font-size: 14px;
  font-weight: 400;
}

.hamburger-trigger i {
  color: #555555;
}

.hamburger-trigger.open i::before {
  content: '\ed2b';
}

/*-- Mobile Menu --*/
.mobile-header-area {
  background-color: #111;
}

.mobile-cart a {
  font-size: 20px;
  margin-right: 30px;
  color: #1A1A1A;
}

.mobile-navigation-icon {
  width: 24px;
  height: 25px;
  position: relative;
  cursor: pointer;
  float: right;
}

.mobile-navigation-icon:hover i {
  background-color: #1A1A1A;
}

.mobile-navigation-icon:hover i:before {
  width: 80%;
  background-color: #1A1A1A;
}

.mobile-navigation-icon:hover i:after {
  background-color: #1A1A1A;
  width: 60%;
}

.mobile-navigation-icon i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background-color: #1A1A1A;
  transition: all 0.3s ease-in-out;
}

.mobile-navigation-icon i:before {
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #1A1A1A;
  content: "";
  transition: all 0.3s ease-in-out;
}

.mobile-navigation-icon i:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #1A1A1A;
  content: "";
  transition: all 0.3s ease-in-out;
}

/* offcanvas mobile menu */
.header-mobile-navigation {
  padding: 20px 0;
}

.header-mobile-navigation .mobile-navigation .header-cart-icon a span {
  left: 50%;
}

.offcanvas-widget-area {
  margin-bottom: 35px;
  margin-top: auto;
}

@media only screen and (max-width: 479px) {
  .offcanvas-widget-area {
    margin-bottom: 30px;
  }
}

.off-canvas-contact-widget {
  margin-bottom: 20px;
}

.off-canvas-contact-widget .header-contact-info {
  flex-basis: 33.33%;
}

.off-canvas-contact-widget .header-contact-info__list li {
  display: inline-block;
  margin-right: 25px;
}

.off-canvas-contact-widget .header-contact-info__list li i {
  font-size: 14px;
  margin-right: 5px;
}

.off-canvas-contact-widget .header-contact-info__list li a {
  color: #666;
  font-weight: 400;
  line-height: 1.5;
}

.off-canvas-contact-widget .header-contact-info__list li a:hover {
  color: #1A1A1A;
}

.off-canvas-contact-widget .header-contact-info__list li:last-child {
  margin-right: 0;
}

.offcanvas-mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  max-width: 100%;
  height: 100vh;
  z-index: 9999;
  transform: translateX(100%);
  padding-left: 60px;
  transition: 0.6s;
}

.offcanvas-mobile-menu.active {
  transform: translateX(0);
}

.offcanvas-mobile-menu.inactive {
  transform: translateX(calc(100% + 60px));
}

.offcanvas-menu-close {
  position: absolute;
  left: 0;
  top: 0;
  background: #343538;
  z-index: 9;
  width: 60px;
  height: 60px;
  color: #fff;
  line-height: 60px;
  text-align: center;
  font-size: 30px;
}

@media only screen and (max-width: 479px) {
  .offcanvas-menu-close {
    width: 50px;
    height: 50px;
    line-height: 45px;
    left: 10px;
    font-size: 25px;
  }
}

.offcanvas-menu-close:hover, .offcanvas-menu-close:focus {
  color: #ffffff;
}

.offcanvas-menu-close i {
  transition: 0.3s;
  transform: rotate(0);
}

.offcanvas-menu-close:hover i {
  transform: rotate(-90deg);
}

.offcanvas-wrapper {
  overflow: auto;
  height: 100%;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
}

.offcanvas-mobile-search-area {
  background-color: #e6e6e6;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 60px);
  z-index: 9;
  margin-left: 60px;
}

.offcanvas-mobile-search-area input, .offcanvas-mobile-search-area .contact__form--3 select, .contact__form--3 .offcanvas-mobile-search-area select, .offcanvas-mobile-search-area .contact__form--3 textarea, .contact__form--3 .offcanvas-mobile-search-area textarea {
  width: 100%;
  font-size: 16px;
  display: block;
  padding: 9px 25px;
  padding-right: 45px;
  color: #222;
  background: #e6e6e6;
  border: none;
}

@media only screen and (max-width: 479px) {
  .offcanvas-mobile-search-area input, .offcanvas-mobile-search-area .contact__form--3 select, .contact__form--3 .offcanvas-mobile-search-area select, .offcanvas-mobile-search-area .contact__form--3 textarea, .contact__form--3 .offcanvas-mobile-search-area textarea {
    font-size: 14px;
    padding: 5px 15px;
    padding-right: 45px;
  }
}

.offcanvas-mobile-search-area button {
  background: none;
  border: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  padding: 0;
}

.offcanvas-mobile-search-area button i {
  font-size: 18px;
  line-height: 40px;
}

.offcanvas-inner-content {
  padding: 90px 35px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

@media only screen and (max-width: 479px) {
  .offcanvas-inner-content {
    padding: 70px 25px 0;
  }
}

.offcanvas-navigation {
  margin-bottom: 50px;
}

.offcanvas-navigation > ul > li {
  margin: 0 -35px;
  padding: 0 35px;
  border-bottom: 1px solid #e6e6e6;
  background-color: #f5f5f5;
}

@media only screen and (max-width: 767px) {
  .offcanvas-navigation > ul > li {
    margin: 0 -25px;
    padding: 0 25px;
  }
}

.offcanvas-navigation > ul > li > a {
  font-size: 15px;
  color: #111;
  font-weight: 600;
  line-height: 1.5;
  padding: 10px 0;
}

.offcanvas-navigation > ul > li > a:hover {
  color: #1A1A1A;
}

@media only screen and (max-width: 479px) {
  .offcanvas-navigation > ul > li > a {
    font-size: 14px;
    line-height: 20px;
  }
}

.offcanvas-navigation ul.submenu2 {
  margin-left: 25px;
}

.offcanvas-navigation ul.submenu2 > li > a {
  font-size: 13px;
  color: #111;
  font-weight: 400;
  line-height: 1.5;
  padding: 10px 0;
}

.offcanvas-navigation ul.submenu2 > li > a:hover {
  color: #1A1A1A;
}

@media only screen and (max-width: 479px) {
  .offcanvas-navigation ul.submenu2 > li > a {
    font-size: 13px;
    line-height: 18px;
  }
}

.offcanvas-navigation ul li.menu-item-has-children {
  position: relative;
  display: block;
}

.offcanvas-navigation ul li.menu-item-has-children a {
  display: block;
}

.offcanvas-navigation ul li.menu-item-has-children.active > .menu-expand i:before {
  transform: rotate(0);
}

.offcanvas-navigation ul li.menu-item-has-children .menu-expand {
  position: absolute;
  right: auto;
  left: 90%;
  top: -5px;
  width: 30px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  text-align: center;
}

.offcanvas-navigation ul li.menu-item-has-children .menu-expand i {
  display: block;
  margin-top: 25px;
  border-bottom: 1px solid;
  position: relative;
  width: 10px;
  transition: all 250ms ease-out;
}

.offcanvas-navigation ul li.menu-item-has-children .menu-expand i:before {
  width: 100%;
  content: "";
  border-bottom: 1px solid;
  display: block;
  position: absolute;
  top: 0;
  transform: rotate(90deg);
}

.off-canvas-widget-social a {
  margin: 0 10px;
  font-size: 14px;
}

.off-canvas-widget-social a:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 479px) {
  .off-canvas-widget-social a {
    margin: 0 10px;
  }
}

.off-canvas-widget-social a:hover {
  color: #1A1A1A;
}

/* offcanvas settings */
.offcanvas-settings .offcanvas-navigation > ul > li > a {
  font-size: 12px;
  font-weight: 400;
  padding: 10px 0;
}

.offcanvas-settings .offcanvas-navigation > ul > li.menu-item-has-children .menu-expand {
  height: 30px;
  margin-top: 0;
}

.offcanvas-settings .offcanvas-navigation ul.submenu2 > li > a {
  padding: 5px 0;
}

/*=====  End of Header  ======*/
/*----------------------------------------*/
/*  03. Hero CSS
/*----------------------------------------*/
.pl-250 {
  padding-left: 250px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-250 {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-250 {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-250 {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .pl-250 {
    padding-left: 15px;
  }
}

.pr-250 {
  padding-right: 250px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-250 {
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-250 {
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-250 {
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .pr-250 {
    padding-right: 15px;
  }
}

.coustom-padding {
  padding: 100px 80px 0px 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .coustom-padding {
    padding: 80px 15px 0px 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coustom-padding {
    padding: 0px 15px 0px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coustom-padding {
    padding: 0px 15px 0px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .coustom-padding {
    padding: 0px 15px 0px 15px;
  }
}

/* Static Slider CSS */
.static-slider-content .title {
  font-size: 86px;
  line-height: 103px;
  font-weight: 700;
  margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .static-slider-content .title {
    font-size: 64px;
    line-height: 76px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .static-slider-content .title {
    font-size: 46px;
    line-height: 56px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .static-slider-content .title {
    font-size: 38px;
    line-height: 45px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .static-slider-content .title {
    font-size: 26px;
    line-height: 31px;
    margin-bottom: 20px;
  }
}

.static-slider-content p {
  font-size: 18px;
  line-height: 36px;
  max-width: 760px;
  margin-bottom: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .static-slider-content p {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .static-slider-content p {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .static-slider-content p {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 20px;
  }
}

.static-slider-image {
  border-radius: 50% 50% 50% 50%;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .static-slider-image {
    margin-top: 30px;
  }
}

.static-slider-image a {
  display: block;
  position: relative;
}

.static-slider-image a img {
  max-width: none;
  width: -webkit-calc(100% + 50px);
  width: calc(100% + 50px);
  transition: transform 0.35s;
  transform: translate3d(-40px, 0, 0);
}

.static-slider-image:hover a img {
  transform: translate3d(0, 0, 0);
}

/*-- Hero Item --*/
.hero-item {
  width: 100%;
  height: 760px;
  align-items: center;
  display: flex !important;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-item {
    height: 580px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-item {
    height: 580px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-item {
    height: 400px;
  }
}

/*-- Hero Content For Background Video --*/
.hero-motion-effects-one {
  position: absolute;
  top: 65%;
  left: 40px;
  transform: translateY(-60%);
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-motion-effects-one {
    left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-motion-effects-one {
    left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-motion-effects-one {
    left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-motion-effects-one {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .hero-motion-effects-one {
    display: none;
  }
}

@media only screen and (max-width: 479px) {
  .hero-motion-effects-one {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-motion-effects-one img {
    width: 220px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-motion-effects-one img {
    width: 170px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-motion-effects-one img {
    width: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-motion-effects-one img {
    width: 170px;
  }
}

.hero-motion-effects-tow {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-motion-effects-tow img {
    width: 255px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-motion-effects-tow img {
    width: 235px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-motion-effects-tow img {
    width: 235px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-motion-effects-tow img {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .hero-motion-effects-tow img {
    display: none;
  }
}

@media only screen and (max-width: 479px) {
  .hero-motion-effects-tow img {
    display: none;
  }
}

.hero-content {
  text-align: center;
  margin-top: 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content {
    margin-top: 130px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content {
    margin-top: 0px;
  }
}

.hero-content h1 {
  font-size: 66px;
  line-height: 74px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content h1 {
    font-size: 58px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content h1 {
    font-size: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content h1 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-content h1 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-content h1 {
    font-size: 24px;
    line-height: 30px;
  }
}

.hero-content p {
  font-size: 18px;
  line-height: 37px;
  max-width: 855px;
  margin: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content p {
    max-width: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content p {
    max-width: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content p {
    font-size: 16px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-content p {
    font-size: 14px;
    line-height: 24px;
  }
}

.hero-content a {
  font-size: 18px;
  line-height: 20px;
  margin-top: 145px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content a {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content a {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content a {
    font-size: 16px;
    margin-top: 30px;
  }
}

.hero-content a span {
  display: block;
  text-align: center;
}

.hero-content a span i {
  font-size: 25px;
  margin-top: 15px;
}

.hero-content a:hover {
  color: #fc4f4f;
}

/*----------------------------------------*/
/*  04. About CSS
/*----------------------------------------*/
/* About Intro Area CSS */
@media only screen and (max-width: 767px) {
  .about-area {
    text-align: center;
  }
}

.about-area .title {
  font-size: 46px;
  line-height: 51px;
  font-weight: 700;
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-area .title {
    font-size: 32px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-area .title {
    font-size: 32px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .about-area .title {
    font-size: 24px;
    line-height: 26px;
  }
}

.about-area p {
  font-size: 16px;
  line-height: 36px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .about-area p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 15px;
  }
}

.about-intro-image {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .about-intro-image {
    margin-top: 30px;
  }
}

.about-intro-image .about-image {
  text-align: right;
}

.about-intro-image .about-image.blog-image img {
  width: 100%;
}

.about-intro-image .about-image img {
  width: 455px;
}

@media only screen and (max-width: 767px) {
  .about-intro-image .about-image img {
    width: 100%;
  }
}

.about-intro-image .play-btn-area {
  position: absolute;
  left: -20px;
  bottom: 65px;
  text-align: left;
}

.about-intro-image .play-btn-area.center {
  left: 0;
  right: 0;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .about-intro-image .play-btn-area {
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
}

.about-intro-image .play-btn-area .play-btn {
  display: inline-block;
  border-radius: 50%;
  position: relative;
  transition: all .5s;
  text-align: left;
}

.about-intro-image .play-btn-area .play-btn::before {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  opacity: 0;
  transition: all .5s;
  z-index: 1;
}

.about-intro-image .play-btn-area .play-btn img {
  width: 90px;
  border-radius: 50%;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.18);
}

.about-intro-image .play-btn-area .play-btn:hover {
  background: rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: -10px;
}

.about-intro-image .play-btn-area .play-btn:hover::before {
  opacity: 1;
  left: -15px;
  right: -15px;
  top: -15px;
  bottom: -15px;
}

/* About Page Content */
.raz-about-content h2 {
  font-size: 46px;
  line-height: 51px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .raz-about-content h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .raz-about-content h2 {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 15px;
  }
}

.raz-about-content p {
  font-size: 16px;
  line-height: 36px;
  color: #1a1a1a;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .raz-about-content p {
    font-size: 14px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .raz-about-content p {
    font-size: 14px;
    line-height: 26px;
  }
}

.raz-about-content .about-list {
  padding: 40px 0px 40px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .raz-about-content .about-list {
    padding: 20px 0px 20px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .raz-about-content .about-list {
    padding: 20px 0px 20px 0px;
  }
}

.raz-about-content .about-list li {
  font-size: 16px;
  line-height: 9px;
  padding-bottom: 45px;
  color: #1a1a1a;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .raz-about-content .about-list li {
    font-size: 14px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .raz-about-content .about-list li {
    font-size: 14px;
    padding-bottom: 20px;
  }
}

.raz-about-content .about-list li i {
  color: #1a1a1a;
  font-size: 8px;
}

.raz-about-content .about-list li span {
  padding-left: 13px;
}

.raz-about-content .about-list li:last-child {
  padding-bottom: 0;
}

/* Single Choose Item CSS */
.single-choose-item {
  text-align: center;
}

.single-choose-item .chosse-icon {
  width: 45px;
  height: 45px;
  margin: auto;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .single-choose-item .chosse-icon {
    margin-bottom: 0;
  }
}

.single-choose-item .choose-content .title {
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  padding: 15px 0;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .single-choose-item .choose-content .title {
    font-size: 16px;
  }
}

.single-choose-item .choose-content p {
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 0;
}

/*----------------------------------------*/
/*  05. Feature CSS
/*----------------------------------------*/
/* Single Features CSS */
.single-feature {
  text-align: center;
}

.single-feature.style-two .feature-content .title {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 8px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-feature.style-two .feature-content .title {
    font-size: 14px;
    line-height: 19px;
  }
}

@media only screen and (max-width: 767px) {
  .single-feature.style-two .feature-content .title {
    font-size: 14px;
  }
}

.single-feature.style-two .feature-content p {
  color: #919191;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-feature.style-two .feature-content p {
    font-size: 12px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-feature.style-two .feature-content p {
    font-size: 12px;
  }
}

.single-feature .feature-icon {
  display: inline-block;
  line-height: 1;
  transition: all .5s;
  color: #1a1a1a;
  font-size: 50px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .single-feature .feature-icon {
    margin-bottom: 5px;
  }
}

.single-feature .feature-content .title {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {
  .single-feature .feature-content .title {
    font-size: 14px;
  }
}

.single-feature .feature-content p {
  color: #919191;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .single-feature .feature-content p {
    font-size: 12px;
  }
}

/*----------------------------------------*/
/*  06. Banner CSS
/*----------------------------------------*/
.height {
  height: 1020px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .height {
    height: 520px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .height {
    height: 480px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .height {
    height: 380px;
  }
}

@media only screen and (max-width: 767px) {
  .height {
    height: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .modern-decorate-banner-content {
    text-align: center;
  }
}

.modern-decorate-banner-content h2 {
  font-size: 56px;
  font-weight: 400;
  line-height: 74px;
  color: #ffffff;
  margin-left: 160px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .modern-decorate-banner-content h2 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modern-decorate-banner-content h2 {
    margin-left: 0px;
    font-size: 38px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-decorate-banner-content h2 {
    margin-left: 0px;
    font-size: 38px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .modern-decorate-banner-content h2 {
    margin-left: 0px;
    font-size: 24px;
    line-height: 38px;
  }
}

.modern-decorate-banner-content .shop-btn {
  margin-top: 20px;
  margin-left: 160px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .modern-decorate-banner-content .shop-btn {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modern-decorate-banner-content .shop-btn {
    margin-top: 0px;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-decorate-banner-content .shop-btn {
    margin-top: 0px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .modern-decorate-banner-content .shop-btn {
    margin-left: 0px;
    margin-top: 0px;
  }
}

.single-banner.color-white .title {
  font-size: 56px;
  font-weight: 400;
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 1px;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner.color-white .title {
    font-size: 32px;
    line-height: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner.color-white .title {
    font-size: 32px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .single-banner.color-white .title {
    font-size: 24px;
    line-height: 32px;
  }
}

.single-banner.color-white .title.left {
  left: 80px;
  transform: translateY(-85%);
  text-align: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner.color-white .title.left {
    left: 40px;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 767px) {
  .single-banner.color-white .title.left {
    left: 20px;
    transform: translateY(0);
  }
}

.single-banner.color-white .title.right {
  right: 80px;
  transform: translateY(-85%);
  text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner.color-white .title.right {
    right: 40px;
    transform: translateY(0);
  }
}

.single-banner.color-white-two .title {
  font-size: 36px;
  font-weight: 400;
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 1px;
  z-index: 9;
  bottom: auto;
  top: 85%;
  transform: translateY(-85%);
  left: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-banner.color-white-two .title {
    font-size: 28px;
    line-height: 39px;
    bottom: auto;
    top: 85%;
    left: 60px !important;
    transform: translateY(-85%) !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner.color-white-two .title {
    font-size: 28px;
    line-height: 39px;
    bottom: auto;
    top: 85%;
    left: 40px !important;
    transform: translateY(-85%) !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner.color-white-two .title {
    font-size: 28px;
    line-height: 39px;
    bottom: auto;
    top: 85%;
    left: 30px !important;
    transform: translateY(-85%) !important;
  }
}

@media only screen and (max-width: 767px) {
  .single-banner.color-white-two .title {
    font-size: 24px;
    line-height: 30px;
    bottom: auto;
    top: 85%;
    left: 20px !important;
    transform: translateY(-85%) !important;
  }
}

.single-banner.banner-style-two .title {
  font-size: 36px;
  line-height: 50px;
  font-weight: 400;
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 1px;
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-banner.banner-style-two .title {
    font-size: 30px;
    line-height: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner.banner-style-two .title {
    font-size: 22px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner.banner-style-two .title {
    font-size: 20px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .single-banner.banner-style-two .title {
    font-size: 20px;
    line-height: 26px;
  }
}

.single-banner.banner-style-two .title.left {
  display: block;
  bottom: auto;
  top: 100%;
  left: 50px;
  opacity: 0;
  text-align: left;
  transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-banner.banner-style-two .title.left {
    left: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner.banner-style-two .title.left {
    left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner.banner-style-two .title.left {
    left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .single-banner.banner-style-two .title.left {
    left: 20px;
  }
}

.single-banner.banner-style-two:hover .title.left {
  opacity: 1;
  transform: translateY(-100%);
  margin-top: -50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner.banner-style-two:hover .title.left {
    margin-top: -20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-banner.banner-style-two:hover .title.left {
    margin-top: -20px;
  }
}

.single-banner .banner-img a {
  display: block;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.single-banner .banner-img a::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1;
  opacity: 0;
  transition: .3s;
}

.single-banner .banner-img a img {
  width: 100%;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.single-banner .title {
  bottom: 10%;
  position: absolute;
  left: 0px;
  right: 0;
  transition: opacity 0.35s, transform 0.35s;
  max-width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  transition: .3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-banner .title {
    font-size: 18px;
    bottom: 5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner .title {
    font-size: 18px;
    bottom: 5%;
    letter-spacing: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner .title {
    font-size: 16px;
    bottom: 5%;
    letter-spacing: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .single-banner .title {
    font-size: 16px;
    bottom: 5%;
    letter-spacing: 0px;
  }
}

.single-banner .title.left {
  left: 80px;
  transform: translateY(-85%);
  text-align: left;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-banner .title.left {
    transform: translateY(0);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner .title.left {
    transform: translateY(0);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner .title.left {
    left: 10px;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 767px) {
  .single-banner .title.left {
    left: 10px;
    transform: translateY(0);
  }
}

.single-banner .title.right {
  right: 80px;
  transform: translateY(-85%);
  text-align: right;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-banner .title.right {
    transform: translateY(0);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner .title.right {
    transform: translateY(0);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner .title.right {
    right: 10px;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 767px) {
  .single-banner .title.right {
    right: 10px;
    transform: translateY(0);
  }
}

.single-banner:hover .banner-img a::before {
  opacity: 0.4;
}

.single-banner:hover .banner-img a img {
  transform: scale(1.1);
}

.single-banner:hover .title {
  letter-spacing: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner:hover .title {
    letter-spacing: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .single-banner:hover .title {
    letter-spacing: 0px;
  }
}

.single-content-banner {
  position: relative;
  overflow: hidden;
}

.single-content-banner .banner-images a {
  display: block;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.single-content-banner .banner-images a::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1;
  opacity: 0;
  transition: .3s;
}

.single-content-banner .banner-images a img {
  width: 100%;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.single-content-banner .banner-content {
  top: 100%;
  position: absolute;
  height: auto;
  left: 80px;
  width: 460px;
  max-width: 100%;
  background-color: rgba(26, 26, 26, 0.9);
  padding: 50px;
  z-index: 5;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-content-banner .banner-content {
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-content-banner .banner-content {
    padding: 15px;
    left: 0;
    width: 100%;
  }
}

.single-content-banner .banner-content.full-width {
  width: 100%;
  left: 0;
}

.single-content-banner .banner-content .title {
  position: relative;
  font-size: 32px;
  line-height: 45px;
  color: #ffffff;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-content-banner .banner-content .title {
    margin-bottom: 20px;
    padding-bottom: 20px;
    font-size: 26px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .single-content-banner .banner-content .title {
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 22px;
    line-height: 26px;
  }
}

.single-content-banner .banner-content .title::before {
  content: "";
  position: absolute;
  background: #808080;
  width: 60px;
  height: 2px;
  bottom: 0;
  left: 0;
}

.single-content-banner .banner-content p {
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-content-banner .banner-content p {
    font-size: 16px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .single-content-banner .banner-content p {
    font-size: 14px;
    line-height: 24px;
  }
}

.single-content-banner .banner-content a {
  font-size: 11px;
  line-height: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #ffffff;
  padding: 20px 0;
  margin-top: 24px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-content-banner .banner-content a {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .single-content-banner .banner-content a {
    padding: 10px 0;
    margin-top: 10px;
  }
}

.single-content-banner:hover .banner-images a::before {
  opacity: 0.4;
}

.single-content-banner:hover .banner-images a img {
  transform: scale(1.1);
}

.single-content-banner:hover .banner-content {
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
}

.single-content-banner:hover .banner-content.full-width {
  top: 100%;
  transform: translateY(-100%);
}

/* Shop Banner Css */
.single-shop-banner {
  position: relative;
  overflow: hidden;
}

.single-shop-banner .shop-banner-image {
  position: relative;
}

.single-shop-banner .shop-banner-image > a {
  display: block;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.single-shop-banner .shop-banner-image > a::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1;
  opacity: 0;
  transition: .3s;
}

.single-shop-banner .shop-banner-image > a img {
  width: 100%;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.single-shop-banner .shop-banner-image .shop-banner-content {
  bottom: 10%;
  position: absolute;
  left: 0px;
  right: 0;
  max-width: 80%;
  text-align: center;
  margin: 0px auto;
  z-index: 999;
  transition: all 0.3s ease-in-out;
}

.single-shop-banner .shop-banner-image .shop-banner-content span {
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.single-shop-banner .shop-banner-image .shop-banner-content .title {
  font-size: 46px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3em;
  letter-spacing: 2px;
  margin: 0px 0px 16px 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-shop-banner .shop-banner-image .shop-banner-content .title {
    font-size: 36px;
    line-height: 39px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-shop-banner .shop-banner-image .shop-banner-content .title {
    font-size: 36px;
    line-height: 39px;
  }
}

@media only screen and (max-width: 767px) {
  .single-shop-banner .shop-banner-image .shop-banner-content .title {
    font-size: 36px;
    line-height: 39px;
  }
}

.single-shop-banner .shop-banner-image .shop-banner-content .shop-btn {
  background-color: rgba(26, 26, 26, 0);
  color: #1a1a1a;
  padding: 15px 30px 15px 30px;
  border: 2px solid #1a1a1a;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 12px;
  display: inline-block;
}

.single-shop-banner .shop-banner-image .shop-banner-content .shop-btn:hover {
  background-color: #1a1a1a;
  color: #ffffff;
}

.single-shop-banner .shop-banner-image .shop-banner-content-two {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 80px;
  text-align: center;
  margin: 0px auto;
  z-index: 999;
  transition: all 0.3s ease-in-out;
}

.single-shop-banner .shop-banner-image .shop-banner-content-two.right {
  left: auto;
  right: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-two {
    left: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-two {
    left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-two {
    left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-two {
    left: 40px;
  }
}

.single-shop-banner .shop-banner-image .shop-banner-content-two span {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.single-shop-banner .shop-banner-image .shop-banner-content-two .title {
  font-size: 46px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3em;
  letter-spacing: 2px;
  margin: 0px 0px 16px 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-two .title {
    font-size: 32px;
    line-height: 44px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-two .title {
    font-size: 32px;
    line-height: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-two .title {
    font-size: 28px;
    line-height: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-two .title {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 5px;
  }
}

.single-shop-banner .shop-banner-image .shop-banner-content-two .shop-btn {
  background-color: rgba(26, 26, 26, 0);
  color: #1a1a1a;
  padding: 20px 0px 20px 0px;
  border: 2px solid #1a1a1a;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 12px;
  display: inline-block;
  width: 180px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-two .shop-btn {
    font-size: 14px;
    padding: 12px 0px 12px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-two .shop-btn {
    font-size: 14px;
    padding: 12px 0px 12px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-two .shop-btn {
    font-size: 14px;
    padding: 12px 0px 12px 0px;
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-two .shop-btn {
    font-size: 14px;
    padding: 12px 0px 12px 0px;
    width: 150px;
  }
}

.single-shop-banner .shop-banner-image .shop-banner-content-three {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 80px;
  text-align: center;
  margin: 0px auto;
  z-index: 999;
  transition: all 0.3s ease-in-out;
}

.single-shop-banner .shop-banner-image .shop-banner-content-three.right {
  left: auto;
  right: 35px;
}

@media only screen and (max-width: 767px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three.right {
    right: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three {
    left: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three {
    left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three {
    left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three {
    left: 30px;
  }
}

.single-shop-banner .shop-banner-image .shop-banner-content-three span {
  display: block;
  font-size: 32px;
  line-height: 45px;
  font-weight: 400;
  color: #1a1a1a;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three span {
    font-size: 24px;
    line-height: 33px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three span {
    font-size: 22px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three span {
    font-size: 22px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three span {
    font-size: 16px;
    line-height: 22px;
  }
}

.single-shop-banner .shop-banner-image .shop-banner-content-three .title-big-size {
  font-size: 130px;
  font-weight: 400;
  line-height: 143px;
  margin: 0px 0px 25px 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three .title-big-size {
    font-size: 84px;
    line-height: 92px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three .title-big-size {
    font-size: 74px;
    line-height: 82px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three .title-big-size {
    font-size: 56px;
    line-height: 61px;
  }
}

@media only screen and (max-width: 767px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three .title-big-size {
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 5px;
  }
}

.single-shop-banner .shop-banner-image .shop-banner-content-three .title {
  font-size: 64px;
  font-weight: 400;
  line-height: 89px;
  letter-spacing: 2px;
  margin: 0px 0px 25px 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three .title {
    font-size: 36px;
    line-height: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three .title {
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three .title {
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three .title {
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 5px;
  }
}

.single-shop-banner .shop-banner-image .shop-banner-content-three .shop-btn {
  background-color: rgba(26, 26, 26, 0);
  color: #1a1a1a;
  padding: 20px 0px 20px 0px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 12px;
  display: inline-block;
  width: 180px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three .shop-btn {
    font-size: 14px;
    padding: 12px 0px 12px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three .shop-btn {
    font-size: 14px;
    padding: 12px 0px 12px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three .shop-btn {
    font-size: 14px;
    padding: 12px 0px 12px 0px;
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .single-shop-banner .shop-banner-image .shop-banner-content-three .shop-btn {
    font-size: 14px;
    padding: 12px 0px 12px 0px;
    width: 150px;
  }
}

.single-shop-banner .shop-banner-price {
  position: absolute;
  z-index: 1;
  right: 55px;
  top: 45%;
  transform: translateY(-45%);
  max-width: 160px;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-shop-banner .shop-banner-price {
    left: 20px;
    top: 10%;
    transform: translateY(-10%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-shop-banner .shop-banner-price {
    left: 20px;
    top: 10%;
    transform: translateY(-10%);
  }
}

@media only screen and (max-width: 767px) {
  .single-shop-banner .shop-banner-price {
    left: 20px;
    top: 10%;
    transform: translateY(-10%);
  }
}

.single-shop-banner .shop-banner-price .del-price {
  display: block;
  font-size: 18px;
  font-weight: 400;
  text-decoration: line-through;
  text-align: left;
  color: #1a1a1a;
}

.single-shop-banner .shop-banner-price .new-price {
  display: block;
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
  text-align: left;
  color: #1a1a1a;
}

.single-shop-banner:hover .shop-banner-image a::before {
  opacity: 0.1;
}

.single-shop-banner:hover .shop-banner-image a img {
  transform: scale(1.05);
}

.single-shop-banner:hover .title {
  letter-spacing: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-shop-banner:hover .title {
    letter-spacing: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .single-shop-banner:hover .title {
    letter-spacing: 0px;
  }
}

.single-shop-banner:hover .shop-banner-content-two .shop-btn {
  background-color: #1a1a1a;
  color: #ffffff;
}

.single-shop-banner:hover .shop-banner-content-three .shop-btn {
  background-color: #1a1a1a;
  color: #ffffff;
}

/* Single Shop Banner CSS */
.single-shop-banner-area {
  cursor: pointer;
}

.single-shop-banner-area .shop-banner-img a {
  display: block;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.single-shop-banner-area .shop-banner-img a::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1;
  opacity: 0;
  transition: .3s;
}

.single-shop-banner-area .shop-banner-img a img {
  width: 100%;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.single-shop-banner-area .title {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: 35px;
  transition: opacity 0.35s, transform 0.35s;
  max-width: 260px;
  z-index: 9;
  font-size: 36px;
  line-height: 50px;
  color: #1a1a1a;
  text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-shop-banner-area .title {
    font-size: 28px;
    line-height: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-shop-banner-area .title {
    font-size: 22px;
    line-height: 30px;
    max-width: 220px;
    right: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .single-shop-banner-area .title {
    font-size: 22px;
    line-height: 28px;
    max-width: 200px;
    right: 25px;
    text-align: right;
  }
}

.single-shop-banner-area:hover .shop-banner-img a::before {
  opacity: 0.4;
}

.single-shop-banner-area:hover .shop-banner-img a img {
  transform: scale(1.05);
}

/* Made Banner CSS */
.made-one-padding {
  padding: 0px 50px;
  height: 100vh;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .made-one-padding {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .made-one-padding {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .made-one-padding {
    height: 500px;
  }
}

@media only screen and (max-width: 575px) {
  .made-one-padding {
    height: 250px;
  }
}

.made-banner-image {
  background: #f9f9f9;
}

.made-banner-image img {
  margin: auto;
}

@media only screen and (max-width: 767px) {
  .made-banner-image img {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .made-banner-image img {
    width: 200px;
  }
}

.center img {
  width: 500px;
  margin: auto;
}

@media only screen and (max-width: 767px) {
  .center img {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .center img {
    width: 200px;
  }
}

.width-615 img {
  width: 615px;
  margin: auto;
}

@media only screen and (max-width: 767px) {
  .width-615 img {
    width: 290px;
  }
}

@media only screen and (max-width: 575px) {
  .width-615 img {
    width: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .width-311 img {
    width: 40%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .width-311 img {
    width: 40%;
  }
}

@media only screen and (max-width: 767px) {
  .width-311 img {
    width: 40%;
  }
}

@media only screen and (max-width: 575px) {
  .width-311 img {
    width: 25%;
  }
}

.made-banner-content {
  max-width: 560px;
  margin: auto;
  padding: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .made-banner-content {
    padding: 0px 50px 0px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .made-banner-content {
    padding: 0px 50px 0px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .made-banner-content {
    padding: 40px 30px 40px 30px;
  }
}

.made-banner-content .title {
  font-size: 56px;
  line-height: 63px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .made-banner-content .title {
    font-size: 40px;
    line-height: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .made-banner-content .title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .made-banner-content .title {
    font-size: 28px;
    line-height: 39px;
    margin-bottom: 15px;
  }
}

.made-banner-content p {
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .made-banner-content p {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .made-banner-content p {
    margin-bottom: 30px;
  }
}

/* Categories Banner CSS */
.cate-banner-slider {
  margin-left: 0;
  margin-right: 0;
}

.cate-banner-slider .col-md-3 {
  padding-left: 0;
  padding-right: 0;
}

.single-categories-banner {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.single-categories-banner .banner-img > a {
  display: block;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.single-categories-banner .banner-img > a::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1;
  opacity: 0;
  transition: .3s;
}

@media only screen and (max-width: 767px) {
  .single-categories-banner .banner-img > a::before {
    opacity: 0.4;
  }
}

.single-categories-banner .banner-img > a img {
  width: 100%;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media only screen and (max-width: 767px) {
  .single-categories-banner .banner-img > a img {
    transform: scale(1.05);
  }
}

.single-categories-banner .banner-content {
  text-align: left;
  padding: 50px;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(20%);
  max-width: 530px;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .single-categories-banner .banner-content {
    padding: 25px;
    opacity: 1;
    visibility: visible;
    transform: translateY(0%);
  }
}

.single-categories-banner .banner-content .title {
  font-size: 56px;
  line-height: 56px;
  letter-spacing: 1px;
  color: #ffffff;
  margin: 0px 0px 20px 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-categories-banner .banner-content .title {
    font-size: 36px;
    line-height: 36px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-categories-banner .banner-content .title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-categories-banner .banner-content .title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-categories-banner .banner-content .title {
    font-size: 26px;
    line-height: 26px;
  }
}

.single-categories-banner .banner-content p {
  font-size: 18px;
  line-height: 37px;
  color: #ffffff;
  margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-categories-banner .banner-content p {
    font-size: 16px;
    line-height: 36px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-categories-banner .banner-content p {
    font-size: 16px;
    line-height: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-categories-banner .banner-content p {
    font-size: 16px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .single-categories-banner .banner-content p {
    font-size: 14px;
    line-height: 28px;
  }
}

.single-categories-banner .banner-content .banner-btn {
  border: 2px solid rgba(225, 225, 225, 0.3);
  background: none;
  display: inline-block;
  font-weight: 700;
  padding: 15px 30px;
  width: 200px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .single-categories-banner .banner-content .banner-btn {
    font-size: 12px;
    line-height: 24px;
    padding: 12px 25px;
    width: 160px;
  }
}

.single-categories-banner .banner-content .banner-btn:hover {
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.single-categories-banner:hover .banner-img a::before {
  opacity: 0.4;
}

.single-categories-banner:hover .banner-img a img {
  transform: scale(1.05);
}

.single-categories-banner:hover .banner-content {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
}

/* Shop Intro Banner */
.shop-intro-banner-content {
  padding-bottom: 225px;
  margin-left: -45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-banner-content {
    margin-left: 95px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-banner-content {
    margin-left: 75px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-banner-content {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content {
    margin-left: 0px;
    padding-bottom: 60px;
    text-align: center;
  }
}

.shop-intro-banner-content .title {
  font-family: "Playfair Display", Sans-serif;
  font-size: 180px;
  font-weight: 400;
  line-height: 152px;
  color: #a17f25;
  margin-bottom: 140px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-banner-content .title {
    font-size: 80px;
    line-height: 100px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-banner-content .title {
    font-size: 60px;
    line-height: 96px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-banner-content .title {
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content .title {
    font-size: 30px;
    line-height: 48px;
    margin-bottom: 20px;
  }
}

.shop-intro-image {
  position: relative;
  margin-right: -45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-image {
    margin-right: 95px;
    text-align: right;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-image {
    margin-right: 75px;
    text-align: right;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-image {
    margin-right: 0px;
    text-align: right;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-image {
    margin-right: 0px;
    text-align: center;
  }
}

.shop-intro-image img {
  width: 740px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-image img {
    width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-image img {
    width: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-image img {
    width: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-image img {
    width: 280px;
  }
}

.shop-intro-image span {
  position: absolute;
  top: 78%;
  transform: translateY(-78%) translateX(-60%);
  left: 60%;
  z-index: 1;
  width: 220px;
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 400;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-image span {
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-image span {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-image span {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-image span {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content-two {
    margin-top: 30px;
    text-align: center;
  }
}

.shop-intro-banner-content-two .title {
  font-family: "Playfair Display", Sans-serif;
  font-size: 100px;
  font-weight: 400;
  line-height: 104px;
  color: #a17f25;
  max-width: 680px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-banner-content-two .title {
    font-size: 60px;
    line-height: 100px;
    max-width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-banner-content-two .title {
    font-size: 40px;
    line-height: 100px;
    max-width: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-banner-content-two .title {
    font-size: 40px;
    line-height: 80px;
    max-width: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content-two .title {
    font-size: 30px;
    line-height: 48px;
  }
}

.shop-intro-banner-content-two .title-black {
  font-family: "Playfair Display", Sans-serif;
  font-size: 100px;
  font-weight: 400;
  line-height: 104px;
  max-width: 680px;
  margin-bottom: 150px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-banner-content-two .title-black {
    font-size: 60px;
    line-height: 60px;
    max-width: 380px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-banner-content-two .title-black {
    font-size: 40px;
    line-height: 40px;
    max-width: 380px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-banner-content-two .title-black {
    font-size: 40px;
    line-height: 40px;
    max-width: 380px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content-two .title-black {
    font-size: 30px;
    line-height: 48px;
    margin-bottom: 20px;
  }
}

.shop-intro-image-two {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .shop-intro-image-two {
    text-align: center;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-image-two img {
    width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-image-two img {
    width: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-image-two img {
    width: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-image-two img {
    width: 380px;
  }
}

.shop-intro-image-two .caption-two {
  position: absolute;
  top: 86%;
  transform: translateY(-86%) translateX(-70%);
  left: 70%;
  z-index: 1;
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 400;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-image-two .caption-two {
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-image-two .caption-two {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-image-two .caption-two {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-image-two .caption-two {
    font-size: 18px;
  }
}

.shop-intro-banner-content-three {
  padding-left: 165px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-banner-content-three {
    padding-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-banner-content-three {
    padding: 0px 0px 0px 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-banner-content-three {
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content-three {
    padding: 0px 0px 0px 0px;
    margin-bottom: 30px;
    text-align: center;
  }
}

.shop-intro-banner-content-three .title {
  font-family: "Playfair Display", Sans-serif;
  font-size: 80px;
  font-weight: 400;
  line-height: 107px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-banner-content-three .title {
    font-size: 60px;
    line-height: 72px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-banner-content-three .title {
    font-size: 40px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-banner-content-three .title {
    font-size: 40px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content-three .title {
    font-size: 30px;
    line-height: 30px;
  }
}

.shop-intro-banner-content-three .title span {
  color: #a17f25;
}

.shop-intro-banner-content-three .sub-title {
  color: #a17f25;
  font-family: "Playfair Display", Sans-serif;
  font-size: 80px;
  font-weight: 400;
  line-height: 107px;
  margin-bottom: 150px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-banner-content-three .sub-title {
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-banner-content-three .sub-title {
    font-size: 40px;
    line-height: 72px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-banner-content-three .sub-title {
    font-size: 40px;
    line-height: 72px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content-three .sub-title {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
  }
}

.shop-intro-banner-content-four {
  padding-left: 165px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-banner-content-four {
    padding: 0px 80px 0px 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-banner-content-four {
    padding: 0px 0px 0px 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-banner-content-four {
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content-four {
    padding: 0px 0px 0px 0px;
    margin-top: 30px;
    text-align: center;
  }
}

.shop-intro-banner-content-four .title {
  font-family: "Playfair Display", Sans-serif;
  font-size: 80px;
  font-weight: 400;
  line-height: 107px;
  color: #4d4d4d;
  margin-bottom: 150px;
  max-width: 680px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-banner-content-four .title {
    font-size: 60px;
    line-height: 96px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-banner-content-four .title {
    font-size: 40px;
    line-height: 64px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-banner-content-four .title {
    font-size: 40px;
    line-height: 64px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content-four .title {
    font-size: 30px;
    line-height: 48px;
    margin-bottom: 20px;
  }
}

.shop-intro-banner-content-five {
  margin-left: -70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-banner-content-five {
    margin-left: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-banner-content-five {
    margin-left: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-banner-content-five {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content-five {
    margin-left: 0px;
    margin-bottom: 30px;
    text-align: center;
  }
}

.shop-intro-banner-content-five .title {
  font-family: "Playfair Display", Sans-serif;
  font-size: 100px;
  font-weight: 400;
  line-height: 107px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-banner-content-five .title {
    font-size: 60px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-banner-content-five .title {
    font-size: 40px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-banner-content-five .title {
    font-size: 40px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content-five .title {
    font-size: 30px;
    line-height: 30px;
  }
}

.shop-intro-banner-content-five .sub-title {
  color: #a17f25;
  font-family: "Playfair Display", Sans-serif;
  font-size: 100px;
  font-weight: 400;
  line-height: 107px;
  margin-bottom: 150px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-banner-content-five .sub-title {
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-banner-content-five .sub-title {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-banner-content-five .sub-title {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content-five .sub-title {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
  }
}

.shop-intro-image-five {
  margin-right: -70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-image-five {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-image-five {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-image-five {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-image-five {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-intro-image-five img {
    width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-image-five img {
    width: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-image-five img {
    width: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content-six {
    text-align: center;
  }
}

.shop-intro-banner-content-six .title {
  font-size: 24px;
  line-height: 33px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-banner-content-six .title {
    font-size: 18px;
    line-height: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-banner-content-six .title {
    font-size: 18px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content-six .title {
    font-size: 16px;
    line-height: 25px;
  }
}

.shop-intro-banner-content-six .discount-title {
  font-size: 130px;
  line-height: 130px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-banner-content-six .discount-title {
    font-size: 74px;
    line-height: 74px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-banner-content-six .discount-title {
    font-size: 74px;
    line-height: 74px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content-six .discount-title {
    font-size: 70px;
    line-height: 70px;
  }
}

.shop-intro-banner-content-six a {
  margin-top: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-intro-banner-content-six a {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-intro-banner-content-six a {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-intro-banner-content-six a {
    margin-top: 20px;
  }
}

.shop-intro-image-six {
  position: relative;
}

.shop-intro-image-six .caption-three {
  position: absolute;
  top: 10%;
  right: 15px;
  transform: translateY(-10%);
  font-size: 24px;
  color: #1a1a1a;
  z-index: 1;
}

/* Banner nav Css */
.banner-nav {
  position: fixed;
  top: 50%;
  left: auto;
  right: 60px;
  margin-right: 20px;
  transform: translateY(-50%);
  z-index: 1;
  width: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-nav {
    right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-nav {
    right: 0;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-nav {
    display: none;
  }
}

.banner-nav ul li {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible !important;
  margin: 5px 0;
}

.banner-nav ul li.active a {
  color: #000000;
}

.banner-nav ul li.active span {
  width: 40px;
  height: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a17f25;
  color: #a17f25;
}

.banner-nav ul li span {
  width: 40px;
  height: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  color: #23a455;
}

.banner-nav ul li a {
  display: block;
  color: #1a1a1a;
  padding: 0 10px;
  width: 50px;
  height: 35px;
  text-align: right;
  color: #a5a5a5;
  font-size: 22px;
  line-height: 35px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
}

/*----------------------------------------*/
/*  07. Hamburger CSS
/*----------------------------------------*/
.hamburger-area {
  text-align: left;
  transition: all .52s ease;
  position: fixed;
  height: 100% !important;
  top: 0;
  margin: 0;
  z-index: 9;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  width: 470px;
  padding: 70px;
  color: #1a1a1a;
  background-color: #ffffff;
  right: 0;
  transform: translateX(470px);
  -webkit-transform: translateX(470px);
  z-index: 99;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hamburger-area {
    left: -280px;
    width: 280px;
    transform: inherit;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #1a1a1a;
    color: #ffffff;
    opacity: 0;
    padding: 0;
    padding-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hamburger-area {
    left: -280px;
    width: 280px;
    transform: inherit;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #1a1a1a;
    color: #ffffff;
    opacity: 0;
    padding: 0;
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .hamburger-area {
    left: -280px;
    width: 280px;
    transform: inherit;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #1a1a1a;
    color: #ffffff;
    opacity: 0;
    padding: 0;
    padding-top: 70px;
  }
}

.hamburger-area.is-visible {
  transform: translateX(0);
  -webkit-transform: translateX(0);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hamburger-area.is-visible {
    left: 0;
    transform: inherit;
    opacity: 1;
    visibility: visible;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hamburger-area.is-visible {
    left: 0;
    transform: inherit;
    opacity: 1;
    visibility: visible;
  }
}

@media only screen and (max-width: 767px) {
  .hamburger-area.is-visible {
    left: 0;
    transform: inherit;
    opacity: 1;
    visibility: visible;
  }
}

/* Hambeger Area */
.hamburger-area .btn-close-search {
  position: absolute;
  left: 30px;
  font-size: 30px;
  line-height: 30px;
  height: 30px;
  top: 50px;
  transition: all .25s ease-out;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hamburger-area .btn-close-search {
    top: 35px;
    right: 24px;
    left: auto;
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hamburger-area .btn-close-search {
    top: 35px;
    right: 24px;
    left: auto;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hamburger-area .btn-close-search {
    top: 35px;
    right: 24px;
    left: auto;
    font-size: 20px;
  }
}

.hamburger-area .btn-close-search button {
  padding: 0;
  border: 0 none;
  color: #1a1a1a;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hamburger-area .btn-close-search button {
    color: #ffffff;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hamburger-area .btn-close-search button {
    color: #ffffff;
  }
}

@media only screen and (max-width: 767px) {
  .hamburger-area .btn-close-search button {
    color: #ffffff;
  }
}

.hamburger-area .btn-close-search button:hover {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  color: #fc4f4f;
}

.hamburger-area .hamburger-menu-main {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.hamburger-area .hamburger-menu-main .humberger-top {
  flex-grow: 2;
  -webkit-flex-grow: 2;
}

.hamburger-area .hamburger-menu-main .humberger-top .hum-mainmenu ul {
  margin-top: 20px;
  margin-bottom: 10px;
}

.hamburger-area .hamburger-menu-main .humberger-top .hum-mainmenu ul li a {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 16px;
  padding: 10px 0;
  position: relative;
  display: block;
  color: #1a1a1a;
}

.hamburger-area .hamburger-menu-main .humberger-top .hum-mainmenu ul li a:hover {
  color: #fc4f4f;
}

.hamburger-area .hamburger-menu-main .humberger-bottom {
  margin: 20px 0 0;
}

.hamburger-area .hamburger-menu-main .humberger-bottom .thumb img {
  width: 180px;
}

.hamburger-area .hamburger-menu-main .humberger-bottom p {
  color: #1a1a1a;
  font-size: 14px;
  margin: 20px 0 30px;
}

.hamburger-area .hamburger-menu-main .humberger-bottom .hm-address li {
  color: #1a1a1a;
  font-size: 12px;
  line-height: 22px;
}

.hamburger-area .hamburger-menu-main .humberger-bottom .hm-address li a {
  color: #1a1a1a;
}

.hamburger-area .hamburger-menu-main .humberger-bottom .hamburger-social {
  margin-top: 20px;
  margin-bottom: 10px;
}

.hamburger-area .hamburger-menu-main .humberger-bottom .hamburger-social a {
  font-size: 18px;
  margin-right: 24px;
}

.hamburger-area .hamburger-menu-main .humberger-bottom .hamburger-social a:hover {
  color: #fc4f4f;
}

.hamburger-area .hamburger-menu-main .humberger-bottom .hm-copyright {
  margin: 30px 0 0;
}

.hamburger-area .hamburger-menu-main .humberger-bottom .hm-copyright p {
  font-size: 12px;
  line-height: 1.4074;
  color: #1a1a1a;
}

.gmap {
  font-size: 12px;
  text-decoration: underline;
}

/* ================================
    Responsive Menu 
==================================*/
.menu-primary-menu-1.color-white > li > a {
  color: #ffffff !important;
}

.menu-primary-menu-1.color-white > li:hover > a {
  color: #fc4f4f !important;
}

.menu-primary-menu-1.color-white > li .sub-menu-two {
  background-color: #1a1a1a !important;
}

.menu-primary-menu-1.color-white > li .sub-menu-two > li {
  width: 100%;
}

.menu-primary-menu-1.color-white > li .sub-menu-two > li > a {
  color: #ffffff;
}

.menu-primary-menu-1.color-white > li .sub-menu-two > li > a:hover {
  color: #fc4f4f !important;
}

.menu-primary-menu-1.color-white > li .sub-menu-two > li.has-dropdown-menu > a {
  color: #ffffff;
}

.menu-primary-menu-1.color-white > li .sub-menu-two > li.has-dropdown-menu > a.is-visiable {
  color: #fc4f4f !important;
}

.menu-primary-menu-1.color-white > li .sub-menu-two > li.has-label--3 .lavel--3 {
  background-color: #1a1a1a !important;
}

.menu-primary-menu-1.color-white > li .sub-menu-two > li.has-label--3 .lavel--3 li a {
  color: #ffffff;
}

.menu-primary-menu-1.color-white > li .sub-menu-two > li.has-label--3 .lavel--3 li a:hover {
  color: #fc4f4f !important;
}

.menu-primary-menu-1.menu-11 li a {
  color: #1a1a1a;
  display: inline-block;
}

.menu-primary-menu-1.menu-11 li.has-dropdown-menu > a {
  position: relative;
}

.menu-primary-menu-1.menu-11 li.has-dropdown-menu > a::after {
  position: absolute;
  content: "\f107";
  font-family: FontAwesome;
  right: -40px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 17px;
  width: 50px;
}

.menu-primary-menu-1.menu-11 li.has-dropdown-menu > a.is-visiable {
  color: #fc4f4f;
}

.menu-primary-menu-1.menu-11 li.has-dropdown-menu > a.is-visiable::after {
  content: "\f106";
}

.menu-primary-menu-1.menu-11 li .sub-menu-two {
  display: none;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 5px 0;
  margin: 5px 0;
}

.menu-primary-menu-1.menu-11 li .sub-menu-two > li {
  width: 100%;
}

.menu-primary-menu-1.menu-11 li .sub-menu-two > li > a {
  padding: 10px 30px;
  font-size: 1em;
  display: inline-block;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .menu-primary-menu-1.menu-11 li .sub-menu-two > li > a {
    font-size: 14px;
    line-height: 1;
  }
}

.menu-primary-menu-1.menu-11 li .sub-menu-two > li.has-dropdown-menu > a {
  position: relative;
}

.menu-primary-menu-1.menu-11 li .sub-menu-two > li.has-dropdown-menu > a::after {
  position: absolute;
  content: "\f107";
  font-family: FontAwesome;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 17px;
  width: 50px;
}

.menu-primary-menu-1.menu-11 li .sub-menu-two > li.has-dropdown-menu > a.is-visiable {
  color: #fc4f4f;
}

.menu-primary-menu-1.menu-11 li .sub-menu-two > li.has-dropdown-menu > a.is-visiable::after {
  content: "\f106";
}

.menu-primary-menu-1.menu-11 li .sub-menu-two > li.has-label--3 .lavel--3 {
  display: none;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 5px 0;
  margin: 5px 0;
}

.menu-primary-menu-1.menu-11 li .sub-menu-two > li.has-label--3 .lavel--3 li {
  width: 100%;
}

.menu-primary-menu-1.menu-11 li .sub-menu-two > li.has-label--3 .lavel--3 li a {
  padding: 10px 30px;
  font-size: 1em;
  padding-right: 40px;
  padding-left: 40px;
  display: block;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .menu-primary-menu-1.menu-11 li .sub-menu-two > li.has-label--3 .lavel--3 li a {
    font-size: 14px;
    line-height: 1;
  }
}

.menu-primary-menu-1 li a {
  color: silver;
}

.menu-primary-menu-1 li a:hover {
  color: #fc4f4f;
}

.menu-primary-menu-1 li.has-dropdown-menu > a {
  position: relative;
}

.menu-primary-menu-1 li.has-dropdown-menu > a::after {
  position: absolute;
  content: "\f107";
  font-family: FontAwesome;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 17px;
  width: 50px;
}

.menu-primary-menu-1 li.has-dropdown-menu > a.is-visiable {
  color: #fc4f4f;
}

.menu-primary-menu-1 li.has-dropdown-menu > a.is-visiable::after {
  content: "\f106";
}

.menu-primary-menu-1 > li > a {
  display: block;
  padding: 14px 20px;
  font-size: 16px;
}

.menu-primary-menu-1 > li .sub-menu-two {
  display: none;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 5px 0;
  margin: 5px 0;
}

.menu-primary-menu-1 > li .sub-menu-two > li {
  width: 100%;
}

.menu-primary-menu-1 > li .sub-menu-two > li > a {
  padding: 10px 30px;
  font-size: 1em;
  display: block;
}

.menu-primary-menu-1 > li .sub-menu-two > li.has-label--3 .lavel--3 {
  display: none;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 5px 0;
  margin: 5px 0;
}

.menu-primary-menu-1 > li .sub-menu-two > li.has-label--3 .lavel--3 li {
  width: 100%;
}

.menu-primary-menu-1 > li .sub-menu-two > li.has-label--3 .lavel--3 li a {
  padding: 10px 30px;
  font-size: 1em;
  padding-right: 40px;
  padding-left: 40px;
  display: block;
}

/*----------------------------------------*/
/*  08. loading CSS
/*----------------------------------------*/
/*========================
    Animation Css 
=========================*/
@keyframes custom-animation-pulse {
  25% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.9);
  }
}

@keyframes custom-animation-pulse-grow {
  to {
    transform: scale(1.1);
  }
}

@-webkit-keyframes moveVertical {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes moveVertical {
  100% {
    opacity: 1;
    transform: none;
  }
}

.move-up {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.move-up.animate {
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-animation: moveVertical .65s ease forwards;
  animation: moveVertical .65s ease forwards;
}

@-webkit-keyframes la-cubeGridScaleDelay {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3d(0, 0, 1);
  }
  70% {
    -webkit-transform: scale3d(1, 1, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
  }
}

@keyframes la-cubeGridScaleDelay {
  0% {
    transform: scale3d(1, 1, 1);
  }
  35% {
    transform: scale3d(0, 0, 1);
  }
  70% {
    transform: scale3d(1, 1, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes la-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes la-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

@-webkit-keyframes la-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes la-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes la-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes la-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@-webkit-keyframes la-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes la-scaleout {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes la-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes la-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

body.loaded .la-image-loading {
  visibility: hidden;
  opacity: 0;
}

.la-image-loading {
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  overflow: hidden;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  opacity: 1;
  visibility: visible;
}

.la-image-loading button {
  position: absolute;
  left: auto;
  top: auto;
  right: 15px;
  bottom: 15px;
  border: 0;
  z-index: 999;
}

.la-image-loading .content {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

.la-loader.spinner1 {
  width: 40px;
  height: 40px;
  margin: 5px;
  display: block;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-animation: la-rotateplane 1.2s infinite ease-in-out;
  animation: la-rotateplane 1.2s infinite ease-in-out;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.la-loader.spinner2 {
  width: 40px;
  height: 40px;
  margin: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  -webkit-animation: la-scaleout 1s infinite ease-in-out;
  animation: la-scaleout 1s infinite ease-in-out;
}

.la-loader.spinner3 {
  margin: 15px 0 0 -10px;
  width: 70px;
  text-align: center;
}

.la-loader.spinner3 [class*=bounce] {
  width: 18px;
  height: 18px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: la-bouncedelay 1.4s infinite ease-in-out;
  animation: la-bouncedelay 1.4s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.la-loader.spinner3 .bounce1 {
  -webkit-animation-delay: -.32s;
  animation-delay: -.32s;
}

.la-loader.spinner3 .bounce2 {
  -webkit-animation-delay: -.16s;
  animation-delay: -.16s;
}

.la-loader.spinner4 {
  margin: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  -webkit-animation: la-rotate 2s infinite linear;
  animation: la-rotate 2s infinite linear;
}

.la-loader.spinner4 [class*=dot] {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  border-radius: 100%;
  -webkit-animation: la-bounce 2s infinite ease-in-out;
  animation: la-bounce 2s infinite ease-in-out;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

.la-loader.spinner4 .dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.la-loader.spinner5 {
  margin: 5px;
  width: 40px;
  height: 40px;
}

.la-loader.spinner5 div {
  width: 33%;
  height: 33%;
  float: left;
  -webkit-animation: la-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: la-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.la-loader.spinner5 div:nth-child(1),
.la-loader.spinner5 div:nth-child(5),
.la-loader.spinner5 div:nth-child(9) {
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

.la-loader.spinner5 div:nth-child(2),
.la-loader.spinner5 div:nth-child(6) {
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}

.la-loader.spinner5 div:nth-child(3) {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
}

.la-loader.spinner5 div:nth-child(4),
.la-loader.spinner5 div:nth-child(8) {
  -webkit-animation-delay: .1s;
  animation-delay: .1s;
}

.la-loader.spinner5 div:nth-child(7) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.bounce1,
.bounce2,
.bounce3 {
  background-color: #fc4f4f;
}

/*----------------------------------------*/
/*  09. Search CSS
/*----------------------------------------*/
.search-flyoverlay-area {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  transition: all ease-in-out .25s;
  z-index: 12;
  color: #1a1a1a;
}

.search-flyoverlay-area::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -.25em;
}

.search-flyoverlay-area .searchform-fly {
  display: inline-block;
  max-width: 90%;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out .3s;
}

.search-flyoverlay-area .searchform-fly p {
  font-weight: 300;
  font-size: 18px;
  margin: 0 0 15px;
  text-align: center;
}

.search-flyoverlay-area .searchform-fly .search-form {
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  line-height: 1.714286;
  font-weight: 400;
  position: relative;
}

.search-flyoverlay-area .searchform-fly .search-form input, .search-flyoverlay-area .searchform-fly .search-form .contact__form--3 select, .contact__form--3 .search-flyoverlay-area .searchform-fly .search-form select, .search-flyoverlay-area .searchform-fly .search-form .contact__form--3 textarea, .contact__form--3 .search-flyoverlay-area .searchform-fly .search-form textarea {
  width: 100%;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #1a1a1a;
  text-align: center;
  font-size: 30px;
  padding: 19px 10px;
  transition: all .3s ease-out;
  font-weight: 300;
  max-width: 100%;
  background: transparent;
  -webkit-appearance: none;
  border-radius: 0;
  margin-bottom: 0;
  color: #1a1a1a;
  height: auto;
  padding-right: 50px;
}

.search-flyoverlay-area .searchform-fly .search-form button {
  font-size: 30px;
  height: 30px;
  color: inherit;
  display: block;
  right: 5px;
  border: none;
  color: #000;
  background: 0 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #1a1a1a;
  cursor: pointer;
}

.search-flyoverlay-area .searchform-fly .search-form button:hover {
  color: #fc4f4f;
}

.search-flyoverlay-area .btn-close-search {
  font-size: 40px;
  display: block;
  position: absolute;
  z-index: 5;
  top: 20%;
  right: 20%;
  line-height: 40px;
  height: 40px;
  transition: all .3s;
  cursor: pointer;
}

.search-flyoverlay-area .btn-close-search:hover {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  color: #fc4f4f;
}

.search-flyoverlay-area.is-visible {
  visibility: visible;
  opacity: 1;
}

.search-flyoverlay-area.is-visible .searchform-fly {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
}

.search-flyoverlay-area .searchform-fly .search-form input::placeholder, .search-flyoverlay-area .searchform-fly .search-form .contact__form--3 select::placeholder, .contact__form--3 .search-flyoverlay-area .searchform-fly .search-form select::placeholder, .search-flyoverlay-area .searchform-fly .search-form .contact__form--3 textarea::placeholder, .contact__form--3 .search-flyoverlay-area .searchform-fly .search-form textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #1a1a1a;
  font-weight: 300;
}

.search-flyoverlay-area .searchform-fly .search-form input:-ms-input-placeholder, .search-flyoverlay-area .searchform-fly .search-form .contact__form--3 select:-ms-input-placeholder, .contact__form--3 .search-flyoverlay-area .searchform-fly .search-form select:-ms-input-placeholder, .search-flyoverlay-area .searchform-fly .search-form .contact__form--3 textarea:-ms-input-placeholder, .contact__form--3 .search-flyoverlay-area .searchform-fly .search-form textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #1a1a1a;
  font-weight: 300;
}

.search-flyoverlay-area .searchform-fly .search-form input::-ms-input-placeholder, .search-flyoverlay-area .searchform-fly .search-form .contact__form--3 select::-ms-input-placeholder, .contact__form--3 .search-flyoverlay-area .searchform-fly .search-form select::-ms-input-placeholder, .search-flyoverlay-area .searchform-fly .search-form .contact__form--3 textarea::-ms-input-placeholder, .contact__form--3 .search-flyoverlay-area .searchform-fly .search-form textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #1a1a1a;
  font-weight: 300;
}

/*----------------------------------------*/
/*  10. Testimonial CSS
/*----------------------------------------*/
/*-- Testimonial Slider Dot --*/
/*-- Testimonial Slider Image --*/
.testimonial-slider-image {
  max-width: 225px;
  margin: auto;
  float: none;
  overflow: hidden;
}

.testimonial-slider-image.image-border .testimonial.slick-current .image {
  border: 5px solid #FFE49E;
}

.testimonial-slider-image .testimonial {
  text-align: center;
}

.testimonial-slider-image .testimonial .image {
  width: 65px;
  opacity: .3;
  margin: 0 10px;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
}

.testimonial-slider-image .testimonial .image img {
  width: 100%;
  border-radius: 50%;
}

@media only screen and (max-width: 479px) {
  .testimonial-slider-image .testimonial .image {
    width: 65px;
  }
}

.testimonial-slider-image .testimonial.slick-center .image {
  opacity: 1;
}

.testimonial__style {
  text-align: center;
}

.testimonial__style .thumbnail {
  width: 55px;
  height: 55px;
  max-width: 100%;
  object-fit: cover;
  margin: 0 auto;
}

.testimonial__style .thumbnail img {
  border-radius: 100%;
}

.testimonial__style .testimonial-info > p {
  font-size: 28px;
  line-height: 56px;
  max-width: 1150px;
  padding: 0 50px;
  border-radius: 0;
  margin: 0 auto;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__style .testimonial-info > p {
    font-size: 24px;
    line-height: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__style .testimonial-info > p {
    font-size: 24px;
    line-height: 48px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__style .testimonial-info > p {
    font-size: 16px;
    line-height: 30px;
    padding: 0;
    margin-bottom: 20px;
  }
}

.testimonial__style .testimonial-info .info {
  margin-top: 10px;
}

.testimonial__style .testimonial-info .info.fz-18 p span {
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
}

.testimonial__style .testimonial-info .info p span {
  font-size: 24px;
  line-height: 41px;
  margin: 0 5px;
}

@media only screen and (max-width: 767px) {
  .testimonial__style .testimonial-info .info p span {
    font-size: 18px;
    line-height: 34px;
  }
}

.testimonial__style__two {
  text-align: center;
}

.testimonial__style__two .testimonial-info > p {
  position: relative;
  font-size: 28px;
  line-height: 56px;
  font-family: "Playfair Display", Sans-serif;
  font-style: italic;
  max-width: 1150px;
  padding: 0 50px;
  border-radius: 0;
  margin: 0 auto;
  padding-bottom: 20px;
  margin-bottom: 30px;
  color: #ffffff;
}

.testimonial__style__two .testimonial-info > p::after {
  content: '';
  height: 0;
  width: 0;
  display: block;
  position: absolute;
  border: 0 solid;
  border-color: rgba(204, 204, 204, 0.5);
  left: 50%;
  border-right-width: 72px;
  margin-left: calc(72px/-2);
  border-top-width: 2px;
  bottom: -2px;
  border-left-width: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__style__two .testimonial-info > p {
    font-size: 24px;
    line-height: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__style__two .testimonial-info > p {
    font-size: 24px;
    line-height: 48px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__style__two .testimonial-info > p {
    font-size: 16px;
    line-height: 30px;
    padding: 0;
    margin-bottom: 20px;
  }
}

.testimonial__style__two .testimonial-info .info {
  margin-top: 10px;
}

.testimonial__style__two .testimonial-info .info p {
  color: #ffffff;
}

.testimonial__style__two .testimonial-info .info p span {
  font-size: 16px;
  line-height: 27px;
  margin: 0 5px;
}

@media only screen and (max-width: 767px) {
  .testimonial__style__two .testimonial-info .info p span {
    font-size: 18px;
    line-height: 34px;
  }
}

.testimonial__style__three {
  text-align: center;
}

.testimonial__style__three .testimonial-info.color-white p {
  color: #ffffff;
}

.testimonial__style__three .testimonial-info.color-white .info p {
  color: #fce49e;
}

.testimonial__style__three .testimonial-info.color-white .info p span {
  font-size: 18px;
  font-weight: 400;
}

.testimonial__style__three .testimonial-info p {
  font-size: 24px;
  line-height: 55px;
  max-width: 980px;
  padding: 0 50px;
  border-radius: 0;
  margin: 0 auto;
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__style__three .testimonial-info p {
    font-size: 18px;
    line-height: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__style__three .testimonial-info p {
    font-size: 18px;
    line-height: 36px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__style__three .testimonial-info p {
    font-size: 16px;
    line-height: 30px;
    padding: 0;
    margin-bottom: 20px;
  }
}

.testimonial__style__three .testimonial-info .info {
  margin-top: 10px;
}

.testimonial__style__three .testimonial-info .info p {
  color: #a17f25;
}

.testimonial__style__three .testimonial-info .info p span {
  font-size: 24px;
  line-height: 41px;
  margin: 0 5px;
}

@media only screen and (max-width: 767px) {
  .testimonial__style__three .testimonial-info .info p span {
    font-size: 18px;
    line-height: 34px;
  }
}

/*----------------------------------------*/
/*  11. Product CSS
/*----------------------------------------*/
/* Product Slider */
.product-slider-4 .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  border: none;
  background-color: #fc4f4f;
  color: #1a1a1a;
  width: 30px;
  height: 44px;
  border-radius: 6px;
  text-align: center;
}

.product-slider-4 .slick-arrow::before, .product-slider-4 .slick-arrow::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fc4f4f;
  transform: rotate(60deg);
  z-index: -1;
  border-radius: 6px;
}

.product-slider-4 .slick-arrow::after {
  transform: rotate(-60deg);
}

.product-slider-4 .slick-arrow i {
  font-size: 18px;
  margin-right: 0px;
  line-height: 25px;
  font-weight: 700;
  color: #1a1a1a;
}

.product-slider-4 .slick-arrow.slick-prev {
  left: 25px;
}

.product-slider-4 .slick-arrow.slick-next {
  right: 25px;
}

.product-slider-4 .slick-dots {
  width: 100%;
  padding: 0;
  margin: 20px 0 0;
  display: flex;
  justify-content: center;
}

.product-slider-4 .slick-dots li {
  margin: 0 5px;
}

.product-slider-4 .slick-dots li button {
  display: block;
  padding: 0;
  width: 12px;
  height: 12px;
  border: none;
  background-color: #d8d8d8;
  text-indent: -9999px;
  border-radius: 50px;
}

.product-slider-4 .slick-dots li.slick-active button {
  background-color: #fc4f4f;
}

/*-- Product --*/
.single-grid-product {
  transition: 0.6s;
  position: relative;
}

.single-grid-product.style-two .product-image a {
  position: relative;
  overflow: hidden;
  display: block;
}

.single-grid-product.style-two .product-image a::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(38, 38, 38, 0.6);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.single-grid-product.style-two .product-content {
  position: absolute;
  width: calc(100% - 20px);
  bottom: 0;
  left: 0;
  padding: 50px 40px;
  opacity: 0;
  transition: .5s;
}

@media only screen and (min-width: 1500px) and (max-width: 1919px) {
  .single-grid-product.style-two .product-content {
    padding: 30px 20px;
    width: calc(100% - 40px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-grid-product.style-two .product-content {
    width: 100%;
    padding: 30px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-grid-product.style-two .product-content {
    width: 100%;
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-grid-product.style-two .product-content {
    padding: 15px 20px;
  }
}

.single-grid-product.style-two .product-content .title {
  color: #ffffff;
}

.single-grid-product.style-two .product-content .product-price {
  color: #ffffff;
}

.single-grid-product.style-two .product-content .product-rating .rating {
  color: #ffffff;
}

.single-grid-product.style-two .product-content .product-action {
  margin-top: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-grid-product.style-two .product-content .product-action {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .single-grid-product.style-two .product-content .product-action.action-rs-small {
    margin-top: 5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-grid-product.style-two .product-content .product-action.action-rs-small ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-grid-product.style-two .product-content .product-action.action-rs-small ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
  }
}

.single-grid-product.style-two .product-content .product-action ul li {
  display: inline-block;
  opacity: 1;
}

.single-grid-product.style-two .product-content .product-action ul li a {
  background-color: #fff;
  color: #2F2F2F;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  display: block;
  padding: 0;
  position: relative;
  display: inline-block;
  text-align: center;
  margin: 3px;
  vertical-align: middle;
}

@media only screen and (min-width: 1500px) and (max-width: 1919px) {
  .single-grid-product.style-two .product-content .product-action ul li a {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .single-grid-product.style-two .product-content .product-action ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.single-grid-product.style-two .product-content .product-action ul li a:hover {
  background-color: #1a1a1a;
  color: #ffffff;
}

.single-grid-product.style-two .product-content .product-action ul li:last-child {
  padding-right: 0;
}

.single-grid-product.style-two:hover .product-image a::before {
  opacity: 1;
}

.single-grid-product.style-two:hover .product-content {
  opacity: 1;
}

.single-grid-product .product-label {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
}

.single-grid-product .product-label span {
  font-size: 11px;
  font-weight: 300;
  color: #fff;
  text-align: center;
  line-height: 50px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: block;
  margin-bottom: 15px;
  margin-left: auto;
}

.single-grid-product .product-label span:last-child {
  margin-bottom: 0;
}

.single-grid-product .product-label span.new {
  background-color: #1a1a1a;
}

.single-grid-product .product-label span.sale {
  background-color: #D97841;
}

.single-grid-product .product-label span.out-stock {
  width: auto;
  border-radius: 0;
  padding: 8px 20px;
  height: auto;
  line-height: 1.8;
  background-color: #1a1a1a;
}

.single-grid-product .product-image {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
}

.single-grid-product .product-image.image-grayscale {
  filter: grayscale(100%);
}

.single-grid-product .product-image > a {
  display: block;
  width: 100%;
}

.single-grid-product .product-image > a img {
  width: 100%;
  transition: 0.6s;
}

.single-grid-product .product-image > a img:nth-child(1) {
  visibility: visible;
  opacity: 1;
}

.single-grid-product .product-image > a img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
}

.single-grid-product .product-image .product-action {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 22px;
  width: 100%;
  z-index: 2;
  text-align: center;
}

.single-grid-product .product-image .product-action.product-action-two ul li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

.single-grid-product .product-image .product-action ul li {
  display: inline-block;
  transition: all 250ms ease-out;
  transition-delay: 100ms;
  transform: translate(40px, 0);
  opacity: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-grid-product .product-image .product-action ul li {
    transform: translate(20px, 0);
  }
}

@media only screen and (max-width: 767px) {
  .single-grid-product .product-image .product-action ul li {
    transform: translate(20px, 0);
  }
}

.single-grid-product .product-image .product-action ul li:nth-child(2) {
  transition-delay: 150ms;
}

.single-grid-product .product-image .product-action ul li:nth-child(3) {
  transition-delay: 200ms;
}

.single-grid-product .product-image .product-action ul li:nth-child(4) {
  transition-delay: 200ms;
}

.single-grid-product .product-image .product-action ul li a {
  background-color: #fff;
  color: #2F2F2F;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  display: block;
  padding: 0;
  position: relative;
  display: inline-block;
  text-align: center;
  margin: 3px;
  vertical-align: middle;
}

@media only screen and (max-width: 767px) {
  .single-grid-product .product-image .product-action ul li a {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}

.single-grid-product .product-image .product-action ul li a:hover {
  background-color: #1a1a1a;
  color: #ffffff;
}

.single-grid-product .product-image .product-action ul li:last-child {
  padding-right: 0;
}

.single-grid-product .product-image .product-action-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -70px;
  background-color: #1a1a1a;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  z-index: 2;
  text-align: center;
  transition: all .6s;
}

.single-grid-product .product-image .product-action-content ul li {
  display: inline-block;
  transition: all 250ms ease-out;
  transition-delay: 100ms;
  transform: translate(40px, 0);
  opacity: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-grid-product .product-image .product-action-content ul li {
    transform: translate(20px, 0);
  }
}

@media only screen and (max-width: 767px) {
  .single-grid-product .product-image .product-action-content ul li {
    transform: translate(20px, 0);
  }
}

.single-grid-product .product-image .product-action-content ul li:nth-child(2) {
  transition-delay: 150ms;
}

.single-grid-product .product-image .product-action-content ul li:nth-child(3) {
  transition-delay: 200ms;
}

.single-grid-product .product-image .product-action-content ul li:nth-child(4) {
  transition-delay: 200ms;
}

.single-grid-product .product-image .product-action-content ul li a span {
  display: block;
  padding: 22px 15px;
  visibility: visible;
  opacity: .5;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  color: #ffffff;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-grid-product .product-image .product-action-content ul li a span {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-grid-product .product-image .product-action-content ul li a span {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-grid-product .product-image .product-action-content ul li a span {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .single-grid-product .product-image .product-action-content ul li a span {
    display: none;
  }
}

.single-grid-product .product-image .product-action-content ul li a span:hover {
  opacity: 1;
}

.single-grid-product .product-image .product-action-content ul li a i {
  display: none;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .single-grid-product .product-image .product-action-content ul li a i {
    width: 50px;
    height: 40px;
    line-height: 40px;
  }
}

.single-grid-product .product-image .product-action-content ul li a i:hover {
  color: #fc4f4f;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-grid-product .product-image .product-action-content ul li a i {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-grid-product .product-image .product-action-content ul li a i {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-grid-product .product-image .product-action-content ul li a i {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .single-grid-product .product-image .product-action-content ul li a i {
    display: block;
  }
}

.single-grid-product .product-content {
  text-align: center;
}

.single-grid-product .product-content.color-white .title {
  color: #ffffff;
}

.single-grid-product .product-content.color-white p {
  color: #ffffff;
}

.single-grid-product .product-content.color-white .product-rating span {
  color: #ffffff;
}

.single-grid-product .product-content.product-content-two .title {
  font-size: 14px;
  line-height: 24px;
}

.single-grid-product .product-content .title {
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 5px;
}

.single-grid-product .product-content .title a {
  display: block;
}

.single-grid-product .product-content .title a:hover {
  color: #fc4f4f;
}

.single-grid-product .product-content p {
  line-height: 20px;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 0;
}

.single-grid-product .product-content p .main-price.discounted del {
  opacity: .6;
}

.single-grid-product .product-content .product-rating {
  line-height: 1;
}

.single-grid-product .product-content .product-rating span {
  font-size: 12px;
  line-height: 14px;
  color: #1a1a1a;
}

.single-grid-product:hover .product-image.image-grayscale {
  filter: grayscale(0);
}

.single-grid-product:hover .product-image > a img:nth-child(2) {
  visibility: visible;
  opacity: 1;
}

.single-grid-product:hover .product-image .product-action ul li {
  opacity: 1;
  transform: translate(0, 0);
}

.single-grid-product:hover .product-image .product-action-content {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}

.single-grid-product:hover .product-image .product-action-content ul li {
  opacity: 1;
  transform: translate(0, 0);
}

.shop-top-bar .shop-topbar-left {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-topbar-left {
    display: block;
    overflow: hidden;
  }
}

@media only screen and (max-width: 479px) {
  .shop-top-bar .shop-topbar-left {
    display: block;
    overflow: hidden;
  }
}

.shop-top-bar .shop-topbar-left p {
  margin: 0 45px 0 0px;
  font-size: 12px;
  color: #1a1a1a;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-topbar-left .page-show {
    margin: 5px 0;
    overflow: hidden;
  }
}

.shop-top-bar .shop-topbar-left .page-show span {
  display: inline-block;
  float: left;
  font-size: 12px;
  color: #1a1a1a;
  margin-right: 14px;
}

.shop-top-bar .shop-topbar-left .page-show ul {
  display: inline-block;
  float: left;
}

.shop-top-bar .shop-topbar-left .page-show ul li {
  display: inline-block;
  margin-right: 11px;
}

.shop-top-bar .shop-topbar-left .page-show ul li:last-child {
  margin-right: 0;
}

.shop-top-bar .shop-topbar-left .page-show ul li a {
  font-size: 12px;
  color: #1a1a1a;
  border-bottom: 1px solid transparent;
}

.shop-top-bar .shop-topbar-left .page-show ul li a:hover {
  color: #1A1A1A;
  border-bottom: 1px solid #1A1A1A;
}

.shop-top-bar .shop-topbar-left .page-show ul li a.active {
  color: #1A1A1A;
  border-bottom: 1px solid #1A1A1A;
}

.shop-top-bar .shop-topbar-right .filter-active a {
  color: #343538;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
  position: relative;
  padding-right: 25px;
}

.shop-top-bar .shop-topbar-right .filter-active a:hover {
  color: #fc4f4f;
}

.shop-top-bar .shop-topbar-right .filter-active a::after {
  content: "\f107";
  font-family: FontAwesome;
  padding-left: 5px;
  display: inline-block;
  line-height: inherit;
  font-style: normal;
}

.shop-top-bar .shop-topbar-right .filter-active a.active::after {
  content: "\f106";
}

.shop-top-bar .shop-topbar-right .short-by {
  position: relative;
}

.shop-top-bar .shop-topbar-right .short-by > a {
  color: #343538;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
  position: relative;
  padding-right: 25px;
}

.shop-top-bar .shop-topbar-right .short-by > a:hover {
  color: #fc4f4f;
}

.shop-top-bar .shop-topbar-right .short-by > a:hover::after {
  content: "\f106";
}

.shop-top-bar .shop-topbar-right .short-by > a::after {
  content: "\f107";
  font-family: FontAwesome;
  padding-left: 5px;
  display: inline-block;
  line-height: inherit;
  font-style: normal;
}

.shop-top-bar .shop-topbar-right .short-by .sort-by-dropdown {
  position: absolute;
  background-color: #fff;
  padding: 7px;
  z-index: 11;
  visibility: hidden;
  margin-top: 30px;
  opacity: 0;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.2);
  transition: all 250ms ease-out;
}

.shop-top-bar .shop-topbar-right .short-by .sort-by-dropdown li {
  display: block;
}

.shop-top-bar .shop-topbar-right .short-by .sort-by-dropdown li a {
  padding: 5px 10px;
  white-space: pre;
  display: block;
  border-radius: 4px;
  font-size: 11px;
  line-height: 24px;
}

.shop-top-bar .shop-topbar-right .short-by .sort-by-dropdown li.active a, .shop-top-bar .shop-topbar-right .short-by .sort-by-dropdown li:hover a {
  background-color: #1a1a1a;
  color: #ffffff;
}

.shop-top-bar .shop-topbar-right .short-by:hover .sort-by-dropdown {
  opacity: 1;
  visibility: visible;
  margin-top: 7px;
}

.shop-top-bar .shop-topbar-right.shop-tab-flex {
  display: flex;
}

.shop-top-bar .shop-topbar-right.shop-tab-flex .shop-tab a {
  color: #1a1a1a;
  font-size: 18px;
  margin-left: 10px;
}

.shop-top-bar .shop-topbar-right.shop-tab-flex .shop-tab a.active {
  color: #1A1A1A;
}

.shop-top-bar.shop-top-bar-flex {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar.shop-top-bar-flex {
    display: flex;
  }
}

@media only screen and (max-width: 479px) {
  .shop-top-bar.shop-top-bar-flex {
    display: block;
  }
}

.shop-top-bar.shop-top-bar-right {
  text-align: right;
}

.product-filter-wrapper {
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  padding: 40px 40px 0;
  display: none;
}

.product-filter-wrapper .single-filter-title {
  margin-bottom: 15px;
}

.product-filter-wrapper .single-filter-title h5 {
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  font-size: 16px;
}

.product-filter-wrapper .product-sort ul li {
  margin-bottom: 5px;
}

.product-filter-wrapper .product-sort ul li:last-child {
  margin-bottom: 0;
}

.product-filter-wrapper .product-sort ul li a {
  color: #1a1a1a;
  font-size: 12px;
}

.product-filter-wrapper .product-sort ul li a:hover {
  color: #fc4f4f;
}

.product-filter-wrapper .product-price-filter ul li {
  margin-bottom: 5px;
}

.product-filter-wrapper .product-price-filter ul li:last-child {
  margin-bottom: 0;
}

.product-filter-wrapper .product-price-filter ul li a {
  color: #1a1a1a;
  font-size: 12px;
}

.product-filter-wrapper .product-price-filter ul li a:hover {
  color: #fc4f4f;
}

.product-filter-wrapper .product-size ul li {
  margin-bottom: 5px;
}

.product-filter-wrapper .product-size ul li:last-child {
  margin-bottom: 0;
}

.product-filter-wrapper .product-size ul li a {
  color: #1a1a1a;
  font-size: 12px;
}

.product-filter-wrapper .product-size ul li a:hover {
  color: #fc4f4f;
}

.product-filter-wrapper .product-color ul li {
  margin-bottom: 5px;
}

.product-filter-wrapper .product-color ul li:last-child {
  margin-bottom: 0;
}

.product-filter-wrapper .product-color ul li a .color-text {
  color: #1a1a1a;
  font-size: 12px;
}

.product-filter-wrapper .product-color ul li a:hover .color-text {
  color: #fc4f4f;
}

.product-filter-wrapper .product-brand ul li {
  margin-bottom: 5px;
}

.product-filter-wrapper .product-brand ul li:last-child {
  margin-bottom: 0;
}

.product-filter-wrapper .product-brand ul li a {
  color: #1a1a1a;
  font-size: 12px;
}

.product-filter-wrapper .product-brand ul li a:hover {
  color: #fc4f4f;
}

/* Shop List CSS */
.shop-list-wrap .product-img-list {
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {
  .shop-list-wrap .product-img-list {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 479px) {
  .shop-list-wrap .product-img-list {
    margin-bottom: 20px;
  }
}

.shop-list-wrap .product-img-list .product-label {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 9;
}

.shop-list-wrap .product-img-list .product-label span {
  font-size: 11px;
  font-weight: 300;
  color: #fff;
  text-align: center;
  line-height: 50px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: block;
  margin-bottom: 15px;
  margin-left: auto;
}

.shop-list-wrap .product-img-list .product-label span:last-child {
  margin-bottom: 0;
}

.shop-list-wrap .product-img-list .product-label span.new {
  background-color: #1a1a1a;
}

.shop-list-wrap .product-img-list .product-label span.sale {
  background-color: #D97841;
}

.shop-list-wrap .product-img-list .product-label span.out-stock {
  width: auto;
  border-radius: 0;
  padding: 8px 20px;
  height: auto;
  line-height: 1.8;
  background-color: #1a1a1a;
}

.shop-list-wrap .product-img-list a {
  display: block;
}

.shop-list-wrap .product-img-list a img {
  width: 100%;
}

.shop-list-wrap .product-img-list .hover-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  transition: opacity 0.5s ease, transform 2s cubic-bezier(0, 0, 0.44, 1.18);
  opacity: 0;
}

.shop-list-wrap .product-img-list:hover .hover-img {
  opacity: 1;
}

.shop-list-wrap .product-img-list .product-quickview-list {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  text-align: center;
  z-index: 9;
  transform: translate(-50%, -50%);
}

.shop-list-wrap .product-img-list .product-quickview-list a {
  background-color: #1a1a1a;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 1px;
  font-size: 12px;
  display: block;
  padding: 14px 10px 16px;
  min-width: 140px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  margin-top: 50px;
  transition: all .4s ease 0s;
}

.shop-list-wrap .product-img-list .product-quickview-list a:hover {
  background-color: #fc4f4f;
  color: #ffffff;
}

.shop-list-wrap .product-img-list:hover .product-quickview-list a {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.shop-list-wrap .shop-list-content .product-details-content .product-list-price {
  margin: 20px 0 0px;
}

.shop-list-wrap .shop-list-content .product-details-content .product-list-price span {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content .product-details-content .product-list-price {
    margin: 12px 0 0px;
  }
}

.shop-list-wrap .shop-list-content .product-details-content p {
  margin: 20px 0 0;
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content .product-details-content p {
    margin: 10px 0 0;
  }
}

.shop-list-wrap .shop-list-content .product-details-content .pro-details-cart {
  margin: 0px 22px 0px 0px;
}

.shop-list-wrap .shop-list-content .product-details-content .pro-details-quality {
  margin: 40px 0 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-list-wrap .shop-list-content .product-details-content .pro-details-quality {
    margin: 30px 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-list-wrap .shop-list-content .product-details-content .pro-details-quality {
    margin: 20px 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-wrap .shop-list-content .product-details-content .pro-details-quality {
    margin: 20px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content .product-details-content .pro-details-quality {
    margin: 20px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content .product-details-content.quickview-content {
    margin-top: 20;
  }
}

@media only screen and (max-width: 479px) {
  .shop-list-wrap .shop-list-content .product-details-content.quickview-content {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap.mb-70 {
    margin-bottom: 30px !important;
  }
}

/* Product Details Page Nav */
@media only screen and (max-width: 575px) {
  .product-nav ul {
    justify-content: flex-start !important;
  }
}

@media only screen and (max-width: 479px) {
  .product-nav ul {
    justify-content: flex-start !important;
  }
}

.product-nav ul li a {
  line-height: 20px;
  font-size: 20px;
  margin: 0 5px;
  display: block;
  color: #1a1a1a;
}

.product-nav ul li a:hover {
  color: #fc4f4f;
}

/* Product Details CSS */
.product-details-content h2 {
  font-size: 32px;
  line-height: 45px;
  margin-bottom: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content h2 {
    font-size: 20px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content h2 {
    font-size: 20px;
    line-height: 28px;
  }
}

.product-details-content h3 {
  color: #1a1a1a;
  font-weight: 400;
  font-size: 24px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .product-details-content h3 {
    font-size: 17px;
  }
}

.product-details-content h3 a:hover {
  color: #fc4f4f;
}

.product-details-content > .product-details-price {
  line-height: 32px;
  display: inline-block;
  font-size: 32px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .product-details-content > .product-details-price {
    font-size: 24px;
    line-height: 24px;
  }
}

.product-details-content > .product-details-price .main-price.discounted del {
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  opacity: .6;
}

@media only screen and (max-width: 767px) {
  .product-details-content > .product-details-price .main-price.discounted del {
    font-size: 14px;
    line-height: 14px;
  }
}

.product-details-content .pro-details-sku {
  padding-top: 15px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-sku {
    padding-top: 0;
  }
}

.product-details-content .pro-details-sku span {
  color: #343538;
  font-size: 12px;
}

.product-details-content p {
  font-size: 14px;
  color: #333;
  margin: 38px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content p {
    margin: 15px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content p {
    margin: 15px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content p {
    margin: 15px 0 0;
  }
}

.product-details-content .fz-16 {
  font-size: 16px;
  line-height: 30px;
  margin-top: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .fz-16 {
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .fz-16 {
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
  }
}

.product-details-content .pro-details-quality {
  display: flex;
  align-items: center;
  margin: 40px 0 28px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .product-details-content .pro-details-quality {
    margin: 40px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-quality {
    margin: 25px 0 25px;
  }
}

@media only screen and (max-width: 479px) {
  .product-details-content .pro-details-quality {
    margin: 20px 0 20px;
    flex-wrap: wrap;
  }
}

.product-details-content .pro-details-cart {
  margin: 0px 22px 0px 11px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-cart {
    margin: 0px 10px 0px 11px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-cart {
    margin: 0px 10px 0px 11px;
  }
}

.product-details-content .pro-details-cart a {
  padding: 15px 50px;
  font-weight: bold;
  line-height: 24px;
  float: left;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  transition: all 250ms ease-out;
  background-color: #343538;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-cart a {
    padding: 15px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-cart a {
    padding: 15px 10px;
  }
}

.product-details-content .pro-details-cart a:hover {
  background-color: #fc4f4f;
}

.product-details-content .pro-details-cart.pro-details-mrg-none {
  margin: 0px 22px 0px 0px;
}

.product-details-content .pro-details-wishlist {
  margin-right: 22px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-wishlist {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-wishlist {
    margin-right: 10px;
  }
}

.product-details-content .pro-details-wishlist a {
  position: relative;
  width: 54px;
  height: 54px;
  line-height: 54px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0;
  font-size: 16px;
  text-align: center;
}

.product-details-content .pro-details-wishlist a:hover {
  background: #fc4f4f;
  color: #ffffff;
  border-color: #fc4f4f;
}

.product-details-content .pro-details-compare {
  position: relative;
}

.product-details-content .pro-details-compare a {
  position: relative;
  width: 54px;
  height: 54px;
  line-height: 54px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0;
  font-size: 16px;
  text-align: center;
}

@media only screen and (max-width: 479px) {
  .product-details-content .pro-details-compare a {
    margin-top: 10px;
  }
}

.product-details-content .pro-details-compare a:hover {
  background: #fc4f4f;
  color: #ffffff;
  border-color: #fc4f4f;
}

.product-details-content .pro-details-meta span {
  color: #1a1a1a;
  font-size: 12px;
  display: inline-block;
  float: left;
  margin-right: 5px;
}

.product-details-content .pro-details-meta ul li {
  margin-right: 2px;
  display: inline-block;
}

.product-details-content .pro-details-meta ul li:last-child {
  margin-right: 0;
}

.product-details-content .pro-details-meta ul li a {
  color: #1a1a1a;
  font-size: 12px;
}

.product-details-content .pro-details-meta ul li a:hover {
  color: #fc4f4f;
}

.product-details-content .product-html-custom {
  display: flex;
  align-items: center;
  padding-top: 28px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .product-html-custom {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 479px) {
  .product-details-content .product-html-custom {
    flex-wrap: wrap;
  }
}

.product-details-content .product-html-custom a {
  margin-right: 20px;
  font-size: 12px;
  display: block;
  color: #1a1a1a;
}

.product-details-content .product-html-custom a i {
  font-size: 22px;
  padding-right: 8px;
  vertical-align: middle;
  font-weight: bold;
}

.product-details-content .pro-details-social {
  margin-top: 38px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-social {
    margin-top: 25px;
    margin-bottom: 20px;
  }
}

.product-details-content .pro-details-social ul {
  display: flex;
}

.product-details-content .pro-details-social ul li {
  margin: 0 5px;
}

.product-details-content .pro-details-social ul li:first-child {
  margin-left: 0px;
}

.product-details-content .pro-details-social ul li:last-child {
  margin-right: 0px;
}

.product-details-content .pro-details-social ul li a {
  background: 0 0;
  border-radius: 50%;
  border: 1px solid #e1e1e1;
  color: #262626;
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  display: block;
  text-align: center;
}

.product-details-content .pro-details-social ul li a.facebook:hover {
  background-color: #4867AA;
  color: #ffffff;
  border-color: #4867AA;
}

.product-details-content .pro-details-social ul li a.twitter:hover {
  background-color: #1DA1F2;
  color: #ffffff;
  border-color: #1DA1F2;
}

.product-details-content .pro-details-social ul li a.google:hover {
  background-color: #DD5144;
  color: #ffffff;
  border-color: #DD5144;
}

.product-details-content .pro-details-social ul li a.pinterest:hover {
  background-color: #BD081B;
  color: #ffffff;
  border-color: #BD081B;
}

.product-details-content .product-rating-stock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .product-rating-stock {
    margin: 15px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-rating-stock {
    display: block;
    margin: 15px 0 0;
  }
}

.product-details-content .product-rating-stock .product-dec-rating-reviews {
  display: flex;
  line-height: 1;
}

.product-details-content .product-rating-stock .product-dec-rating-reviews .product-dec-rating {
  line-height: 1;
}

.product-details-content .product-rating-stock .product-dec-rating-reviews .product-dec-rating i {
  color: #f5b223;
  font-size: 12px;
}

.product-details-content .product-rating-stock .product-dec-rating-reviews .product-dec-reviews {
  margin-left: 5px;
}

.product-details-content .product-rating-stock .product-dec-rating-reviews .product-dec-reviews a {
  font-size: 12px;
  color: #8a8a8a;
}

.product-details-content .product-rating-stock .product-dec-rating-reviews .product-dec-reviews a:hover {
  color: #fc4f4f;
}

.product-details-content .product-rating-stock .pro-stock span {
  font-size: 12px;
  color: #1a1a1a;
  text-transform: uppercase;
}

.product-details-content .product-rating-stock .pro-stock span i {
  font-size: 14px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.product-details-content .pro-details-group {
  margin: 40px 0 0;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-group {
    margin: 15px 0 0;
  }
}

.product-details-content .pro-details-group .single-group {
  display: flex;
  margin-bottom: 20px;
}

.product-details-content .pro-details-group .single-group .pro-dec-title-stock {
  margin: 5px 0 0 25px;
}

.product-details-content .pro-details-group .single-group .pro-dec-title-stock .pro-dec-title h5 {
  font-weight: bold;
  font-size: 14px;
  margin: 0;
}

.product-details-content .pro-details-group .single-group .pro-dec-title-stock .pro-dec-price-stock span {
  color: #8a8a8a;
  font-size: 12px;
}

.product-details-content .pro-details-group .single-group .pro-dec-title-stock .pro-dec-price-stock span.pro-price {
  margin-right: 15px;
}

.product-details-content .pro-details-size-color {
  margin-top: 30px;
}

.product-details-content .pro-details-size-color form .table {
  max-width: 422px;
}

.product-details-content .pro-details-size-color form .table tr {
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 10px 10px 10px 20px;
  align-items: baseline;
  font-size: 16px;
  color: #1a1a1a;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-size-color form .table tr {
    font-size: 14px;
  }
}

.product-details-content .pro-details-size-color form .table tr:last-child {
  margin-bottom: 0;
}

.product-details-content .pro-details-size-color form .table tr td.label {
  padding: 0px;
  border-bottom: 0;
  min-width: auto;
  display: inline-block;
  width: auto !important;
  margin-right: 15px;
  flex-grow: 1;
}

.product-details-content .pro-details-size-color form .table tr td.value {
  padding: 0px;
  border-bottom: 0;
  min-width: auto;
  display: inline-block;
  width: auto !important;
  margin-right: 0px;
  flex-grow: 2;
}

.product-details-content .pro-details-size-color form .table tr td.value select {
  height: auto;
  width: 100%;
  border: none;
}

.product-details-content.quickview-content .product-details-price {
  margin: 15px 0 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.quickview-content .product-details-price {
    margin: 10px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.quickview-content .product-details-price {
    margin: 10px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.pro-details-sidebar-style .pro-details-cart a {
    padding: 18px 20px;
  }
}

@media only screen and (max-width: 479px) {
  .shop-list-content .product-details-content .pro-details-compare a {
    margin-top: 0;
  }
}

.product-tabs .easyzoom-style {
  position: relative;
}

.product-tabs .easyzoom-style .product-label {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
}

.product-tabs .easyzoom-style .product-label span {
  font-size: 11px;
  font-weight: 300;
  color: #fff;
  text-align: center;
  line-height: 50px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: block;
  margin-bottom: 15px;
  margin-left: auto;
}

.product-tabs .easyzoom-style .product-label span:last-child {
  margin-bottom: 0;
}

.product-tabs .easyzoom-style .product-label span.new {
  background-color: #1a1a1a;
}

.product-tabs .easyzoom-style .product-label span.sale {
  background-color: #D97841;
}

.product-tabs .easyzoom-style .product-label span.out-stock {
  width: auto;
  border-radius: 0;
  padding: 8px 20px;
  height: auto;
  line-height: 1.8;
  background-color: #1a1a1a;
}

.product-tabs .easyzoom-style a.easyzoom-pop-up {
  position: absolute;
  right: 20px;
  bottom: 15px;
  z-index: 2;
  background: #ffffff;
  color: #1a1a1a;
  width: 58px;
  height: 58px;
  line-height: 58px;
  text-align: center;
  font-size: 19px;
  border-radius: 50%;
  display: block;
  margin: 5px;
  order: 2;
  border: 1px solid #CBCBCB;
  transition: all .3s;
}

.product-tabs .easyzoom-style a.easyzoom-pop-up:hover {
  background-color: #1a1a1a;
  border-color: #1a1a1a;
  color: #ffffff;
}

.product-tabs .easyzoom-style .easyzoom > a {
  transition: none;
}

.product-tabs .easyzoom-style .easyzoom > a img {
  width: 100%;
  transition: none;
}

.product-tabs .easyzoom-style .easyzoom-popup > a img {
  width: 100%;
}

.product-dec-slider {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
}

.product-dec-slider .product-dec-small {
  margin: 10px;
  cursor: pointer;
}

.product-dec-slider .product-dec-small.slick-active {
  border: 1px solid transparent;
}

@media only screen and (max-width: 767px) {
  .product-dec-slider .product-dec-small.slick-active {
    border: none;
  }
}

.product-dec-slider .product-dec-small.slick-active.slick-current {
  border: 1px solid #1A1A1A;
}

@media only screen and (max-width: 767px) {
  .product-dec-slider .product-dec-small.slick-active.slick-current {
    border: none;
  }
}

.product-dec-slider-2 .product-dec-small {
  margin-bottom: 20px;
}

.product-dec-slider-2 .product-dec-small.slick-active {
  border: 1px solid transparent;
}

.product-dec-slider-2 .product-dec-small.slick-active.slick-current {
  border: 1px solid #1A1A1A;
}

.product-dec-right {
  width: calc(100% - 140px);
  padding-left: 30px;
  float: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-right {
    width: calc(100% - 100px);
    padding-left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-right {
    width: calc(100% - 100px);
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-right {
    width: calc(100% - 70px);
    padding-left: 15px;
  }
}

.product-dec-left {
  width: 140px;
  float: left;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-left {
    width: 100px;
    padding-left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-left {
    width: 100px;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-left {
    width: 70px;
    padding-left: 15px;
  }
}

/* Shop Collection CSS */
.shop-collection-content {
  padding-bottom: 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .shop-collection-content {
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-collection-content {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-collection-content {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collection-content {
    padding-bottom: 30px;
  }
}

.shop-collection-content .title {
  font-size: 76px;
  line-height: 106px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-collection-content .title {
    font-size: 66px;
    line-height: 76px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-collection-content .title {
    font-size: 56px;
    line-height: 66px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collection-content .title {
    font-size: 30px;
    line-height: 42px;
  }
}

.shop-collection-content p {
  font-size: 16px;
  line-height: 32px;
  max-width: 770px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-collection-content p {
    max-width: 670px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-collection-content p {
    max-width: 670px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collection-content p {
    font-size: 14px;
    line-height: 26px;
  }
}

/* Product Description CSS */
.description-nav ul {
  margin: 30px auto 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-nav ul {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .description-nav ul {
    margin: 20px auto 25px;
  }
}

.description-nav ul li {
  margin-right: 60px;
  transition: all .3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .description-nav ul li {
    margin-right: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-nav ul li {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .description-nav ul li {
    margin-right: 15px;
  }
}

.description-nav ul li:last-child {
  margin-right: 0px;
}

.description-nav ul li a {
  font-size: 26px;
  font-weight: 400;
  padding: 0 0 30px;
  text-align: center;
  color: #1a1a1a;
  position: relative;
  transition: all .3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .description-nav ul li a {
    font-size: 18px;
    line-height: 20px;
    padding: 0 0 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-nav ul li a {
    font-size: 16px;
    line-height: 16px;
    padding: 0 0 18px;
  }
}

@media only screen and (max-width: 767px) {
  .description-nav ul li a {
    font-size: 16px;
    line-height: 16px;
    padding: 0 0 18px;
  }
}

.description-nav ul li a::after {
  position: absolute;
  width: 30%;
  height: 5px;
  background-color: #1a1a1a;
  content: "";
  left: 0;
  bottom: 0;
  opacity: 0;
  transition: all .3s;
}

.description-nav ul li a.active {
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .description-nav ul li a.active {
    font-weight: 400;
  }
}

.description-nav ul li a.active::after {
  opacity: 1;
  width: 100%;
  height: 5px;
}

.description-nav ul li a:hover::after {
  opacity: 1;
  width: 100%;
  height: 5px;
}

.single-product-tab .decription-content p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
}

.single-product-tab .decription-content .list-container {
  list-style: none;
  margin-bottom: 30px;
}

.single-product-tab .decription-content .list-container .list-items {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .single-product-tab .decription-content .list-container .list-items {
    margin-bottom: 10px;
  }
}

.single-product-tab .decription-content .list-container .list-items .list-icon {
  font-size: 8px;
}

.single-product-tab .decription-content .list-container .list-items .list-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-left: 5px;
}

.single-product-tab .product-review-inner .title {
  font-size: 36px;
  display: block;
  font-weight: 400;
  margin: 0 0 5px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .single-product-tab .product-review-inner .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.single-product-tab .product-review-inner .review-list {
  margin: 0;
  list-style: none;
  padding: 0;
  background: #f9f9f9;
}

.single-product-tab .product-review-inner .review-list .review {
  padding: 30px 50px;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .single-product-tab .product-review-inner .review-list .review {
    padding: 29px 20px;
    display: block;
  }
}

.single-product-tab .product-review-inner .review-list .review img {
  width: 90px;
  height: 90px;
  margin-right: 50px;
  border-radius: 50%;
}

.single-product-tab .product-review-inner .review-list .review .content {
  width: calc(100% - 140px);
}

@media only screen and (max-width: 767px) {
  .single-product-tab .product-review-inner .review-list .review .content {
    width: 100%;
    margin-top: 24px;
  }
}

.single-product-tab .product-review-inner .review-list .review .content .product-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .single-product-tab .product-review-inner .review-list .review .content .product-meta {
    display: block;
  }
}

.single-product-tab .product-review-inner .review-list .review .content .product-meta .meta {
  font-size: 12px;
  margin-bottom: 0;
}

.single-product-tab .product-review-inner .review-list .review .content .product-meta .meta strong {
  font-size: 14px;
  color: #2f2f2f;
}

.single-product-tab .product-review-inner .review-list .review .content .product-meta .rating {
  color: #f5b223;
}

.single-product-tab .product-review-inner .review-list .review .content .product-meta .rating span {
  display: inline-block;
  line-height: 14px;
  font-size: 14px;
}

.single-product-tab .product-review-inner .review-list .review .content .desc {
  padding-bottom: 30px;
  font-size: 16px;
  margin: 0;
}

.single-product-tab .product-review-inner .review-list .review.thread-odd {
  background: #efefef;
}

.single-product-tab .information-list {
  padding: 0;
  list-style: none;
  width: 500px;
  margin: 0 auto;
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .single-product-tab .information-list {
    width: auto;
  }
}

.single-product-tab .information-list li {
  padding: 2px 0;
  font-size: 14px;
  line-height: 24px;
}

.single-product-tab .information-list li span {
  float: right;
  text-align: left;
  width: 400px;
}

@media only screen and (max-width: 767px) {
  .single-product-tab .information-list li span {
    width: auto;
    float: none;
  }
}

.product-about .title {
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .product-about .title {
    font-size: 24px;
  }
}

.product-about p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
}

.product-shipping p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
}

.review-form {
  padding-top: 50px;
}

.review-form .title h3 {
  color: #2f2f2f;
  font-size: 36px;
  display: block;
  font-weight: 400;
  margin: 0 0 5px;
}

@media only screen and (max-width: 767px) {
  .review-form .title h3 {
    font-size: 24px;
  }
}

.review-form .title p {
  font-style: italic;
  font-size: 14px;
  margin-bottom: 30px;
  color: #535353;
}

.review-form .input-box {
  margin-bottom: 30px;
}

.review-form .input-box label {
  display: block;
  padding-bottom: 5px;
  font-size: 14px;
  color: #535353;
  font-weight: 400;
}

.review-form .input-box input, .review-form .input-box .contact__form--3 select, .contact__form--3 .review-form .input-box select, .review-form .input-box .contact__form--3 textarea, .contact__form--3 .review-form .input-box textarea {
  width: 100%;
  max-width: 100%;
  -webkit-appearance: none;
  padding: 17px 25px;
  border-style: solid;
  border-width: 1px;
  border-color: #e8e8e8;
  height: 60px;
}

.review-form .input-box textarea {
  width: 100%;
  height: 150px;
  max-width: 100%;
  vertical-align: top;
  -webkit-appearance: none;
  border-style: solid;
  border-width: 1px;
  border-color: #e8e8e8;
  padding: 17px 25px;
}

/* Related Product CSS */
@media only screen and (max-width: 767px) {
  .related-product-slider .slick-dots {
    margin-top: 0;
  }
}

/*----------------------------------------*/
/*  12. Cart CSS
/*----------------------------------------*/
.cart-flyout .cart-flyout-inner {
  position: relative;
}

.cart-flyout .cart-flyout-inner .btn-close-cart {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 30px;
  line-height: 30px;
}

.cart-flyout .cart-flyout-inner .btn-close-cart i {
  color: #535353;
  transition: all 0.3s ease-in-out;
}

.cart-flyout .cart-flyout-inner .btn-close-cart i:hover {
  color: #fc4f4f;
}

.cart-flyout .cart-flyout-inner .cart-flyout__content {
  padding-top: 50px;
}

.cart-flyout .cart-flyout-inner .cart-flyout__heading {
  margin-bottom: 40px;
  font-size: 18px;
}

.cart-flyout .cart-flyout-inner .widget_shopping_cart_content {
  overflow: hidden;
  opacity: 1;
}

.cart-flyout .cart-flyout-inner .widget_shopping_cart_content .product_list_widget {
  margin: 0 -30px 0 0;
  padding: 0 30px 0 0;
  max-height: calc(100vh - 430px);
  overflow: auto;
}

.cart-flyout .cart-flyout-inner .widget_shopping_cart_content .product_list_widget li {
  display: flex;
  position: relative;
  margin: 10px 0;
}

.cart-flyout .cart-flyout-inner .widget_shopping_cart_content .product_list_widget li .thumb img {
  max-width: 70px;
  margin-right: 20px;
}

.cart-flyout .cart-flyout-inner .widget_shopping_cart_content .product_list_widget li .content h6 {
  display: block;
  font-size: 14px;
  line-height: normal;
  margin: 0 0 5px;
}

.cart-flyout .cart-flyout-inner .widget_shopping_cart_content .product_list_widget li .content h6 a {
  color: #2f2f2f;
  transition: all 0.3s ease-in-out;
}

.cart-flyout .cart-flyout-inner .widget_shopping_cart_content .product_list_widget li .content h6 a:hover {
  color: #1A1A1A;
}

.cart-flyout .cart-flyout-inner .widget_shopping_cart_content .product_list_widget li .content .quntity {
  color: #2f2f2f;
}

.cart-flyout .cart-flyout-inner .widget_shopping_cart_content .product_list_widget li .content .remove-btn {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 400;
  font-size: 14px;
  height: 20px;
  line-height: 17px;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: right;
  width: 20px;
  padding: 0;
  border: 0 none;
  transition: all 0.3s ease-in-out;
}

.cart-flyout .cart-flyout-inner .widget_shopping_cart_content .product_list_widget li .content .remove-btn:hover {
  color: #1A1A1A;
}

.cart-flyout .cart-flyout-inner .minicart__total {
  font-weight: 400;
  font-size: 14px;
  color: #535353;
  margin-bottom: 35px;
  padding-top: 15px;
}

.cart-flyout .cart-flyout-inner .minicart__total .price {
  font-size: 18px;
  font-weight: 500;
}

.cart-flyout .cart-flyout-inner .cart__btn a {
  width: 100%;
  display: block;
  margin: 10px 0 0;
  text-align: center;
  line-height: 20px;
  padding: 20px;
  transition: all .25s ease-out;
  color: #fff;
  background-color: #2f2f2f;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}

.cart-flyout .cart-flyout-inner .cart__btn a:hover {
  background-color: #fc4f4f;
}

/*=============================
    Search Overlay 
===============================*/
.search-flyoveray {
  position: fixed;
  background: #080808;
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  transition: all .25s ease-out;
}

.open-cart-aside .search-flyoveray {
  visibility: visible;
  opacity: .7;
}

.cart-flyout {
  position: fixed;
  top: 0;
  width: 420px;
  padding: 50px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: 0;
  visibility: hidden;
  opacity: 0;
  background: #fff;
  transition: all .25s ease-in;
  transform: translate(200px, 0);
  -webkit-transform: translate(200px, 0);
  box-shadow: 0px 0px 87px 0px rgba(0, 0, 0, 0.09);
}

@media only screen and (max-width: 767px) {
  .cart-flyout {
    width: 290px;
    padding: 25px;
  }
}

.open-cart-aside .cart-flyout {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
}

/*-- Quantity --*/
.quantity {
  color: #1a1a1a;
  position: relative;
  font-size: 20px;
  font-weight: 500;
  width: 120px;
  height: 54px;
  line-height: 1;
}

.quantity-input {
  border: 1px solid #b7b7b7;
  border-radius: 500px;
  font-size: 14px;
  text-align: center;
  color: #1a1a1a;
  width: 100%;
  height: 100%;
}

.quantity .qtybutton {
  position: absolute;
  cursor: pointer;
  top: 0;
  font-weight: 700;
  height: 54px;
  width: 36px;
  line-height: 54px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.quantity .qtybutton.inc {
  right: 0;
  text-align: left;
}

.quantity .qtybutton.dec {
  left: 0;
  text-align: right;
}

.quantity--2 {
  width: 80px;
  height: 55px;
  line-height: 30px;
}

.quantity--2 .quantity-input {
  border-radius: 0;
}

.quantity--2 .qtybutton {
  width: 20px;
  height: 50px;
  line-height: 50px;
}

.coupon-form-areas {
  display: inline-block;
  border-bottom: 1px solid #D8D8D8;
  width: 100%;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .coupon-form-areas {
    margin-bottom: 20px;
  }
}

.coupon-form-areas input, .coupon-form-areas .contact__form--3 select, .contact__form--3 .coupon-form-areas select, .coupon-form-areas .contact__form--3 textarea, .contact__form--3 .coupon-form-areas textarea {
  width: 100%;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  padding-right: 120px;
}

.coupon-form-areas button {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: #1a1a1a;
}

.coupon-form-areas button:hover {
  color: #fc4f4f;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-totals {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-totals {
    margin-top: 30px;
  }
}

.cart-calculator {
  background-color: #f7f7f7;
  padding: 30px 30px 0;
  border-width: 0;
  padding-bottom: 20px;
}

.cart-calculator h2 {
  font-size: 18px;
  padding: 20px 0;
  margin: 0 0 20px;
  font-weight: 700;
}

.cart-calculator__item {
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  color: #1a1a1a;
  font-weight: 600;
  padding: 5px 0 15px;
}

.cart-calculator__item--head {
  flex-basis: 50%;
  max-width: 50%;
}

.cart-calculator__item--value {
  flex-basis: 50%;
  max-width: 50%;
}

.cart-calculator__item--value > span,
.cart-calculator__item--value > a {
  display: inline-block;
}

.cart-calculator__item:last-child {
  border-top: 1px solid #DBDBDB;
  padding-top: 15px;
}

.checkout-btn {
  font-size: 12px;
  display: block;
  cursor: pointer;
  padding: 15px 50px;
  text-align: center;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  background-color: #1a1a1a;
  color: #ffffff;
}

.checkout-btn:hover {
  color: #ffffff;
  background-color: #fc4f4f;
}

/*---------------------------------------
/*    13. Checkout CSS
-----------------------------------------*/
.customer-zone > p {
  font-size: 14px;
  line-height: 24px;
  color: #343538;
  position: relative;
  padding: 14px 30px 14px 60px;
  background-color: #eeeff2;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .customer-zone > p {
    font-size: 12px;
    line-height: 20px;
  }
}

.customer-zone > p:before {
  content: "\f05a";
  color: #6dde6d;
  display: inline-block;
  position: absolute;
  font-family: fontawesome;
  left: 30px;
}

.checkout-login-info {
  margin: 20px 0 0;
  display: none;
}

.checkout-login-info p {
  color: #8a8a8a;
  font-size: 12px;
  margin: 0 0 12px;
}

.checkout-login-info form {
  display: block;
  overflow: hidden;
}

.checkout-login-info form .sin-checkout-login {
  margin-bottom: 30px;
}

.checkout-login-info form .sin-checkout-login label {
  color: #343538;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0 0 5px;
}

.checkout-login-info form .sin-checkout-login label span {
  color: red;
}

.checkout-login-info form .sin-checkout-login input, .checkout-login-info form .sin-checkout-login .contact__form--3 select, .contact__form--3 .checkout-login-info form .sin-checkout-login select, .checkout-login-info form .sin-checkout-login .contact__form--3 textarea, .contact__form--3 .checkout-login-info form .sin-checkout-login textarea {
  border: 1px solid #e8e8e8;
  height: 60px;
  background-color: transparent;
  width: 100%;
}

.checkout-login-info form .sin-checkout-login input:focus, .checkout-login-info form .sin-checkout-login .contact__form--3 select:focus, .contact__form--3 .checkout-login-info form .sin-checkout-login select:focus, .checkout-login-info form .sin-checkout-login .contact__form--3 textarea:focus, .contact__form--3 .checkout-login-info form .sin-checkout-login textarea:focus {
  border: 1px solid #343538;
}

.checkout-login-info form .button-remember-wrap {
  display: block;
  overflow: hidden;
}

.checkout-login-info form .button-remember-wrap button {
  color: #8a8a8a;
  border: 1px solid #aaa;
  font-size: 12px;
  padding: 8px 30px 10px;
  display: inline-block;
  float: left;
  margin-right: 20px;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn {
  display: flex;
  margin: 8px 0 0;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn input, .checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn .contact__form--3 select, .contact__form--3 .checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn select, .checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn .contact__form--3 textarea, .contact__form--3 .checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn textarea {
  height: 15px;
  margin: 0;
  position: relative;
  top: 5px;
  width: 17px;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn label {
  font-size: 12px;
  color: #343538;
  margin: 0 0 0 5px;
}

.checkout-login-info form .lost-password {
  margin: 20px 0 11px;
}

.checkout-login-info form .lost-password a {
  color: #8a8a8a;
  font-size: 12px;
}

.checkout-login-info .checkout-login-social span {
  color: #8a8a8a;
  font-size: 12px;
  margin: 0 0 13px;
  display: block;
}

.checkout-login-info .checkout-login-social ul li {
  display: inline-block;
  margin: 0 14px 0 0;
}

.checkout-login-info .checkout-login-social ul li:last-child {
  margin: 0 0px 0 0;
}

@media only screen and (max-width: 767px) {
  .checkout-login-info .checkout-login-social ul li {
    margin: 0 5px 0 0;
  }
}

.checkout-login-info .checkout-login-social ul li a {
  background-color: #f5f5f5;
  color: #8a8a8a;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  float: left;
  font-size: 11px;
  padding: 20px;
  padding: 24px 106px 25px;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-login-info .checkout-login-social ul li a {
    padding: 24px 70px 25px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info .checkout-login-social ul li a {
    padding: 15px 13px 16px;
  }
}

.checkout-login-info .checkout-login-social ul li a:hover {
  background-color: #4fb3ec;
  color: #fff;
}

.checkout-login-info2 {
  display: none;
  margin: 20px 0 0;
}

.checkout-login-info2 ul li {
  display: inline-block;
}

.checkout-login-info2 ul li a {
  color: #8a8a8a;
  font-size: 12px;
}

.checkout-login-info2 ul li a:hover {
  color: #1A1A1A;
}

.checkout-login-info3 {
  display: none;
  margin-top: 20px;
}

.checkout-login-info3 form input[type="text"], .checkout-login-info3 form .contact__form--3 select[type="text"], .contact__form--3 .checkout-login-info3 form select[type="text"], .checkout-login-info3 form .contact__form--3 textarea[type="text"], .contact__form--3 .checkout-login-info3 form textarea[type="text"] {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  width: 48%;
  color: #8a8a8a;
  font-size: 12px;
  padding: 0 20px;
}

.checkout-login-info3 form input[type="text"]:focus, .checkout-login-info3 form .contact__form--3 select[type="text"]:focus, .contact__form--3 .checkout-login-info3 form select[type="text"]:focus, .checkout-login-info3 form .contact__form--3 textarea[type="text"]:focus, .contact__form--3 .checkout-login-info3 form textarea[type="text"]:focus {
  border: 1px solid #343538;
}

.checkout-login-info3 form input[type="submit"], .checkout-login-info3 form .contact__form--3 select[type="submit"], .contact__form--3 .checkout-login-info3 form select[type="submit"], .checkout-login-info3 form .contact__form--3 textarea[type="submit"], .contact__form--3 .checkout-login-info3 form textarea[type="submit"] {
  background: #343538 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 50px;
  margin-left: 6px;
  padding: 5px 30px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  width: inherit;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .checkout-login-info3 form input[type="submit"], .checkout-login-info3 form .contact__form--3 select[type="submit"], .contact__form--3 .checkout-login-info3 form select[type="submit"], .checkout-login-info3 form .contact__form--3 textarea[type="submit"], .contact__form--3 .checkout-login-info3 form textarea[type="submit"] {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info3 form input[type="submit"], .checkout-login-info3 form .contact__form--3 select[type="submit"], .contact__form--3 .checkout-login-info3 form select[type="submit"], .checkout-login-info3 form .contact__form--3 textarea[type="submit"], .contact__form--3 .checkout-login-info3 form textarea[type="submit"] {
    padding: 5px 16px;
  }
}

.checkout-login-info3 form input[type="submit"]:hover, .checkout-login-info3 form .contact__form--3 select[type="submit"]:hover, .contact__form--3 .checkout-login-info3 form select[type="submit"]:hover, .checkout-login-info3 form .contact__form--3 textarea[type="submit"]:hover, .contact__form--3 .checkout-login-info3 form textarea[type="submit"]:hover {
  background-color: #fc4f4f;
}

.billing-info-wrap h3 {
  font-size: 18px;
  font-weight: 700;
  position: relative;
  margin: 0 0 55px;
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap h3 {
    margin-bottom: 30px;
  }
}

.billing-info-wrap h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -12px;
  background-color: #000;
  width: 50px;
  height: 2px;
}

.billing-info-wrap .billing-info label {
  color: #343538;
  font-size: 12px;
  text-transform: uppercase;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .billing-info label abbr {
  color: red;
}

.billing-info-wrap .billing-info input, .billing-info-wrap .billing-info .contact__form--3 select, .contact__form--3 .billing-info-wrap .billing-info select, .billing-info-wrap .billing-info .contact__form--3 textarea, .contact__form--3 .billing-info-wrap .billing-info textarea {
  width: 100% !important;
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #8a8a8a;
  font-size: 14px;
}

.billing-info-wrap .billing-info input:focus, .billing-info-wrap .billing-info .contact__form--3 select:focus, .contact__form--3 .billing-info-wrap .billing-info select:focus, .billing-info-wrap .billing-info .contact__form--3 textarea:focus, .contact__form--3 .billing-info-wrap .billing-info textarea:focus {
  border: 1px solid #343538;
}

.billing-info-wrap .billing-info input.billing-address, .billing-info-wrap .billing-info .contact__form--3 select.billing-address, .contact__form--3 .billing-info-wrap .billing-info select.billing-address, .billing-info-wrap .billing-info .contact__form--3 textarea.billing-address, .contact__form--3 .billing-info-wrap .billing-info textarea.billing-address {
  margin-bottom: 20px;
}

.billing-info-wrap .billing-select {
  display: flex;
  flex-wrap: wrap;
}

.billing-info-wrap .billing-select label {
  color: #343538;
  font-size: 12px;
  text-transform: uppercase;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .billing-select label abbr {
  color: red;
}

.billing-info-wrap .billing-select select {
  border: 1px solid #e6e6e6;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #8a8a8a;
  font-size: 12px;
  height: 50px;
  padding: 2px 20px;
  background: rgba(0, 0, 0, 0) url("../../assets/img/icon-img/select.png") no-repeat scroll right 18px center;
  cursor: pointer;
}

.billing-info-wrap .billing-select select option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  color: #8a8a8a;
  font-size: 12px;
}

.billing-info-wrap .checkout-account input, .billing-info-wrap .checkout-account .contact__form--3 select, .contact__form--3 .billing-info-wrap .checkout-account select, .billing-info-wrap .checkout-account .contact__form--3 textarea, .contact__form--3 .billing-info-wrap .checkout-account textarea {
  border: 1px solid #9fa0a2;
  display: inline-block;
  float: left;
  height: 10px;
  width: 10px;
  position: relative;
  top: 6px;
}

.billing-info-wrap .checkout-account span {
  color: #343538;
  font-weight: 400;
  margin: 0 0 0 12px;
  font-size: 12px;
  text-transform: uppercase;
}

.billing-info-wrap .checkout-account-toggle label {
  color: #343538;
  font-size: 12px;
  text-transform: uppercase;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .checkout-account-toggle label abbr {
  color: red;
}

.billing-info-wrap .checkout-account-toggle input, .billing-info-wrap .checkout-account-toggle .contact__form--3 select, .contact__form--3 .billing-info-wrap .checkout-account-toggle select, .billing-info-wrap .checkout-account-toggle .contact__form--3 textarea, .contact__form--3 .billing-info-wrap .checkout-account-toggle textarea {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  color: #8a8a8a;
  font-size: 12px;
  padding: 2px 20px;
  width: 100%;
}

.billing-info-wrap .checkout-account-toggle input:focus, .billing-info-wrap .checkout-account-toggle .contact__form--3 select:focus, .contact__form--3 .billing-info-wrap .checkout-account-toggle select:focus, .billing-info-wrap .checkout-account-toggle .contact__form--3 textarea:focus, .contact__form--3 .billing-info-wrap .checkout-account-toggle textarea:focus {
  border: 1px solid #343538;
}

.billing-info-wrap .different-address {
  display: none;
}

.billing-info-wrap .additional-info-wrap {
  margin: 3px 0 0;
}

.billing-info-wrap .additional-info-wrap label {
  color: #343538;
  font-size: 12px;
  text-transform: uppercase;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .additional-info-wrap textarea {
  min-height: 120px;
  background-color: #f7f7f7;
  border: 1px solid #e8e8e8;
  padding: 20px;
  color: #8a8a8a;
  font-size: 12px;
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .billing-info-wrap.mr-130 {
    margin-right: 30px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .billing-info-wrap.mr-130 {
    margin-right: 0px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .billing-info-wrap.mr-130 {
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap.mr-130 {
    margin-right: 0px !important;
  }
}

.your-order-area {
  padding: 40px 50px 50px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  border-color: rgba(52, 53, 56, 0.1);
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .your-order-area {
    padding: 40px 25px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .your-order-area {
    padding: 40px 12px 50px;
    margin-top: 30px;
  }
}

.your-order-area h3 {
  font-size: 18px;
  font-weight: 700;
  position: relative;
  margin: 0 0 45px;
}

.your-order-area h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -12px;
  background-color: #000;
  width: 50px;
  height: 2px;
}

.your-order-area .your-order-wrap .your-order-info {
  border-bottom: 1px solid #e8e8e8;
}

.your-order-area .your-order-wrap .your-order-info ul li {
  color: #343538;
  font-size: 12px;
  padding: 0 0 7px;
}

.your-order-area .your-order-wrap .your-order-info ul li span {
  float: right;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-info.order-shipping {
  padding: 15px 0 13px;
  display: block;
  overflow: hidden;
}

.your-order-area .your-order-wrap .your-order-info.order-shipping ul li > p {
  float: right;
  font-size: 12px;
  color: #343538;
  text-align: right;
}

.your-order-area .your-order-wrap .your-order-info.order-total {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-total ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #e8e8e8;
  padding: 24px 0 23px;
}

.your-order-area .your-order-wrap .your-order-middle ul li {
  font-size: 14px;
  color: #343538;
  margin: 0 0 11px;
}

.your-order-area .your-order-wrap .your-order-middle ul li:last-child {
  margin-bottom: 0;
}

.your-order-area .your-order-wrap .your-order-middle ul li span {
  float: right;
  font-size: 16px;
}

.your-order-area .your-order-wrap .payment-method {
  margin: 17px 0 0;
}

.your-order-area .your-order-wrap .payment-method .sin-payment {
  margin-bottom: 20px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment input, .your-order-area .your-order-wrap .payment-method .sin-payment .contact__form--3 select, .contact__form--3 .your-order-area .your-order-wrap .payment-method .sin-payment select, .your-order-area .your-order-wrap .payment-method .sin-payment .contact__form--3 textarea, .contact__form--3 .your-order-area .your-order-wrap .payment-method .sin-payment textarea {
  width: auto;
  display: inline-block;
  float: left;
  height: auto;
  margin-top: 10px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label {
  font-size: 12px;
  text-transform: uppercase;
  color: #343538;
  margin: 0px 0 0 21px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .your-order-area .your-order-wrap .payment-method .sin-payment label {
    display: block;
  }
}

.your-order-area .your-order-wrap .payment-method .sin-payment label img {
  max-width: 140px;
  display: inline-block;
  margin: 0 5px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label a {
  text-decoration: underline;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box {
  margin: 6px 0 0;
  display: none;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box p {
  font-size: 12px;
  margin: 0;
}

.your-order-area .your-order-wrap .payment-method .sin-payment.sin-payment-3 input, .your-order-area .your-order-wrap .payment-method .sin-payment.sin-payment-3 .contact__form--3 select, .contact__form--3 .your-order-area .your-order-wrap .payment-method .sin-payment.sin-payment-3 select, .your-order-area .your-order-wrap .payment-method .sin-payment.sin-payment-3 .contact__form--3 textarea, .contact__form--3 .your-order-area .your-order-wrap .payment-method .sin-payment.sin-payment-3 textarea {
  margin-top: 10px;
}

.your-order-area .Place-order a {
  background-color: #343538;
  font-size: 12px;
  display: block;
  cursor: pointer;
  padding: 17px 50px 18px;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  color: #fff;
  text-align: center;
}

.your-order-area .Place-order a:hover {
  background-color: #fc4f4f;
}

/*----------------------------------------*/
/*  14. Wishlist CSS
/*----------------------------------------*/
.table {
  margin-bottom: 0;
}

.table thead {
  background-color: #f7f7f7;
}

.table thead th, .table th {
  padding: 13px 0;
  font-weight: 400;
  border-top-width: 0;
  border-bottom-width: 0;
  vertical-align: middle;
  font-size: 12px;
  color: #1a1a1a;
}

.table td {
  vertical-align: middle;
  border: 0px solid #1a1a1a;
}

.table th.wide-column, .table td.wide-column {
  min-width: 230px;
}

.table tbody td {
  padding: 29px 0;
  border-bottom: 1px solid #dadada;
  min-width: 120px;
}

.table tbody td.product-remove {
  min-width: 30px;
}

.table tbody td.product-remove .remove:before {
  font-family: 'dliconoutline';
  content: "\ed2b";
  font-size: 16px;
}

.table tbody td.product-remove .remove:hover {
  color: #fc4f4f;
}

.table tbody td.product-thumbnail {
  background-color: transparent;
  width: 90px;
}

.table tbody td.product-thumbnail img {
  width: 70px;
  height: auto;
}

.table tbody td.product-name h3 {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.table tbody td.product-stock {
  color: #1a1a1a;
}

.table tbody td.product-out_stock {
  color: #fc4f4f;
}

.table tbody td.product-price .product-price-wrapper .money, .table tbody td.product-total-price .product-price-wrapper .money {
  font-size: 16px;
  color: #1a1a1a;
  font-weight: 700;
}

.table tbody td.product-quantity {
  display: table-cell;
}

.table tbody td.product-quantity .quantity {
  margin-left: auto;
  margin-right: auto;
}

.table.shop_attributes th, .table.shop_attributes td {
  padding: 4px 0;
  text-transform: none;
}

.table.shop_attributes th a, .table.shop_attributes td a {
  color: #4d4d4d;
}

.table.shop_attributes th a:hover, .table.shop_attributes td a:hover {
  color: #fc4f4f;
}

.table.shop_attributes td {
  font-size: 12px;
}

.table.shop_attributes th {
  min-width: 140px;
  border: transparent;
  color: #4d4d4d;
  font-weight: 400;
  background-color: transparent;
  font-size: 12px;
}

.table.order-table thead {
  background-color: transparent;
}

.table.order-table th {
  background-color: transparent;
  color: #1a1a1a;
  border: none !important;
}

.table.order-table th strong {
  font-size: 12px;
}

.table.order-table th, .table.order-table td {
  padding-left: 0;
}

.table.order-table td {
  font-size: 14px;
  border: none;
  padding: 15px 0;
}

.table.product-table thead th {
  padding-left: 0;
  padding-right: 0;
}

.table.product-table .product-remove {
  min-width: 40px;
}

.table.product-table .product-thumbnail img {
  width: 70px;
}

.table.compare-table tbody tr:last-child th {
  border-bottom: 1px solid #1a1a1a;
}

.table.compare-table tbody th, .table.compare-table tbody td {
  border-width: 1px;
  padding: 10px;
}

.table.compare-table tbody th {
  min-width: 200px;
  vertical-align: middle;
  border-color: #1a1a1a;
  border-left: 1px solid #1a1a1a;
  padding-left: 20px;
  color: #1a1a1a;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0;
}

.table.compare-table tbody td {
  min-width: 250px;
  border-color: #1a1a1a;
  text-align: center;
  vertical-align: middle;
  line-height: 1.4;
}

.table.compare-table tbody .product-name {
  font-size: 14px;
  margin: 20px 0;
}

.table.wishlist-table tbody td {
  border-bottom: 1px solid #1a1a1a;
}

/*---------------------------------------
    15. Blog CSS
-----------------------------------------*/
/*-- Blog Slider --*/
.related-post-slider .slick-dots {
  margin-top: 0;
}

/*-- Blog --*/
.blog .blog-inner.blog-border {
  border-top: 2px solid #4D4D4D;
  border-bottom: 2px solid #4D4D4D;
  padding-top: 20px;
  padding-bottom: 20px;
}

.blog .blog-inner .media-image {
  margin-bottom: 20px;
  position: relative;
}

.blog .blog-inner .media-image .video-icon {
  background-image: url("../images/icons/button-play-3.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 66px;
  height: 66px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50% 50% 50% 50%;
  box-shadow: 0px 0px 25px 0px rgba(255, 255, 255, 0.5);
}

.blog .blog-inner .media-image .video-icon::before {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  opacity: 0;
  transition: all .5s;
  z-index: 1;
}

.blog .blog-inner .media-image .image {
  display: block;
}

.blog .blog-inner .media-image .image img {
  width: 100%;
}

.blog .blog-inner .content .meta {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0;
  margin-bottom: 10px;
}

.blog .blog-inner .content .meta li {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
  margin: 0 5px;
  margin-bottom: 5px;
}

.blog .blog-inner .content .meta li:first-child {
  margin-left: 0;
}

.blog .blog-inner .content .meta li a:hover {
  color: #fc4f4f;
}

.blog .blog-inner .content .title {
  font-size: 32px;
  line-height: 45px;
  font-weight: 400;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog .blog-inner .content .title {
    font-size: 24px;
    line-height: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .blog .blog-inner .content .title {
    font-size: 20px;
    line-height: 26px;
  }
}

.blog .blog-inner .content .title a:hover {
  color: #fc4f4f;
}

.blog .blog-inner .content .title.fz-24 {
  font-size: 24px;
  line-height: 33px;
}

@media only screen and (max-width: 767px) {
  .blog .blog-inner .content .title.fz-24 {
    font-size: 20px;
    line-height: 26px;
  }
}

.blog .blog-inner .content p {
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 0;
}

.blog .blog-inner .content .blog-btn {
  position: relative;
  margin-top: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog .blog-inner .content .blog-btn {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .blog .blog-inner .content .blog-btn {
    margin-top: 10px;
  }
}

.blog .blog-inner .content .blog-btn:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #D8D8D8;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.blog .blog-inner .content .blog-btn .read-more {
  font-size: 16px;
  font-weight: 700;
  color: #1a1a1a;
  text-transform: capitalize;
  display: inline-block;
  transition: all .5s;
  background-color: #ffffff;
  position: relative;
  z-index: 2;
  padding: 20px 20px 20px 0;
  line-height: 1;
}

.blog .blog-inner .content .blog-btn .read-more:hover {
  color: #fc4f4f;
}

.blog .blog-inner:hover .media-image .video-icon::before {
  opacity: 1;
  left: -15px;
  right: -15px;
  top: -15px;
  bottom: -15px;
}

.quote-content-area {
  padding: 50px 40px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .quote-content-area {
    padding: 15px 10px;
  }
}

.quote-content-area .quote-content {
  font-size: 46px !important;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  line-height: 1.15 !important;
  color: #1a1a1a;
  max-width: 625px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quote-content-area .quote-content {
    font-size: 36px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quote-content-area .quote-content {
    font-size: 30px !important;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .quote-content-area .quote-content {
    font-size: 22px !important;
    line-height: 28px !important;
    margin-bottom: 0;
  }
}

.quote-content-area .quote-author {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: #1a1a1a;
  position: relative;
  padding: 0 50px;
  margin-top: 20px;
}

.quote-content-area .quote-author::before {
  content: "";
  top: .8em;
  width: 40px;
  height: 2px;
  left: 0;
  background-color: #1a1a1a;
  position: absolute;
  z-index: 1;
}

/*-- Blog Details --*/
.blog-details-content .entry-content .title {
  font-size: 66px !important;
  line-height: 1.3 !important;
  font-weight: 700 !important;
  margin-bottom: 15px !important;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .blog-details-content .entry-content .title {
    font-size: 36px !important;
    line-height: 46px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-content .entry-content .title {
    font-size: 36px !important;
    line-height: 46px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-content .entry-content .title {
    font-size: 36px !important;
    line-height: 46px !important;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-content .entry-content .title {
    font-size: 26px !important;
    line-height: 32px !important;
  }
}

.fun-fuct-area .single-funfact {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .fun-fuct-area .single-funfact {
    margin-bottom: 20px;
  }
}

.fun-fuct-area .single-funfact h2 {
  font-size: 36px;
  line-height: 1;
}

.fun-fuct-area .single-funfact h4 {
  font-size: 18px;
  line-height: 45px;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fun-fuct-area .single-funfact h4 {
    font-size: 16px;
    line-height: 34px;
  }
}

.post-block-cover {
  width: auto;
  max-width: 1000%;
  background-size: cover;
  background-position: center bottom;
  padding-top: 53%;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  margin-top: 95px;
  margin-bottom: 85px;
  overflow: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .post-block-cover {
    margin-top: 75px;
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .post-block-cover {
    margin-top: 65px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .post-block-cover {
    margin-top: 55px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .post-block-cover {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}

.media-image-text-content .title {
  font-size: 26px !important;
  line-height: 1;
  margin: 15px 0 !important;
  font-weight: 400 !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .media-image-text-content .title {
    font-size: 24px !important;
    line-height: 1;
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .media-image-text-content .title {
    font-size: 24px !important;
    line-height: 1;
  }
}

@media only screen and (max-width: 767px) {
  .media-image-text-content .title {
    font-size: 24px !important;
    line-height: 1;
  }
}

.block-quote {
  margin: 50px 0px;
  color: #1a1a1a;
  line-height: 1em;
  padding: 0 20px;
  border-left: 3px solid #212121;
}

@media only screen and (max-width: 767px) {
  .block-quote {
    margin: 20px 0px;
  }
}

.block-quote p em {
  font-size: 46px !important;
  line-height: 1;
  margin-bottom: 25px !important;
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  display: block;
}

@media only screen and (max-width: 767px) {
  .block-quote p em {
    font-size: 26px !important;
    line-height: 28px;
  }
}

.block-quote cite {
  font-size: 14px;
  line-height: 1;
  margin-top: 30px;
  display: block;
}

.entry-footer {
  padding-bottom: 32px;
  margin-bottom: 20px;
  font-size: 16px;
  margin-top: 50px;
  border-bottom: 1px solid #4D4D4D;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .entry-footer {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .entry-footer {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .entry-footer {
    flex-wrap: wrap;
    margin-top: 10px;
  }
}

.entry-footer .footer-left {
  font-style: italic;
  color: #535353;
}

.entry-footer .footer-left .taglist a {
  display: block;
  color: #1a1a1a;
}

.entry-footer .footer-left .taglist a:hover {
  color: #fc4f4f;
}

.social-icons {
  margin: 0 -5px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .social-icons {
    margin: 0 -3px;
  }
}

.social-icons a {
  width: 35px;
  height: 35px;
  font-size: 16px;
  line-height: 36px;
  color: #ffffff;
  border-radius: 50%;
  margin: 0 5px;
}

@media only screen and (max-width: 767px) {
  .social-icons a {
    margin: 0 1px;
  }
}

.social-icons a.facebook {
  background-color: #4867AA;
}

.social-icons a.twitter {
  background-color: #1DA1F2;
}

.social-icons a.pinterest {
  background-color: #BD081B;
}

.social-icons a.youtube {
  background-color: #FE0000;
}

.post-navigation .post-links {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 479px) {
  .post-navigation .post-links {
    flex-wrap: wrap;
  }
}

.post-navigation .post-links a .nav-text {
  font-size: 10px;
  line-height: 17px;
  font-weight: 700;
  text-transform: uppercase;
}

.post-navigation .post-links a:hover {
  color: #fc4f4f;
}

/*---------------------------------------
    16. Comment CSS
-----------------------------------------*/
/*-- Comment Wrap --*/
.post-comment-wrapper .title {
  font-size: 32px;
  line-height: 1;
  display: block;
  font-weight: 400;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .post-comment-wrapper .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.comment-list {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.comment-list .comment {
  padding: 0 50px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .comment-list .comment {
    padding: 20px;
    flex-wrap: wrap;
  }
}

.comment-list .comment .border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.comment-list .comment .thumbnail img {
  width: 95px;
  height: 95px;
  margin-right: 50px;
  border-radius: 50%;
}

.comment-list .comment .content {
  width: 80%;
  width: calc(100% - 140px);
  padding: 50px 0;
}

@media only screen and (max-width: 767px) {
  .comment-list .comment .content {
    width: 100%;
    padding: 15px 0;
  }
}

.comment-list .comment .content .desc p {
  font-size: 16px;
  color: #535353;
  font-family: "Playfair Display", sans-serif;
}

@media only screen and (max-width: 767px) {
  .comment-list .comment .content .desc p {
    font-size: 14px;
  }
}

.comment-list .comment .content .comment-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .comment-list .comment .content .comment-meta {
    flex-wrap: wrap;
  }
}

.comment-list .comment .content .comment-meta .comment-author {
  padding-top: 10px;
}

@media only screen and (max-width: 767px) {
  .comment-list .comment .content .comment-meta .comment-author {
    padding-top: 0px;
  }
}

.comment-list .comment .content .comment-meta .comment-author span.name {
  font-size: 12px;
  color: #1a1a1a;
  font-weight: 400;
}

.comment-list .comment .content .comment-meta .comment-author span.date {
  color: #989898;
  font-size: 12px;
}

.comment-list .comment .content .comment-meta .reply-btn a {
  color: #2f2f2f;
  text-transform: uppercase;
  padding: 0 10px;
  display: inline-block;
  font-size: 12px;
}

.comment-list .comment.children {
  margin-left: 80px;
}

@media only screen and (max-width: 767px) {
  .comment-list .comment.children {
    margin-left: 0px;
  }
}

/*-- Comment Form --*/
.comment-form-wrapper .title {
  font-size: 32px;
  line-height: 1;
  display: block;
  font-weight: 400;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .comment-form-wrapper .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.comment-form-wrapper p {
  font-size: 12px;
  line-height: 20px;
}

.requard {
  color: #fc4f4f;
}

.comment-form label {
  display: block;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 24px;
  color: #1a1a1a;
}

.comment-form input, .comment-form .contact__form--3 select, .contact__form--3 .comment-form select, .comment-form .contact__form--3 textarea, .contact__form--3 .comment-form textarea {
  width: 100%;
  max-width: 100%;
  -webkit-appearance: none;
  border-color: #e8e8e8;
  border-style: solid;
  border-width: 1px;
  height: 60px;
  margin: 0 0 15px;
  padding: 0 15px;
  background: #ffffff;
}

.comment-form textarea {
  height: 150px;
  width: 100%;
  max-width: 100%;
  vertical-align: top;
  -webkit-appearance: none;
  border-color: #e8e8e8;
  border-style: solid;
  border-width: 1px;
  margin: 0 0 15px;
  background: #ffffff;
  padding: 15px;
}

.comment-form .button-link span {
  color: #ffffff;
}

/*---------------------------------------
    17. Sidebar CSS
-----------------------------------------*/
/* Shop Sidebar CSS */
.sidebar-widget h4.pro-sidebar-title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  line-height: 1;
}

.sidebar-widget .sidebar-widget-list ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sidebar-widget .sidebar-widget-list ul li:last-child {
  margin-bottom: 0;
}

.sidebar-widget .sidebar-widget-list ul li a {
  text-transform: capitalize;
  font-size: 14px;
  color: #1a1a1a;
  display: block;
}

.sidebar-widget .sidebar-widget-list ul li a:hover {
  text-decoration: underline;
  color: #fc4f4f;
}

.sidebar-widget .sidebar-widget-list ul li span {
  color: #1a1a1a;
  font-size: 14px;
  text-transform: capitalize;
}

.sidebar-widget.sidebar-border {
  border-bottom: 1px solid #e8e8e8;
}

.sidebar-widget .sidebar-widget-color ul li {
  display: inline-block;
  margin-right: 3px;
  padding: 1px 6px 6px;
  border: 1px solid transparent;
  border-radius: 100%;
  line-height: 0px;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-widget-color ul li {
    padding: 1px 4px 6px;
  }
}

.sidebar-widget .sidebar-widget-color ul li:last-child {
  margin-right: 0;
}

.sidebar-widget .sidebar-widget-color ul li a {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.sidebar-widget .sidebar-widget-color ul li a::before {
  content: attr(title);
  position: absolute;
  bottom: 80%;
  text-indent: 0;
  background: #000;
  padding: 3px 10px 5px;
  line-height: 1;
  border-radius: 4px;
  margin-bottom: 10px;
  min-width: 50px;
  color: #fff;
  left: 50%;
  white-space: pre;
  text-align: center;
  text-transform: none;
  transform: translate(-50%, 0);
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
  font-size: 12px;
}

.sidebar-widget .sidebar-widget-color ul li a::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 80%;
  border-width: 4px 4px 0 4px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  margin-bottom: 6px;
  margin-left: -4px;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
}

.sidebar-widget .sidebar-widget-color ul li a:hover::before {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.sidebar-widget .sidebar-widget-color ul li a:hover::after {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.sidebar-widget .sidebar-widget-color ul li a.black {
  background-color: #000000;
}

.sidebar-widget .sidebar-widget-color ul li a.blue {
  background-color: #5097f7;
}

.sidebar-widget .sidebar-widget-color ul li a.brown {
  background-color: #a17f25;
}

.sidebar-widget .sidebar-widget-color ul li a.green {
  background-color: #9fc539;
}

.sidebar-widget .sidebar-widget-color ul li a.purple {
  background-color: #504e86;
}

.sidebar-widget .sidebar-widget-color ul li a.yellow {
  background-color: #eea437;
}

.sidebar-widget .sidebar-widget-size ul li {
  display: inline-block;
  margin: 2px 2px 2px 0;
}

.sidebar-widget .sidebar-widget-size ul li a {
  border: 1px solid #1a1a1a;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: #1a1a1a;
  padding: 16px 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-widget-size ul li a {
    line-height: 14px;
    padding: 12px 20px;
  }
}

.sidebar-widget .sidebar-widget-size ul li a:hover {
  background-color: #fc4f4f;
  border: 1px solid #fc4f4f;
  color: #ffffff;
}

.sidebar-widget .sidebar-widget-brand ul li {
  margin: 0 0 8px;
}

.sidebar-widget .sidebar-widget-brand ul li:last-child {
  margin: 0 0 0px;
}

.sidebar-widget .sidebar-widget-brand ul li a {
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.sidebar-widget .sidebar-widget-brand ul li a .brand-box {
  margin-right: 15px;
  border-radius: 0;
  border: 1px solid #979797;
  width: 21px;
  height: 21px;
  display: inline-block;
}

.sidebar-widget .sidebar-widget-brand ul li a:hover {
  color: #fc4f4f;
}

.sidebar-widget .sidebar-widget-tags ul li {
  display: inline-block;
}

.sidebar-widget .sidebar-widget-tags ul li a {
  color: #8a8a8a;
  font-size: 14px;
}

.sidebar-widget .sidebar-widget-tags ul li a:hover {
  text-decoration: underline;
  color: #343538;
}

.sidebar-widget .price-filter .price-slider-amount input, .sidebar-widget .price-filter .price-slider-amount .contact__form--3 select, .contact__form--3 .sidebar-widget .price-filter .price-slider-amount select, .sidebar-widget .price-filter .price-slider-amount .contact__form--3 textarea, .contact__form--3 .sidebar-widget .price-filter .price-slider-amount textarea {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 12px;
  line-height: 40px;
  color: #1a1a1a;
}

.sidebar-widget .price-filter #slider-range {
  background: #9d9d9d none repeat scroll 0 0;
  border: medium none;
  border-radius: 50px;
  height: 5px;
  margin-bottom: 20px;
  margin-left: auto;
}

.sidebar-widget .price-filter #slider-range span {
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-range {
  position: absolute;
  display: block;
  border: 0;
  background: #343538 none repeat scroll 0 0;
  border: none;
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-handle {
  background: #1a1a1a none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  height: 18px;
  margin-left: 0;
  width: 18px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.2);
  margin-top: -2px;
}

.sidebar-widget .price-filter button {
  color: #fff;
  display: inline-block;
  cursor: pointer;
  padding: 9px 10px;
  transition: all 300ms ease;
  border: none;
  background-color: #1a1a1a;
  font-size: 12px;
  text-transform: uppercase;
  margin: 13px 0 0;
  width: 90px;
}

.sidebar-widget .price-filter button:hover {
  background-color: #fc4f4f;
}

.sidebar-widget .sidebar-banner {
  position: relative;
}

.sidebar-widget .sidebar-banner .sidebar-banner-image a {
  display: block;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.sidebar-widget .sidebar-banner .sidebar-banner-image a::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1;
  opacity: 0;
  transition: .3s;
}

.sidebar-widget .sidebar-banner .sidebar-banner-image a img {
  width: 100%;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.sidebar-widget .sidebar-banner .sidebar-banner-content {
  top: 85%;
  transform: translateY(-85%);
  position: absolute;
  left: 30px;
  z-index: 9;
}

.sidebar-widget .sidebar-banner .sidebar-banner-content .title {
  font-size: 36px;
  line-height: 41px;
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-banner .sidebar-banner-content .title {
    font-size: 24px;
    line-height: 30px;
  }
}

.sidebar-widget .sidebar-banner .sidebar-banner-content .banner-shop-btn {
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  text-transform: uppercase;
  width: 120px;
  background-color: #1a1a1a;
  margin: 25px 0px 0px 0px;
  padding: 15px 30px;
  color: #ffffff;
  text-align: center;
  display: inline-block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-banner .sidebar-banner-content .banner-shop-btn {
    margin-top: 15px;
  }
}

.sidebar-widget .sidebar-banner .sidebar-banner-content-two {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 30px;
  z-index: 9;
}

.sidebar-widget .sidebar-banner .sidebar-banner-content-two .title {
  color: #ffffff;
  font-size: 24px;
  line-height: 33px;
  font-weight: 700;
  text-align: left;
}

.sidebar-widget .sidebar-banner .sidebar-banner-content-two span {
  color: #ffffff;
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
}

.sidebar-widget .sidebar-banner:hover .sidebar-banner-image a::before {
  opacity: .3;
}

.sidebar-widget .sidebar-banner:hover .sidebar-banner-image a img {
  transform: scale(1.1);
}

.sidebar-widget .sidebar-widget-search .search-form-2 {
  position: relative;
}

.sidebar-widget .sidebar-widget-search .search-form-2 input, .sidebar-widget .sidebar-widget-search .search-form-2 .contact__form--3 select, .contact__form--3 .sidebar-widget .sidebar-widget-search .search-form-2 select, .sidebar-widget .sidebar-widget-search .search-form-2 .contact__form--3 textarea, .contact__form--3 .sidebar-widget .sidebar-widget-search .search-form-2 textarea {
  background: transparent;
  height: 50px;
  border: 1px solid #1a1a1a;
  font-size: 14px;
  color: #1a1a1a;
  padding: 2px 40px 2px 20px;
  width: 100%;
}

.sidebar-widget .sidebar-widget-search .search-form-2 .button-search-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 16px;
  color: #1a1a1a;
  line-height: 1;
}

.sidebar-widget .sidebar-widget-search .search-form-2 .button-search-2:hover {
  color: #fc4f4f;
}

.sidebar-widget .sidebar-widget-comment ul li {
  color: #8a8a8a;
  font-size: 12px;
  padding-left: 30px;
  position: relative;
  margin: 0 0 15px;
}

.sidebar-widget .sidebar-widget-comment ul li:last-child {
  margin-bottom: 0;
}

.sidebar-widget .sidebar-widget-comment ul li::before {
  content: "\52";
  font-family: negan;
  position: absolute;
  left: 0;
  top: 1px;
  font-size: 14px;
}

.sidebar-widget .sidebar-widget-comment ul li a {
  color: #1A1A1A;
}

.sidebar-widget .sidebar-widget-tags2 ul li {
  display: inline-block;
  margin: 2px 2px 2px 0;
}

.sidebar-widget .sidebar-widget-tags2 ul li a {
  font-size: 11px;
  line-height: 18px;
  display: inline-block;
  margin: 0 0 8px;
  position: relative;
  border: 1px solid #ccc;
  padding: 5px 18px;
  border-radius: 5px;
  transition: all .3s;
}

.sidebar-widget .sidebar-widget-tags2 ul li a:hover {
  color: #ffffff;
  border: 1px solid #1a1a1a;
  background-color: #1a1a1a;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget.pb-45 {
    padding-bottom: 20px;
  }
}

.sidebar-product-wrap .single-sidebar-product {
  display: flex;
  padding-top: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #DCDCDC;
  padding-bottom: 20px;
}

.sidebar-product-wrap .single-sidebar-product .sidebar-product-img {
  flex: 0 0 70px;
}

.sidebar-product-wrap .single-sidebar-product .sidebar-product-img a img {
  width: 100%;
}

.sidebar-product-wrap .single-sidebar-product .sidebar-product-content {
  margin-left: 20px;
}

.sidebar-product-wrap .single-sidebar-product .sidebar-product-content h4 {
  font-weight: 700;
  font-size: 14px;
  margin: 0 0 5px;
}

.sidebar-product-wrap .single-sidebar-product .sidebar-product-content .title {
  font-weight: 700;
  font-size: 14px;
  margin: 0 0 5px;
  color: #1a1a1a;
  line-height: 1;
  display: block;
}

.sidebar-product-wrap .single-sidebar-product .sidebar-product-content span {
  font-size: 12px;
  color: #8a8a8a;
}

@media only screen and (max-width: 767px) {
  .sidebar-product-wrap .single-sidebar-product.mb-40 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-sidebar {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-sidebar {
    margin-top: 30px;
  }
}

/*-- Sidebar --*/
.sidebar {
  margin-bottom: 50px;
}

.sidebar:last-child {
  margin-bottom: 0;
}

/*-- Sidebar Title --*/
.sidebar-title {
  font-size: 22px;
  line-height: 1;
  font-weight: 700;
  border-bottom: 1px solid #eee;
  padding-bottom: 30px;
  margin-bottom: 25px;
}

/*-- Sidebar Search --*/
.sidebar-search form {
  display: flex;
  border: 1px solid #e5e6e7;
}

.sidebar-search form input, .sidebar-search form .contact__form--3 select, .contact__form--3 .sidebar-search form select, .sidebar-search form .contact__form--3 textarea, .contact__form--3 .sidebar-search form textarea {
  flex: 1 0 calc(100% - 40px);
  max-width: calc(100% - 40px);
  height: 40px;
  border: none;
  background-color: transparent;
  padding: 0 15px;
  color: #4d4d4d;
}

.sidebar-search form button {
  max-width: 40px;
  flex: 1 0 40px;
  height: 40px;
  line-height: 40px;
  border: none;
  background-color: transparent;
  padding: 0;
  display: block;
}

.sidebar-search form button i {
  font-size: 20px;
}

.sidebar-search form button:hover {
  color: #1A1A1A;
}

/*-- Sidebar List --*/
.sidebar-list li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e6e7;
}

.sidebar-list li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0px solid transparent;
}

.sidebar-list li a {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.sidebar-list li a img {
  width: 25px;
  margin-right: 15px;
}

.sidebar-list li a i {
  margin-right: 15px;
  font-size: 24px;
}

.sidebar-list li a:hover {
  color: #1A1A1A;
}

/*-- Sidebar Blog --*/
.sidebar-blog {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e6e7;
}

.sidebar-blog:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.sidebar-blog .image {
  max-width: 60px;
  flex: 1 0 60px;
}

.sidebar-blog .image img {
  width: 100%;
}

.sidebar-blog .content {
  flex: 1 0 calc(100% - 60px);
  padding-left: 15px;
}

.sidebar-blog .content h5 {
  font-size: 14px;
  line-height: 1.1;
  font-weight: 600;
}

.sidebar-blog .content span {
  font-size: 15px;
  display: block;
  line-height: 18px;
  color: #777;
}

/*-- Sidebar Tags --*/
.sidebar-tag {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.sidebar-tag li {
  margin-right: 2px;
  margin-bottom: 8px;
}

.sidebar-tag li a {
  display: block;
  border: 2px solid #ddd;
  padding: 5px 12px;
  font-size: 14px;
}

.sidebar-tag li a:hover {
  background-color: #1a1a1a;
  border-color: #1a1a1a;
  color: #1A1A1A;
}

/*-- Sidebar Product --*/
.sidebar-product {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e6e7;
}

.sidebar-product:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.sidebar-product .image {
  max-width: 80px;
  flex: 1 0 80px;
}

.sidebar-product .image img {
  width: 100%;
  border: 1px solid #f1f2f3;
}

.sidebar-product .content {
  flex: 1 0 calc(100% - 80px);
  padding-left: 15px;
}

.sidebar-product .content .title {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.4;
}

.sidebar-product .content .price {
  font-size: 14px;
  line-height: 1;
  margin: 0;
}

.sidebar-product .content .price span.new {
  font-weight: 700;
  color: #d80000;
  margin-left: 10px;
}

.sidebar-product .content .price span.old {
  color: #4d4d4d;
  text-decoration: line-through;
}

.sidebar-product .content .ratting {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.sidebar-product .content .ratting i {
  font-size: 12px;
  line-height: 18px;
  margin-right: 5px;
  color: #1A1A1A;
}

.sidebar-product .content .ratting i:last-child {
  margin-right: 0;
}

/*----------------------------------------*/
/*  18. Coming Soon CSS
/*----------------------------------------*/
.coming-soon-section {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media only screen and (max-width: 767px) {
  .coming-soon-section {
    height: auto;
    padding: 200px 0;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-image {
    height: 400px;
  }
}

.coming-soon-desc {
  padding: 0 90px;
  padding-top: 205px;
}

@media only screen and (min-width: 1500px) and (max-width: 1919px) {
  .coming-soon-desc {
    padding: 0 50px;
    padding-top: 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .coming-soon-desc {
    padding: 0 15px;
    padding-top: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-desc {
    padding: 0 15px;
    padding-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-desc {
    padding: 0 15px;
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-desc {
    padding: 0 15px;
    padding-top: 50px;
  }
}

.coming-soon-content-area {
  text-align: left;
}

.coming-soon-content-area h2 {
  font-size: 68px;
  line-height: 81px;
  max-width: 550px;
  margin-bottom: 15px;
  color: #ffffff;
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .coming-soon-content-area h2 {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 30px;
  }
}

.coming-soon-content-area.black-color h2 {
  color: #1a1a1a;
  font-weight: 700;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-content-area.black-color h2 {
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-content-area.black-color h2 {
    margin-bottom: 20px;
  }
}

.cs-countdown {
  margin-bottom: 50px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .cs-countdown {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .cs-countdown.black-color {
    margin-bottom: 0px;
  }
}

.cs-countdown.black-color .countdown-wrap > div {
  display: inline-block;
  text-align: center;
}

.cs-countdown.black-color .countdown-wrap > div + div {
  width: 110px;
  height: 75px;
  text-align: center;
  margin: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cs-countdown.black-color .countdown-wrap > div + div {
    width: 90px;
    height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .cs-countdown.black-color .countdown-wrap > div + div {
    width: 70px;
    height: 40px;
    margin: 30px 0px 30px 0px;
  }
}

.cs-countdown.black-color .number {
  color: #1a1a1a;
}

.cs-countdown.black-color .text {
  color: #1a1a1a;
}

.cs-countdown .countdown-wrap > div {
  display: inline-block;
  text-align: center;
}

.cs-countdown .countdown-wrap > div + div {
  width: 110px;
  height: 75px;
  text-align: center;
  margin: 10px;
}

@media only screen and (max-width: 767px) {
  .cs-countdown .countdown-wrap > div + div {
    width: 70px;
    height: 50px;
    margin: 30px 0px 30px 0px;
  }
}

.cs-countdown .number {
  font-size: 49px;
  line-height: 49px;
  color: #ffffff;
  display: block;
}

@media only screen and (max-width: 767px) {
  .cs-countdown .number {
    font-size: 22px;
    line-height: 1;
  }
}

.cs-countdown .text {
  display: block;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  color: #ffffff;
}

.cs-form.black-color .news-latter-form {
  border-bottom: 1px solid #1a1a1a;
}

.cs-form.black-color .news-latter-form form input, .cs-form.black-color .news-latter-form form .contact__form--3 select, .contact__form--3 .cs-form.black-color .news-latter-form form select, .cs-form.black-color .news-latter-form form .contact__form--3 textarea, .contact__form--3 .cs-form.black-color .news-latter-form form textarea {
  color: #1a1a1a;
}

.cs-form.black-color .news-latter-form form button {
  color: #1a1a1a;
}

.cs-form .news-latter-form {
  max-width: 550px;
  padding: 10px 0;
  border-bottom: 1px solid #ffffff;
}

.cs-form .news-latter-form form {
  display: flex;
}

.cs-form .news-latter-form form input, .cs-form .news-latter-form form .contact__form--3 select, .contact__form--3 .cs-form .news-latter-form form select, .cs-form .news-latter-form form .contact__form--3 textarea, .contact__form--3 .cs-form .news-latter-form form textarea {
  padding: 0px 0px 0px 0px;
  background-color: rgba(0, 0, 0, 0);
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  width: 100%;
}

.cs-form .news-latter-form form button {
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  color: #ffffff;
  padding: 0;
  border: 0;
  margin-left: 10px;
}

/*---------------------------------------
    19. Compare CSS
-----------------------------------------*/
/*-- Compare Table --*/
.compare-table .table tbody tr:first-child {
  border-top: 1px solid #eeeeee;
}

.compare-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 30px;
  vertical-align: middle;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

.compare-table .table tbody tr td:last-child {
  border-right: 1px solid #eeeeee;
}

.compare-table .table tbody tr td.first-column {
  min-width: 180px;
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
  line-height: 1;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
  padding-top: 70px;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 40px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .image img {
  max-width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  font-size: 13px;
  line-height: 15px;
  color: #444444;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .category:hover {
  color: #1A1A1A;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 14px;
  font-weight: 600;
  color: #444444;
  line-height: 20px;
  margin-bottom: 10px;
}

.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #fc4f4f;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td.pro-price, .compare-table .table tbody tr td.pro-color, .compare-table .table tbody tr td.pro-stock {
  font-size: 14px;
  font-weight: 600;
  color: #444444;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart {
  position: relative;
  background-color: #1A1A1A;
  color: #fff;
  border-radius: 50px;
  display: inline-block;
  width: 170px;
  padding: 10px 25px 10px 25px;
  transition: all 0.3s ease-in-out;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart:hover {
  background-color: #fc4f4f;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart span {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 26px;
  overflow: hidden;
  display: block;
  text-align: center;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart.added {
  padding-left: 25px;
  padding-right: 56px;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart.added i {
  left: calc(100% - 41px);
}

.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.compare-table .table tbody tr td.pro-remove button:hover {
  color: #1A1A1A;
}

.compare-table .table tbody tr td.pro-remove button i {
  font-size: 20px;
}

.compare-table .table tbody tr td.pro-ratting i {
  color: #444444;
  font-size: 14px;
}

/*----------------------------------------*/
/*  20. Countdown CSS
/*----------------------------------------*/
.countdown-content .title {
  font-size: 56px;
  line-height: 67px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .countdown-content .title br {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .countdown-content .title br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .countdown-content .title {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .countdown-content .title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}

.countdown-content p {
  font-size: 18px;
  line-height: 36px;
}

@media only screen and (max-width: 767px) {
  .countdown-content p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }
}

.countdown-area {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.countdown-area .single-countdown {
  flex-flow: unset;
  align-items: baseline;
  margin: 50px 40px 60px 0px;
  text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .countdown-area .single-countdown {
    margin: 30px 25px 40px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .countdown-area .single-countdown {
    margin: 30px 25px 40px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .countdown-area .single-countdown {
    margin: 30px 15px 30px 0px;
  }
}

@media only screen and (max-width: 575px) {
  .countdown-area .single-countdown {
    margin: 20px 6px 20px 0px;
  }
}

.countdown-area .single-countdown .single-countdown-time {
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 400;
  font-style: italic;
  margin: 0px 10px 0px 0px;
}

@media only screen and (max-width: 575px) {
  .countdown-area .single-countdown .single-countdown-time {
    font-size: 18px;
    margin: 0px;
  }
}

.countdown-area .single-countdown .single-countdown-text {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
}

@media only screen and (max-width: 575px) {
  .countdown-area .single-countdown .single-countdown-text {
    font-size: 12px;
  }
}

.countdown-banner {
  position: relative;
}

.banner-offer {
  position: absolute;
  top: 40%;
  left: 0;
  max-width: 100%;
}

.banner-offer .banner-sale-headline {
  color: #d94141;
  font-size: 56px;
  line-height: 96px;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: left;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-offer .banner-sale-headline {
    font-size: 48px;
    line-height: 82px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-offer .banner-sale-headline {
    font-size: 32px;
    line-height: 40px;
  }
}

.banner-offer .normal-headline {
  color: #1a1a1a;
  font-size: 24px;
  font-style: italic;
  letter-spacing: 0.8px;
  text-align: left;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-offer .normal-headline {
    font-size: 16px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-offer .normal-headline {
    font-size: 18px;
  }
}

.offer-product-name {
  text-align: right;
  top: 44%;
  right: 0px;
  max-width: 100%;
  width: 170px;
}

.offer-product-name p {
  font-size: 16px;
  line-height: 32px;
  color: #1a1a1a;
  text-align: right;
}

@media only screen and (max-width: 575px) {
  .offer-product-name p {
    font-size: 12px;
  }
}

.banner-image {
  margin: 0px 0px 0px 160px;
  max-width: 100%;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .banner-image {
    margin: 0px 0px 0px 60px;
  }
}

.banner-image img {
  width: 311px;
}

/*----------------------------------------*/
/*  21. Faq CSS
/*----------------------------------------*/
/* Faq CSS */
.faq-content .title {
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-content .title {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-content .title {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 15px;
  }
}

.faq-content p {
  font-size: 14px;
  line-height: 28px;
}

.help-content {
  text-align: center;
}

.help-content h2 {
  font-size: 36px;
  line-height: 50px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .help-content h2 {
    font-size: 28px;
    line-height: 36px;
  }
}

.help-content a {
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
}

.help-content a:hover {
  background: #fc4f4f;
  border-color: #fc4f4f;
  color: #ffffff;
}

/*----------------------------------------*/
/*  22. Instagram CSS
/*----------------------------------------*/
.instafeed ul {
  display: flex;
  flex-wrap: wrap;
}

.instafeed li {
  flex: 0 0 25%;
  max-width: 25%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .instafeed li {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.instafeed li > a {
  position: relative;
  display: block;
}

.instafeed li > a::before {
  background-color: rgba(52, 53, 56, 0.4);
  z-index: 9;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  right: 0;
  bottom: 0;
  -webkit-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
  opacity: 0;
  visibility: hidden;
}

.instafeed li > a img {
  width: 100%;
}

.instafeed li .item-info .inner {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  color: #fff;
  text-align: center;
  transform: translateY(-50%);
  margin: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.instafeed li .item-info .inner a {
  position: relative;
  color: #fff;
  font-size: 16px;
  display: inline-block;
  margin: 0 10px;
}

.instafeed li .item-info .inner a i {
  padding-right: 4px;
}

.instafeed li:hover a::before {
  opacity: 1;
  visibility: visible;
}

.instafeed li:hover .item-info .inner {
  opacity: 1;
  visibility: visible;
}

/*----------------------------------------*/
/*  23. My Account CSS
/*----------------------------------------*/
.my-account-form-area {
  max-width: 500px;
  margin: 0px auto;
}

.my-account-form-area form h2 {
  font-size: 34px;
  line-height: 47px;
  font-weight: 700;
  text-align: center;
}

.my-account-form-area form .single-input {
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .my-account-form-area form .single-input {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .my-account-form-area form .single-input {
    margin-bottom: 20px;
  }
}

.my-account-form-area form .single-input label {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #1a1a1a;
  text-align: left;
  margin-bottom: 8px;
}

.my-account-form-area form .single-input input, .my-account-form-area form .single-input .contact__form--3 select, .contact__form--3 .my-account-form-area form .single-input select, .my-account-form-area form .single-input .contact__form--3 textarea, .contact__form--3 .my-account-form-area form .single-input textarea {
  width: 100%;
  height: 70px;
  padding: 10px 20px;
  font-size: 14px;
  text-align: left;
  border: 1px solid #CDCDCD;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .my-account-form-area form .single-input input, .my-account-form-area form .single-input .contact__form--3 select, .contact__form--3 .my-account-form-area form .single-input select, .my-account-form-area form .single-input .contact__form--3 textarea, .contact__form--3 .my-account-form-area form .single-input textarea {
    height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .my-account-form-area form .single-input input, .my-account-form-area form .single-input .contact__form--3 select, .contact__form--3 .my-account-form-area form .single-input select, .my-account-form-area form .single-input .contact__form--3 textarea, .contact__form--3 .my-account-form-area form .single-input textarea {
    height: 50px;
  }
}

.my-account-form-area form .single-input input:focus, .my-account-form-area form .single-input .contact__form--3 select:focus, .contact__form--3 .my-account-form-area form .single-input select:focus, .my-account-form-area form .single-input .contact__form--3 textarea:focus, .contact__form--3 .my-account-form-area form .single-input textarea:focus {
  border-color: #1a1a1a;
}

.my-account-form-area form label {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #1a1a1a;
  text-align: left;
  margin-bottom: 8px;
}

.my-account-form-area form .lost-pass {
  margin-top: 20px;
}

.my-account-form-area form .lost-pass a {
  font-size: 14px;
  line-height: 24px;
  color: #fc4f4f;
}

.lost-password > a {
  color: #a43d21;
}

.lost-password > a:hover {
  color: #1A1A1A;
}

/*---------------------------------------
    24. Newslatter CSS
-----------------------------------------*/
.newsletter-form {
  position: relative;
  border-bottom: 1px solid #1a1a1a;
  width: 620px;
  max-width: 100%;
  margin: 0 auto;
}

.newsletter-form.newsletter-form-two {
  width: 780px;
  max-width: 100%;
}

.newsletter-form.color-white {
  border-bottom: 1px solid #ffffff;
}

.newsletter-form.color-white input, .newsletter-form.color-white .contact__form--3 select, .contact__form--3 .newsletter-form.color-white select, .newsletter-form.color-white .contact__form--3 textarea, .contact__form--3 .newsletter-form.color-white textarea {
  color: #ffffff;
}

.newsletter-form.color-white button {
  color: #ffffff;
}

.newsletter-form input, .newsletter-form .contact__form--3 select, .contact__form--3 .newsletter-form select, .newsletter-form .contact__form--3 textarea, .contact__form--3 .newsletter-form textarea {
  width: 100%;
  border: none;
  line-height: 30px;
  height: 55px;
  padding: 10px 0px 10px 0px;
  padding-right: 115px;
  background-color: rgba(0, 0, 0, 0);
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.7px;
}

@media only screen and (max-width: 767px) {
  .newsletter-form input, .newsletter-form .contact__form--3 select, .contact__form--3 .newsletter-form select, .newsletter-form .contact__form--3 textarea, .contact__form--3 .newsletter-form textarea {
    height: 40px;
    padding-right: 150px;
  }
}

@media only screen and (max-width: 479px) {
  .newsletter-form input, .newsletter-form .contact__form--3 select, .contact__form--3 .newsletter-form select, .newsletter-form .contact__form--3 textarea, .contact__form--3 .newsletter-form textarea {
    padding-right: 128px;
    font-size: 11px;
  }
}

.newsletter-form button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background: none;
  border: none;
  width: 200px;
  font-size: 16px;
  font-weight: 700;
  color: #1a1a1a;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .newsletter-form button {
    width: 110px;
    font-size: 14px;
  }
}

.newsletter-form button:hover {
  color: #fc4f4f;
}

/*----------------------------------------*/
/*  25. Order CSS
/*----------------------------------------*/
.order-tracking-form-area {
  max-width: 620px;
  margin: 0 auto;
  text-align: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .order-tracking-form-area {
    padding: 0px 80px 0px 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .order-tracking-form-area {
    padding: 0px 40px 0px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-tracking-form-area {
    padding: 55px 0px 60px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .order-tracking-form-area {
    padding: 45px 15px 50px 15px;
  }
}

.order-tracking-form-area form p {
  font-size: 14px;
  line-height: 24px;
  color: #1a1a1a;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .order-tracking-form-area form p {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .order-tracking-form-area form p {
    margin-bottom: 20px;
  }
}

.order-tracking-form-area form .single-input {
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .order-tracking-form-area form .single-input {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .order-tracking-form-area form .single-input {
    margin-bottom: 20px;
  }
}

.order-tracking-form-area form .single-input label {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #1a1a1a;
  text-align: left;
  margin-bottom: 8px;
}

.order-tracking-form-area form .single-input input, .order-tracking-form-area form .single-input .contact__form--3 select, .contact__form--3 .order-tracking-form-area form .single-input select, .order-tracking-form-area form .single-input .contact__form--3 textarea, .contact__form--3 .order-tracking-form-area form .single-input textarea {
  width: 100%;
  height: 70px;
  padding: 10px 20px;
  font-size: 14px;
  text-align: left;
  border: 1px solid #CDCDCD;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .order-tracking-form-area form .single-input input, .order-tracking-form-area form .single-input .contact__form--3 select, .contact__form--3 .order-tracking-form-area form .single-input select, .order-tracking-form-area form .single-input .contact__form--3 textarea, .contact__form--3 .order-tracking-form-area form .single-input textarea {
    height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .order-tracking-form-area form .single-input input, .order-tracking-form-area form .single-input .contact__form--3 select, .contact__form--3 .order-tracking-form-area form .single-input select, .order-tracking-form-area form .single-input .contact__form--3 textarea, .contact__form--3 .order-tracking-form-area form .single-input textarea {
    height: 50px;
  }
}

.order-tracking-form-area form .single-input input:focus, .order-tracking-form-area form .single-input .contact__form--3 select:focus, .contact__form--3 .order-tracking-form-area form .single-input select:focus, .order-tracking-form-area form .single-input .contact__form--3 textarea:focus, .contact__form--3 .order-tracking-form-area form .single-input textarea:focus {
  border-color: #1a1a1a;
}

/*----------------------------------------*/
/*  26. Portfolio CSS
/*----------------------------------------*/
/* Protfolio Dot CSS */
.project-slider .slick-dots {
  margin-top: 10px;
}

/* Protfolio Nav CSS */
.portfolio-nav-area ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 10px 0;
}

@media only screen and (max-width: 767px) {
  .portfolio-nav-area ul {
    justify-content: flex-start;
  }
}

.portfolio-nav-area ul li {
  font-size: 20px;
  line-height: 1;
  margin-right: 30px;
}

.portfolio-nav-area ul li:last-child {
  margin-right: 0;
}

.portfolio-nav-area ul li a {
  color: #1a1a1a;
}

.portfolio-nav-area ul li a:hover {
  color: #fc4f4f;
}

/* Single Portfolio CSS */
.single-portfolio {
  position: relative;
  overflow: hidden;
}

.single-portfolio.style-two .protfolio-content {
  padding: 12px 0px 50px;
  text-align: left;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-portfolio.style-two .protfolio-content {
    padding: 12px 0px 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-portfolio.style-two .protfolio-content {
    padding: 12px 0px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-portfolio.style-two .protfolio-content {
    padding: 12px 0px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-portfolio.style-two .protfolio-content {
    padding: 12px 0px 30px;
  }
}

.single-portfolio.style-two .protfolio-content h3 {
  font-size: 32px;
  line-height: 45px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-portfolio.style-two .protfolio-content h3 {
    font-size: 24px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .single-portfolio.style-two .protfolio-content h3 {
    font-size: 24px;
    line-height: 32px;
  }
}

.single-portfolio.style-two .protfolio-content h3 a:hover {
  color: #fc4f4f;
}

.single-portfolio.style-two .protfolio-content p {
  font-size: 14px;
  color: #4D4D4D;
  line-height: 28px;
}

.single-portfolio.style-three {
  position: relative;
  overflow: hidden;
}

.single-portfolio.style-three.style-transform .portfolio-image .icon {
  top: 65%;
  transform: translateY(-65%);
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-portfolio.style-three.style-transform .portfolio-image .icon {
    top: 65%;
    transform: translateY(-65%);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-portfolio.style-three.style-transform .portfolio-image .icon {
    top: 70%;
    transform: translateY(-70%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-portfolio.style-three.style-transform .portfolio-image .icon {
    top: 70%;
    transform: translateY(-70%);
  }
}

@media only screen and (max-width: 767px) {
  .single-portfolio.style-three.style-transform .portfolio-image .icon {
    top: 70%;
    transform: translateY(-70%);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-portfolio.style-three.style-transform .portfolio-overlay-desc {
    top: 50%;
    transform: translateY(-50%);
  }
}

.single-portfolio.style-three::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all .4s;
}

.single-portfolio.style-three .portfolio-image {
  position: relative;
}

.single-portfolio.style-three .portfolio-image img {
  width: 100%;
}

.single-portfolio.style-three .portfolio-image .icon {
  top: 65%;
  transform: translateY(-65%);
}

@media only screen and (min-width: 1500px) and (max-width: 1919px) {
  .single-portfolio.style-three .portfolio-image .icon {
    top: 67%;
    transform: translateY(-67%);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-portfolio.style-three .portfolio-image .icon {
    top: 70%;
    transform: translateY(-70%);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-portfolio.style-three .portfolio-image .icon {
    top: 70%;
    transform: translateY(-70%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-portfolio.style-three .portfolio-image .icon {
    top: 70%;
    transform: translateY(-70%);
  }
}

@media only screen and (max-width: 767px) {
  .single-portfolio.style-three .portfolio-image .icon {
    top: 70%;
    transform: translateY(-70%);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-portfolio.style-three .portfolio-image .icon a {
    margin: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-portfolio.style-three .portfolio-image .icon a {
    margin: 6px;
  }
}

@media only screen and (max-width: 767px) {
  .single-portfolio.style-three .portfolio-image .icon a {
    margin: 5px;
  }
}

.single-portfolio.style-three .portfolio-overlay-desc {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  width: 100%;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all .4s;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-portfolio.style-three .portfolio-overlay-desc {
    top: 48%;
    transform: translateY(-48%);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-portfolio.style-three .portfolio-overlay-desc {
    top: 48%;
    transform: translateY(-48%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-portfolio.style-three .portfolio-overlay-desc {
    top: 50%;
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 767px) {
  .single-portfolio.style-three .portfolio-overlay-desc {
    top: 45%;
    transform: translateY(-45%);
  }
}

.single-portfolio.style-three .portfolio-overlay-desc p {
  font-size: 14px;
  line-height: 1;
  font-style: italic;
  color: #B4B4B4;
  margin-bottom: 0;
}

.single-portfolio.style-three .portfolio-overlay-desc .title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  color: #ffffff;
  margin: 15px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-portfolio.style-three .portfolio-overlay-desc .title {
    font-size: 18px;
    line-height: 26px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-portfolio.style-three .portfolio-overlay-desc .title {
    font-size: 18px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .single-portfolio.style-three .portfolio-overlay-desc .title {
    font-size: 18px;
    line-height: 26px;
  }
}

.single-portfolio.style-three .portfolio-overlay-desc .title a:hover {
  color: #fc4f4f;
}

.single-portfolio.style-three:hover::after {
  opacity: 1;
}

.single-portfolio.style-three:hover .portfolio-overlay-desc {
  opacity: 1;
  visibility: visible;
}

.single-portfolio.style-four .protfolio-content {
  padding: 12px 0px 10px;
  text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-portfolio.style-four .protfolio-content {
    padding: 12px 0px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-portfolio.style-four .protfolio-content {
    padding: 12px 0px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .single-portfolio.style-four .protfolio-content {
    padding: 12px 0px 10px;
  }
}

.single-portfolio.style-four .protfolio-content h2 {
  font-size: 24px;
  line-height: 33px;
}

.single-portfolio .portfolio-image {
  position: relative;
}

.single-portfolio .portfolio-image img {
  width: 100%;
}

.single-portfolio .portfolio-image .icon {
  position: absolute;
  top: 50%;
  z-index: 3;
  left: 0;
  right: 0;
  margin: auto;
  visibility: hidden;
  opacity: 0;
  margin-top: 30px;
  transition: all .4s;
  transform: translateY(-50%);
  text-align: center;
}

.single-portfolio .portfolio-image .icon a {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: none;
  color: #fff;
  border: 1px solid #fff;
  margin: 10px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  transition: all .3s;
  display: inline-block;
}

.single-portfolio .portfolio-image .icon a:hover {
  background: #ffffff;
  border-color: #ffffff;
  color: #1a1a1a;
}

.single-portfolio .portfolio-overlay-info {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  box-sizing: border-box;
  position: absolute;
  bottom: -50px;
  left: 0;
  background: #212121;
  color: #ffffff;
  z-index: 2;
  text-align: left;
  padding: 30px;
  transition: all .6s;
  visibility: hidden;
  opacity: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-portfolio .portfolio-overlay-info {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-portfolio .portfolio-overlay-info {
    padding: 15px;
  }
}

.single-portfolio .portfolio-overlay-info .title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #ffffff;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-portfolio .portfolio-overlay-info .title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .single-portfolio .portfolio-overlay-info .title {
    font-size: 16px;
  }
}

.single-portfolio .portfolio-overlay-info .title a:hover {
  color: #ffffff;
}

.single-portfolio .portfolio-overlay-info .category {
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.single-portfolio:hover .portfolio-image .icon {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.single-portfolio:hover .portfolio-overlay-info {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

/* Protfolio Details CSS */
.portfolio-details-content .title {
  font-size: 56px;
  line-height: 78px;
  font-weight: 700;
  margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-details-content .title {
    font-size: 36px;
    line-height: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details-content .title {
    font-size: 36px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-details-content .title {
    font-size: 24px;
    line-height: 34px;
  }
}

.portfolio-details-content .category {
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
  font-style: italic;
  color: #1a1a1a;
  margin-bottom: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-details-content .category {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details-content .category {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-details-content .category {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 15px;
  }
}

.portfolio-details-content p {
  font-size: 16px;
  line-height: 36px;
  color: #1a1a1a;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-details-content p {
    font-size: 14px;
    line-height: 31px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details-content p {
    font-size: 14px;
    line-height: 31px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-details-content p {
    font-size: 14px;
    line-height: 31px;
  }
}

.portfolio-details-content .portfolio-list {
  padding: 40px 0px 40px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details-content .portfolio-list {
    padding: 20px 0px 20px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-details-content .portfolio-list {
    padding: 20px 0px 20px 0px;
  }
}

.portfolio-details-content .portfolio-list li {
  font-size: 16px;
  line-height: 9px;
  padding-bottom: 45px;
  color: #1a1a1a;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-details-content .portfolio-list li {
    font-size: 14px;
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details-content .portfolio-list li {
    font-size: 14px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-details-content .portfolio-list li {
    font-size: 14px;
    padding-bottom: 20px;
  }
}

.portfolio-details-content .portfolio-list li i {
  color: #1a1a1a;
  font-size: 8px;
}

.portfolio-details-content .portfolio-list li span {
  padding-left: 13px;
}

.portfolio-details-content .portfolio-list li:last-child {
  padding-bottom: 0;
}

.portfolio-details-content .portfolio-feature {
  margin: 40px 0px 50px 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-details-content .portfolio-feature {
    margin: 25px 0px 40px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details-content .portfolio-feature {
    margin: 25px 0px 40px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-details-content .portfolio-feature {
    margin: 15px 0px 20px 0px;
  }
}

.portfolio-details-content .portfolio-feature li {
  font-size: 16px;
  line-height: 9px;
  padding-bottom: 20px;
  color: #1a1a1a;
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details-content .portfolio-feature li {
    font-size: 14px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-details-content .portfolio-feature li {
    font-size: 14px;
    padding-bottom: 20px;
  }
}

.portfolio-details-content .portfolio-feature li .icon i {
  font-size: 20px;
  color: #1a1a1a;
}

.portfolio-details-content .portfolio-feature li .content {
  font-size: 14px;
  line-height: 24px;
  color: #1a1a1a;
  padding-left: 26px;
}

.portfolio-details-content .portfolio-feature li:last-child {
  padding-bottom: 0;
}

.portfolio-details-content .portfolio-social-icon a {
  font-size: 18px;
  border: 1px solid #1a1a1a;
  border-radius: 50%;
  background-color: #1a1a1a;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 12px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .portfolio-details-content .portfolio-social-icon a {
    margin-right: 10px;
  }
}

.portfolio-details-content .portfolio-social-icon a:last-child {
  margin-right: 0;
}

.portfolio-details-content .portfolio-social-icon a i {
  color: #ffffff;
}

.portfolio-details-content .portfolio-social-icon a:hover {
  border-color: rgba(0, 0, 0, 0);
}

.portfolio-details-content .portfolio-social-icon a.facebook:hover {
  background-color: #4867AA;
}

.portfolio-details-content .portfolio-social-icon a.twitter:hover {
  background-color: #1DA1F2;
}

.portfolio-details-content .portfolio-social-icon a.google:hover {
  background-color: #DD5144;
}

.portfolio-details-content .portfolio-social-icon a.dribbble:hover {
  background-color: #EA4C8A;
}

.portfolio-details-content .shop-btn {
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  padding: 20px 40px;
  border: 0;
  margin-top: 60px;
  width: 220px;
  text-align: center;
  text-transform: capitalize;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-details-content .shop-btn {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details-content .shop-btn {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-details-content .shop-btn {
    margin-top: 30px;
  }
}

.portfolio-details-content .shop-btn:hover {
  background-color: #fc4f4f;
}

@media only screen and (max-width: 767px) {
  .portfolio-details-gallery {
    margin-top: 30px;
  }
}

.single-portfolio-image a {
  display: block;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.single-portfolio-image a::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1;
  opacity: 0;
  transition: .3s;
}

.single-portfolio-image a img {
  width: 100%;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.single-portfolio-image:hover a::before {
  opacity: 0.4;
}

.single-portfolio-image:hover a img {
  transform: scale(1.1);
}

.portfolio-pagination-section {
  padding: 22px 0;
  border-top: 1px solid #1a1a1a;
  border-bottom: 1px solid #1a1a1a;
}

@media only screen and (max-width: 767px) {
  .portfolio-pagination-section {
    padding: 15px 0;
  }
}

.portfolio-pagination a {
  font-size: 24px;
  line-height: 41px;
  font-weight: 700;
  color: #1a1a1a;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-pagination a {
    font-size: 20px;
    line-height: 34px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-pagination a {
    font-size: 18px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .portfolio-pagination a {
    font-size: 13px;
    line-height: 20px;
  }
}

.portfolio-pagination a:hover {
  color: #fc4f4f;
}

/*----------------------------------------*/
/*  27. Team CSS
/*----------------------------------------*/
/* Single Team CSS */
.single-team {
  position: relative;
  z-index: 2;
}

.single-team .thumbnail {
  position: relative;
}

.single-team .thumbnail::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  transition: all .4s .1s;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0;
}

.single-team .thumbnail .hover-action {
  text-align: center;
  z-index: 2;
  bottom: 20px;
  opacity: 0;
  transition: all .4s .1s;
  transform: translateY(20px);
  -webkit-transform: translateY(20px);
  position: absolute;
  width: 100%;
}

.single-team .thumbnail .hover-action .social-icons {
  margin: -5px;
}

.single-team .thumbnail .hover-action .social-icons a {
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  width: 38px;
  height: 38px;
  line-height: 38px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 16px;
  display: inline-block;
  margin: 5px;
}

.single-team .thumbnail .hover-action .social-icons a:hover {
  color: #ffffff;
  background-color: #fc4f4f;
  border-color: #fc4f4f;
}

.single-team .info {
  text-align: left;
  padding-top: 25px;
  padding-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-team .info {
    padding-top: 15px;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .single-team .info {
    padding-top: 15px;
    padding-bottom: 10px;
  }
}

.single-team .info .title {
  font-size: 24px;
  line-height: 24px;
  margin: 0px 0px 8px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-team .info .title {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .single-team .info .title {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 0;
  }
}

.single-team .info .desc {
  color: #919191;
  font-size: 16px;
  line-height: 27px;
  font-style: italic;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-team .info .desc {
    font-size: 14px;
    line-height: 20px;
  }
}

.single-team:hover .thumbnail::before {
  opacity: 0.5;
}

.single-team:hover .thumbnail .hover-action {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
}

/*----------------------------------------*/
/*  28. Warranty CSS
/*----------------------------------------*/
@media only screen and (max-width: 767px) {
  .warranty-content {
    text-align: center;
  }
}

.warranty-content .title {
  font-size: 64px;
  line-height: 90px;
  color: #ffffff;
  letter-spacing: 2px;
  margin-bottom: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .warranty-content .title {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .warranty-content .title {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 15px;
  }
}

.warranty-content p {
  font-size: 24px;
  line-height: 43px;
  color: #ffffff;
  max-width: 600px;
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .warranty-content p {
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .warranty-content p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}

.warranty-content .warranty-btn {
  background-color: rgba(26, 26, 26, 0);
  color: #ffffff;
  padding: 20px 40px;
  border: 2px solid #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 12px;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .warranty-content .warranty-btn {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .warranty-content .warranty-btn {
    font-size: 12px;
    padding: 15px 30px 15px 30px;
  }
}

.warranty-content .warranty-btn:hover {
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

@media only screen and (max-width: 767px) {
  .warranty-image {
    margin-top: 30px;
    text-align: center;
  }
}

.warranty-image img {
  width: 195px;
}

@media only screen and (max-width: 767px) {
  .warranty-image img {
    width: 100px;
  }
}

/*----------------------------------------*/
/*  29. Brand CSS
/*----------------------------------------*/
/*-- Brand Slider --*/
.brand-slider .slick-list .slick-track {
  display: flex;
  align-items: center;
}

.brand-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -15px;
  z-index: 9;
  border: none;
  background-color: #ffffff;
  color: #1A1A1A;
  padding: 10px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  opacity: 0;
}

.brand-slider .slick-arrow i {
  font-size: 24px;
  line-height: 24px;
  display: block;
}

.brand-slider .slick-arrow.slick-prev {
  left: -7px;
  margin-left: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .brand-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .brand-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-slider .slick-arrow.slick-prev {
    left: 8px;
  }
}

.brand-slider .slick-arrow.slick-next {
  right: -7px;
  margin-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .brand-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .brand-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-slider .slick-arrow.slick-next {
    right: 8px;
  }
}

.brand-slider .slick-arrow:hover {
  background-color: #1A1A1A;
  color: #ffffff;
}

.brand-slider:hover .slick-arrow {
  opacity: 1;
}

.brand-slider:hover .slick-arrow.slick-prev {
  margin-left: 0;
}

.brand-slider:hover .slick-arrow.slick-next {
  margin-right: 0;
}

.brand-slider .slick-dots {
  width: 100%;
  padding: 0;
  margin: 10px 0 0;
  display: flex;
  justify-content: center;
}

.brand-slider .slick-dots li {
  margin: 0 5px;
}

.brand-slider .slick-dots li button {
  display: block;
  padding: 0;
  width: 12px;
  height: 12px;
  border: none;
  background-color: #d8d8d8;
  text-indent: -9999px;
  border-radius: 50px;
}

.brand-slider .slick-dots li.slick-active button {
  background-color: #1A1A1A;
}

/*-- Brand --*/
.brand {
  text-align: center;
  padding: 50px;
}

.brand.brand-two a img {
  opacity: 1;
}

.brand.brand-two:hover a img {
  transform: scale(1);
  opacity: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .brand {
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .brand {
    padding: 15px;
  }
}

.brand a {
  display: block;
  position: relative;
  overflow: hidden;
}

.brand a img {
  max-width: 100%;
  margin: auto;
  transition: all 0.3s ease-in-out;
}

.brand:hover a img {
  transform: scale(1.2);
}

/*---------------------------------------
    30. Contact CSS
-----------------------------------------*/
/*-- Map --*/
.ht-gmap2 {
  height: 1070px;
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ht-gmap2 {
    height: 910px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ht-gmap2 {
    height: 910px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ht-gmap2 {
    height: 895px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ht-gmap2 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .ht-gmap2 {
    height: 400px;
  }
}

/*-- Contact Form --*/
.contact__form--3 {
  padding: 160px 80px;
  background-color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .contact__form--3 {
    padding: 80px 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__form--3 {
    padding: 70px 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__form--3 {
    padding: 60px 15px;
    padding-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .contact__form--3 {
    padding: 50px 15px;
    padding-top: 0px;
  }
}

.contact__form--3 input, .contact__form--3 select, .contact__form--3 textarea {
  font-size: 18px;
  padding: 0 0 15px;
  margin: 50px 0 10px;
  border-style: solid;
  border-width: 0 0 2px;
  border-color: #2f2f2f;
  height: auto;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .contact__form--3 input, .contact__form--3 select, .contact__form--3 textarea {
    margin-top: 40px;
  }
}

.contact__form--3 input[type=submit], .contact__form--3 select[type="submit"], .contact__form--3 textarea[type="submit"] {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
  padding: 30px;
  border-radius: 0;
  display: block;
  width: 100%;
  margin: 25px 0 0;
  background-color: #1a1a1a;
  text-decoration: none;
  color: #ffffff;
  border-color: transparent;
}

.contact__form--3 input[type=submit]:hover, .contact__form--3 select[type="submit"]:hover, .contact__form--3 textarea[type="submit"]:hover {
  background-color: #fc4f4f;
  color: #ffffff;
}

.contact__form--3 textarea {
  height: 150px;
  min-height: 150px;
}

.form-messege.success {
  color: #1dbc51;
  font-weight: 700;
}

.form-messege.error {
  color: #ff1313;
}

/*----------------------------------------*/
/*  31. 404 CSS
/*----------------------------------------*/
/* 404 Error CSS */
@media only screen and (max-width: 767px) {
  .error-404-page-area {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 767px) {
  .error-content {
    text-align: center;
  }
}

.error-content h1 {
  font-size: 86px;
  line-height: 1;
  font-weight: 700;
  margin: 0 0 5px;
}

.error-content h2 {
  font-size: 46px;
  line-height: 64px;
  font-weight: 700;
  margin: 15px 0;
}

@media only screen and (max-width: 767px) {
  .error-content h2 {
    font-size: 26px;
    line-height: 36px;
  }
}

.error-content p {
  font-size: 16px;
  line-height: 36px;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .error-content p {
    font-size: 14px;
    line-height: 28px;
  }
}

.error-content a {
  text-transform: uppercase;
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .error-content a {
    margin-top: 20px;
    padding: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .error-content a::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .error-image {
    margin-top: 30px;
  }
}

/*---------------------------------------
    32. Modal CSS
-----------------------------------------*/
.modal {
  display: block !important;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: none;
}

.modal.show {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease 0s;
}

.modal-content {
  border: none;
  border-radius: 0;
}

.modal-dialog {
  margin: 13px auto;
  max-width: 1300px;
  width: 1300px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .modal-dialog {
    width: 1100px;
    max-width: 1100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal-dialog {
    width: 960px;
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog {
    width: 720px;
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 20px 0;
  }
}

.modal-dialog .modal-body {
  padding: 30px 30px;
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-body {
    padding: 30px 15px;
  }
}

.modal-header {
  padding: 15px 15px 20px;
  border-bottom: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal-header {
    padding: 15px 15px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-header {
    padding: 15px 15px 20px;
  }
}

.modal-header .close {
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  font-size: 38px;
  text-shadow: 0 0 15px transparent;
  opacity: .9;
}

.modal-header .close:hover {
  color: #fc4f4f;
  text-shadow: 0 0 15px #fff;
  opacity: 1;
}

.modal-backdrop {
  background-color: #333;
}

.modal-backdrop.show {
  opacity: .9;
}

.quickview-img-wrap {
  width: 100%;
  display: block;
  height: 100%;
}

.quickview-small-img-slider {
  width: 68px;
  float: left;
  display: block;
  margin-top: 35px;
}

.quickview-small-img-slider .single-small-img {
  margin-bottom: 11px;
}

.quickview-small-img-slider .single-small-img img {
  width: 100%;
}

.quickview-small-img-slider .single-small-img.slick-active {
  border: 1px solid transparent;
}

.quickview-small-img-slider .single-small-img.slick-active.slick-current {
  border: 1px solid #fc4f4f;
}

.quickview-big-img-slider {
  width: calc(100% - 68px);
  padding-left: 30px;
  float: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quickview-big-img-slider {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .quickview-big-img-slider {
    padding-left: 15px;
  }
}

.quickview-big-img-slider .single-big-img img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .quickview-content {
    margin-top: 20px;
  }
}

.tooltip .tooltip-inner {
  padding: 0px 12px 2px;
  background-color: #000;
  font-size: 14px;
}

/*---------------------------------------
    33. Footer CSS
-----------------------------------------*/
.footer-default {
  padding: 0 90px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-default {
    padding: 0 60px 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer-default {
    padding: 0 40px 0;
  }
}

.footer-default .copyright-area {
  padding: 13px 0;
  padding-top: 37px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .footer-default .copyright-area {
    padding-top: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-default .copyright-area {
    padding-top: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-default .copyright-area {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-default .copyright-area {
    padding-top: 0px;
  }
}

.footer-default .copyright-area .content p {
  font-size: 11px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1.7px;
  color: #b4b4b4;
}

@media only screen and (max-width: 767px) {
  .footer-default .copyright-area .content p {
    font-size: 10px;
  }
}

.footer-default .copyright-area .content .cp-link li {
  margin-right: calc(25px/2);
  margin-left: calc(25px/2);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-default .copyright-area .content .cp-link li {
    margin-right: calc(18px/2);
    margin-left: calc(18px/2);
  }
}

@media only screen and (max-width: 767px) {
  .footer-default .copyright-area .content .cp-link li {
    margin-right: calc(18px/2);
    margin-left: calc(18px/2);
  }
}

.footer-default .copyright-area .content .cp-link li a {
  color: #a2a2a2;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
}

.footer-default .copyright-area .content .cp-link li a:hover {
  color: #1A1A1A;
}

/*=========================
    Start Footer Widget
===========================*/
.footer-widget .logo a img {
  width: 124px;
}

@media only screen and (max-width: 767px) {
  .footer-widget .logo a img {
    width: 95px;
  }
}

.footer-widget .ft-title {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 0 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget .ft-title {
    margin: 0 0 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .ft-title {
    margin: 0 0 35px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .ft-title {
    margin: 0 0 30px;
  }
}

.footer-widget .footer-content p.pra {
  font-size: 18px;
  line-height: 34px;
  max-width: 320px;
  color: #b4b4b4;
  margin: 30px 0px 30px 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget .footer-content p.pra {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-content p.pra {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-content p.pra {
    font-size: 14px;
    line-height: 26px;
  }
}

.footer-widget .quick-link li {
  margin-bottom: 25px;
}

.footer-widget .quick-link li a {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: #b4b4b4;
  display: block;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
}

.footer-widget .quick-link li a:hover {
  color: #fc4f4f;
}

.footer-widget .quick-link li:last-child {
  margin-bottom: 0px;
}

.footer-widget .quick-link li:not(:last-child) {
  padding-bottom: calc(5px/2);
}

.footer-widget .quick-link li:not(:last-child) {
  margin-top: calc(5px/2);
}

.footer-widget .address p {
  display: flex;
  align-items: center;
  color: #b4b4b4;
}

.footer-widget .address p .list-icon {
  border: 1px solid rgba(225, 225, 225, 0.3);
  height: 58px;
  width: 58px;
  text-align: center;
  line-height: 58px;
  border-radius: 50%;
  color: #ffffff;
  display: block;
  color: #ffffff;
  font-size: 22px;
  margin-right: 15px;
}

.footer-widget .address p .list-content {
  font-size: 16px;
}

.footer-widget.footer-column__a {
  width: 44%;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .footer-widget.footer-column__a {
    width: 25%;
  }
}

.footer-widget.footer-column__a .inner {
  width: 370px;
  max-width: 100%;
  margin-right: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .footer-widget.footer-column__a .inner {
    width: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget.footer-column__a .inner {
    margin-left: auto;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.footer-column__a .inner {
    margin-left: auto;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget.footer-column__a .inner {
    text-align: left !important;
  }
}

.footer-widget.footer-column__b {
  width: 18%;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .footer-widget.footer-column__b {
    width: 25%;
  }
}

.footer-widget.widget_style--2 .logo a img {
  width: 250px;
}

@media only screen and (max-width: 767px) {
  .footer-widget.widget_style--2 .logo a img {
    width: 160px;
  }
}

.footer-widget.widget_style--2 .footer-content p.pra {
  font-size: 16px;
  line-height: 32px;
  max-width: 370px;
  color: #4d4d4d;
  margin: 30px 0px 30px 0px;
}

@media only screen and (max-width: 767px) {
  .footer-widget.widget_style--2 .footer-content p.pra {
    font-size: 14px;
    line-height: 26px;
    margin: 15px 0px 15px 0px;
  }
}

.footer-widget.widget_style--2 .footer-content .footer-social a {
  font-size: 18px;
  margin-right: 24px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .footer-widget.widget_style--2 .footer-content .footer-social a {
    margin-right: 10px;
  }
}

.footer-widget.widget_style--2 .footer-content .footer-social a:last-child {
  margin-right: 0;
}

.footer-widget.widget_style--2 .footer-content .footer-social a i {
  color: #1a1a1a;
}

.footer-widget.widget_style--2 .footer-content .footer-social a:hover i {
  color: #fc4f4f;
}

.footer-widget.widget_style--2 .quick-link li {
  margin-bottom: 0;
}

.footer-widget.widget_style--2 .quick-link li a {
  font-size: 16px;
  line-height: 29px;
  color: #1a1a1a;
  text-transform: capitalize;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .footer-widget.widget_style--2 .quick-link li a {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }
}

.footer-widget.widget_style--2 .quick-link li a:hover {
  color: #fc4f4f;
}

.footer-widget.widget_style--2 .address-two p {
  color: #1a1a1a;
  display: flex;
}

.footer-widget.widget_style--2 .address-two p strong {
  height: 30px;
  float: left;
  margin-right: 8px;
  display: block;
  line-height: 25px;
}

.footer-widget.widget_style--2 .address-two p .list-content {
  font-size: 16px;
  line-height: 25px;
  color: #1a1a1a;
  display: block;
}

@media only screen and (max-width: 767px) {
  .footer-widget.widget_style--2 .address-two p .list-content {
    font-size: 14px;
    line-height: 20px;
  }
}

.footer-widget.widget_style--2.footer-column__a {
  max-width: 35%;
  flex: 0 0 35%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget.widget_style--2.footer-column__a {
    max-width: 100%;
    flex: 0 0 100%;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.widget_style--2.footer-column__a {
    max-width: 100%;
    flex: 0 0 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget.widget_style--2.footer-column__a {
    max-width: 100%;
    flex: 0 0 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 479px) {
  .footer-widget.widget_style--2.footer-column__a {
    max-width: 100%;
    flex: 0 0 100%;
    text-align: center;
  }
}

.footer-widget.widget_style--2.footer-column__a .inner {
  width: 370px;
  max-width: 100%;
  margin-right: auto;
  text-align: left;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .footer-widget.widget_style--2.footer-column__a .inner {
    width: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget.widget_style--2.footer-column__a .inner {
    text-align: center !important;
    margin-left: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.widget_style--2.footer-column__a .inner {
    text-align: center !important;
    margin-left: auto;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget.widget_style--2.footer-column__a .inner {
    text-align: center !important;
    margin-left: auto;
  }
}

@media only screen and (max-width: 479px) {
  .footer-widget.widget_style--2.footer-column__a .inner {
    text-align: left !important;
  }
}

.footer-widget.widget_style--2.footer-column__b {
  max-width: 15%;
  flex: 0 0 15%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget.widget_style--2.footer-column__b {
    max-width: 25%;
    flex: 0 0 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.widget_style--2.footer-column__b {
    max-width: 25%;
    flex: 0 0 25%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget.widget_style--2.footer-column__b {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 479px) {
  .footer-widget.widget_style--2.footer-column__b {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.footer-widget.widget_style--2.footer-column__c {
  max-width: 20%;
  flex: 0 0 20%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget.widget_style--2.footer-column__c {
    max-width: 25%;
    flex: 0 0 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.widget_style--2.footer-column__c {
    max-width: 25%;
    flex: 0 0 25%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget.widget_style--2.footer-column__c {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 479px) {
  .footer-widget.widget_style--2.footer-column__c {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.footer-coming-soon {
  margin-top: -100px;
  margin-bottom: 0;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .footer-coming-soon {
    margin-top: -180px;
    margin-bottom: 0px;
  }
}

.footer-coming-soon-two {
  padding-top: 220px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .footer-coming-soon-two {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-coming-soon-two {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-coming-soon-two {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-coming-soon-two {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-logo-content {
    flex-wrap: wrap;
    text-align: center !important;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-logo-content-two {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-logo-content-two {
    margin-bottom: 30px;
  }
}

.footer-logo-image {
  width: 25%;
  margin-right: 25px;
}

@media only screen and (max-width: 767px) {
  .footer-logo-image {
    margin-right: 0;
    margin: auto;
    margin-bottom: 15px;
  }
}

.foot-content.black-color p {
  color: #1a1a1a;
}

@media only screen and (max-width: 767px) {
  .foot-content.black-color p {
    text-align: center;
  }
}

.foot-content p {
  color: #ffffff;
  font-size: 11px;
  line-height: 20px;
}

.footer-social-icon.black-color a {
  border-color: #1a1a1a;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .footer-social-icon.black-color a {
    margin-right: 15px;
  }
}

.footer-social-icon.black-color a i {
  color: #1a1a1a;
}

.footer-social-icon.black-color a:hover i {
  color: #ffffff;
}

.footer-social-icon a {
  font-size: 23px;
  border: 1px solid rgba(255, 255, 255, 0.32);
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0);
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-right: 24px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .footer-social-icon a {
    margin-right: 10px;
  }
}

.footer-social-icon a:last-child {
  margin-right: 0;
}

.footer-social-icon a i {
  color: #ffffff;
}

.footer-social-icon a:hover {
  border-color: rgba(0, 0, 0, 0);
}

.footer-social-icon a.facebook:hover {
  background-color: #4867AA;
}

.footer-social-icon a.twitter:hover {
  background-color: #1DA1F2;
}

.footer-social-icon a.google:hover {
  background-color: #DD5144;
}

.footer-social-icon a.dribbble:hover {
  background-color: #EA4C8A;
}

.footer-nav {
  text-align: center;
}

.footer-nav ul li {
  display: inline-block;
  padding: 0 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .footer-nav ul li {
    padding: 0 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-nav ul li {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-nav ul li {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-nav ul li {
    display: block;
    padding: 0;
    padding-bottom: 10px;
  }
}

.footer-nav ul li a {
  font-size: 18px;
  font-weight: 600;
  line-height: 33px;
  color: #1a1a1a;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-nav ul li a {
    font-size: 14px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-nav ul li a {
    font-size: 14px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-nav ul li a {
    font-size: 14px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-nav ul li a {
    font-size: 14px;
    line-height: 24px;
  }
}

.footer-nav ul li a:hover {
  color: #fc4f4f;
}

.copyright-area-two .content p {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: 1px;
  color: #737373;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .copyright-area-two .content p {
    font-size: 11px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright-area-two .content p {
    font-size: 11px;
  }
}

@media only screen and (max-width: 767px) {
  .copyright-area-two .content p {
    text-align: center;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-social-three {
    text-align: center !important;
    margin-top: 10px;
  }
}

.footer-social-three a {
  font-size: 22px;
  margin-right: 24px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-social-three a {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-social-three a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-social-three a {
    font-size: 18px;
  }
}

.footer-social-three a:last-child {
  margin-right: 0;
}

.footer-social-three a i {
  color: #1a1a1a;
}

.footer-social-three a:hover i {
  color: #fc4f4f;
}
