/*----------------------------------------*/
/*  04. About CSS
/*----------------------------------------*/
/* About Intro Area CSS */
.about-area{
    // Responsive
    @media #{$large-mobile}{
        text-align: center;
    }
    & .title{
        font-size: 46px;
        line-height: 51px;
        font-weight: 700;
        margin-bottom: 20px;
        // Responsive
        @media #{$desktop-device}{
            font-size: 32px;
            line-height: 35px;
        }
        @media #{$tablet-device}{
            font-size: 32px;
            line-height: 35px;
        }
        @media #{$large-mobile}{
            font-size: 24px;
            line-height: 26px;
        }
    }
    & p{
        font-size: 16px;
        line-height: 36px;
        margin-bottom: 40px;
        // Responsive
        @media #{$large-mobile}{
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 15px;
        }
    }
}
.about-intro-image{
    position: relative;
    // Responsive
    @media #{$large-mobile}{
        margin-top: 30px;
    }
    & .about-image{
        text-align: right;
        &.blog-image{
            & img{
                width: 100%;
            }
        }
        & img{
            width: 455px;
            // Responsive
            @media #{$large-mobile}{
                width: 100%;
            }
        }
    }
    & .play-btn-area{
        position: absolute;
        left: -20px;
        bottom: 65px;
        text-align: left;
        &.center{
            left: 0;
            right: 0;
            top: 50%;
            bottom: auto;
            transform: translateY(-50%);
            text-align: center;
        }
        // Responsive
        @media #{$large-mobile}{
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
        }
        & .play-btn{
            display: inline-block;
            border-radius: 50%;
            position: relative;
            transition: all .5s;
            text-align: left;
            &::before{
                content: '';
                position: absolute;
                left: -5px;
                right: -5px;
                top: -5px;
                bottom: -5px;
                background: rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                opacity: 0;
                transition: all .5s;
                z-index: 1;
            }
            & img{
                width: 90px;
                border-radius: 50%;
                box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.18);
            }
            &:hover{
                background: rgba(0, 0, 0, 0.1);
                padding: 10px;
                margin: -10px;
                &::before{
                    opacity: 1;
                    left: -15px;
                    right: -15px;
                    top: -15px;
                    bottom: -15px;
                }
            }
        }
    }
}
/* About Page Content */
.raz-about-content{
    & h2{
        font-size: 46px;
        line-height: 51px;
        margin-bottom: 30px;
        // Responsive
        @media #{$tablet-device}{
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 15px;
        }
        @media #{$large-mobile}{
            font-size: 26px;
            line-height: 34px;
            margin-bottom: 15px;
        }
    }
    & p{
        font-size: 16px;
        line-height: 36px;
        color: $heading-color;
        margin-bottom: 0;
        // Responsive
        @media #{$tablet-device}{
            font-size: 14px;
            line-height: 26px;
        }
        @media #{$large-mobile}{
            font-size: 14px;
            line-height: 26px;
        }
    }
    & .about-list{
        padding: 40px 0px 40px 0px;
        // Responsive
        @media #{$tablet-device}{
            padding: 20px 0px 20px 0px;
        }
        @media #{$large-mobile}{
            padding: 20px 0px 20px 0px;
        }
        & li{
            font-size: 16px;
            line-height: 9px;
            padding-bottom: 45px;
            color: $heading-color;
            // Responsive
            @media #{$tablet-device}{
                font-size: 14px;
                padding-bottom: 20px;
            }
            @media #{$large-mobile}{
                font-size: 14px;
                padding-bottom: 20px;
            }
            & i{
                color: #1a1a1a;
                font-size: 8px;
            }
            & span{
                padding-left: 13px;
            }
            &:last-child{
                padding-bottom: 0;
            }
        }
    }
}
/* Single Choose Item CSS */
.single-choose-item{
    text-align: center;
    & .chosse-icon{
        width: 45px;
        height: 45px;
        margin: auto;
        margin-bottom: 15px;
        // Responsive
        @media #{$large-mobile}{
            margin-bottom: 0;
        }
    }
    & .choose-content{
        & .title{
            font-size: 18px;
            line-height: 18px;
            font-weight: 700;
            padding: 15px 0;
            margin-bottom: 0;
            // Responsive
            @media #{$large-mobile}{
                font-size: 16px;
            }
        }
        & p{
            font-size: 14px;
            line-height: 28px;
            margin-bottom: 0;
        }
    }
}