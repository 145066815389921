/*----------------------------------------*/
/*  03. Hero CSS
/*----------------------------------------*/
.pl-250{
    padding-left: 250px;
    // Responsive
    @media #{$laptop-device}{
        padding-left: 15px;
    }
    @media #{$desktop-device}{
        padding-left: 15px;
    }
    @media #{$tablet-device}{
        padding-left: 15px;
    }
    @media #{$large-mobile}{
        padding-left: 15px;
    }
}
.pr-250{
    padding-right: 250px;
    // Responsive
    @media #{$laptop-device}{
        padding-right: 15px;
    }
    @media #{$desktop-device}{
        padding-right: 15px;
    }
    @media #{$tablet-device}{
        padding-right: 15px;
    }
    @media #{$large-mobile}{
        padding-right: 15px;
    }
}
.coustom-padding{
    padding: 100px 80px 0px 80px;
    // Responsive
    @media #{$laptop-device}{
        padding: 80px 15px 0px 15px;
    }
    @media #{$desktop-device}{
        padding: 0px 15px 0px 15px;
    }
    @media #{$tablet-device}{
        padding: 0px 15px 0px 15px;
    }
    @media #{$large-mobile}{
        padding: 0px 15px 0px 15px;
    }
}
/* Static Slider CSS */
.static-slider-content{
    & .title{
        font-size: 86px;
        line-height: 103px;
        font-weight: 700;
        margin-bottom: 30px;
        // Responsive
        @media #{$laptop-device}{
            font-size: 64px;
            line-height: 76px;
        }
        @media #{$desktop-device}{
            font-size: 46px;
            line-height: 56px;
            margin-bottom: 20px;
        }
        @media #{$tablet-device}{
            font-size: 38px;
            line-height: 45px;
            margin-bottom: 20px;
        }
        @media #{$large-mobile}{
            font-size: 26px;
            line-height: 31px;
            margin-bottom: 20px;
        }
    }
    & p{
        font-size: 18px;
        line-height: 36px;
        max-width: 760px;
        margin-bottom: 60px;
        // Responsive
        @media #{$desktop-device}{
            margin-bottom: 30px;
        }
        @media #{$tablet-device}{
            font-size: 16px;
            line-height: 32px;
            margin-bottom: 25px;
        }
        @media #{$large-mobile}{
            font-size: 14px;
            line-height: 28px;
            margin-bottom: 20px;
        }
    }
}
.static-slider-image{
    border-radius: 50% 50% 50% 50%;
    overflow: hidden;
    // Responsive
    @media #{$large-mobile}{
        margin-top: 30px;
    }
    & a{
        display: block;
        position: relative;
        

        & img{
            max-width: none;
            width: -webkit-calc(100% + 50px);
            width: calc(100% + 50px);
            transition: transform 0.35s;
            transform: translate3d(-40px, 0, 0);

        }
    }
    &:hover{
        a{
            & img{
                transform: translate3d(0, 0, 0);
            }
        }
    }
}

/*-- Hero Item --*/
.hero-item {
    width: 100%;
    height: 760px;
    align-items: center;
    display: flex !important;
    justify-content: center;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    // Responsive
    @media #{$laptop-device}{
    }
    @media #{$desktop-device}{
        height: 580px;
    }
    @media #{$tablet-device}{
        height: 580px;
    }
    @media #{$large-mobile}{
        height: 400px;
    }
}
/*-- Hero Content For Background Video --*/
.hero-motion-effects-one{
    position: absolute;
    top: 65%;
    left: 40px;
    transform: translateY(-60%);
    // Responsive
    @media #{$laptop-device}{
        left: 15px;
    }
    @media #{$desktop-device}{
        left: 15px;
    }
    @media #{$tablet-device}{
        left: 15px;
    }
    @media #{$large-mobile}{
        display: none;
    }
    @media #{$small-mobile}{
        display: none;
    }
    @media #{$extra-small-mobile}{
        display: none;
    }
    & img{
        // Responsive
        @media #{$laptop-device}{
            width: 220px;
        }
        @media #{$desktop-device}{
            width: 170px;
        }
        @media #{$tablet-device}{
            width: 170px;
        }
        @media #{$large-mobile}{
            width: 170px;
        }
    }
}
.hero-motion-effects-tow {
	position: absolute;
	right: 0;
    bottom: 0;
    & img{
        // Responsive
        @media #{$laptop-device}{
            width: 255px;
        }
        @media #{$desktop-device}{
            width: 235px;
        }
        @media #{$tablet-device}{
            width: 235px;
        }
        @media #{$large-mobile}{
            display: none;
        }
        @media #{$small-mobile}{
            display: none;
        }
        @media #{$extra-small-mobile}{
            display: none;
        }
    }
}
.hero-content {
    text-align: center;
    margin-top: 200px;
    // Responsive
    @media #{$desktop-device}{
        margin-top: 130px;
    }
    @media #{$tablet-device}{
        margin-top: 0px;
    }
    @media #{$large-mobile}{
        margin-top: 0px;
    }
    & h1 {
        font-size: 66px;
        line-height: 74px;
        font-weight: 700;
        color: $heading-color;
        margin-bottom: 20px;
        // Responsive
        @media #{$desktop-device}{
            font-size: 58px;
        }
        @media #{$tablet-device}{
            font-size: 48px;
        }
        @media #{$large-mobile}{
            font-size: 38px;
        }
        @media #{$small-mobile}{
            font-size: 36px;
        }
        @media #{$extra-small-mobile}{
            font-size: 24px;
            line-height: 30px;
        }
    }
    & p{
        font-size: 18px;
        line-height: 37px;
        max-width: 855px;
        margin: auto;
        // Responsive
        @media #{$desktop-device}{
            max-width: 550px;
        }
        @media #{$tablet-device}{
            max-width: 550px;
        }
        @media #{$large-mobile}{
            font-size: 16px;
            line-height: 30px;
        }
        @media #{$extra-small-mobile}{
            font-size: 14px;
            line-height: 24px;
        }
    }
    & a {
        font-size: 18px;
        line-height: 20px;
        margin-top: 145px;
        // Responsive
        @media #{$desktop-device}{
            margin-top: 100px;
        }
        @media #{$tablet-device}{
            margin-top: 50px;
        }
        @media #{$large-mobile}{
            font-size: 16px;
            margin-top: 30px;
        }
        & span{
            display: block;
            text-align: center;
            & i{
                font-size: 25px;
                margin-top: 15px;
            }
        }
        &:hover{
            color: $theme-color-two;
        }
    }
}

