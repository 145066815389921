/*---------------------------------------
    16. Comment CSS
-----------------------------------------*/

/*-- Comment Wrap --*/
.post-comment-wrapper{
    .title{
        font-size: 32px;
        line-height: 1;
        display: block;
        font-weight: 400;
        margin-bottom: 30px;
        // Responsive
        @media #{$large-mobile}{
            font-size: 24px;
            margin-bottom: 20px;
        }
    }
}


.comment-list{
    border: 1px solid rgba(0, 0, 0, 0.1);
    .comment{
        padding: 0 50px;
        display: flex;
        align-items: center;
        // Responsive
        @media #{$large-mobile}{
            padding: 20px;
            flex-wrap: wrap;
        }
        .border-bottom{
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .thumbnail{
            & img{
                width: 95px;
                height: 95px;
                margin-right: 50px;
                border-radius: 50%;
            }
        }
        .content{
            width: 80%;
            width: calc(100% - 140px);
            padding: 50px 0;
            // Responsive
            @media #{$large-mobile}{
                width: 100%;
                padding: 15px 0;
            }
            .desc {
                p {
                    font-size: 16px;
                    color: #535353;
                    font-family: "Playfair Display", sans-serif;
                    // Responsive
                    @media #{$large-mobile}{
                        font-size: 14px;
                    }
                }
            } 
            .comment-meta{
                display: flex;
                justify-content: space-between;
                align-items: center;
                // Responsive
                @media #{$large-mobile}{
                    flex-wrap: wrap;
                }
                .comment-author{
                    padding-top: 10px;
                    // Responsive
                    @media #{$large-mobile}{
                        padding-top: 0px;
                    }
                    span{
                        &.name{
                            font-size: 12px;
                            color: $heading-color;
                            font-weight: 400;
                        }
                        &.date{
                            color: #989898;
                            font-size: 12px;
                        }
                    }
                }
                .reply-btn{
                    a {
                        color: #2f2f2f;
                        text-transform: uppercase;
                        padding: 0 10px;
                        display: inline-block;
                        font-size: 12px;
                    }
                }
            } 
        }

        &.children {
            margin-left: 80px;
            // Responsive
            @media #{$large-mobile}{
                margin-left: 0px;
            }
        }


        &.thread-odd {

        }

    }
}



/*-- Comment Form --*/
.comment-form-wrapper{
    .title{
        font-size: 32px;
        line-height: 1;
        display: block;
        font-weight: 400;
        margin-bottom: 30px;
        // Responsive
        @media #{$large-mobile}{
            font-size: 24px;
            margin-bottom: 20px;
        }
    }
    p{
        font-size: 12px;
        line-height: 20px;
    }
}
.requard{
    color: $theme-color-two;
}
.comment-form{
    label{
        display: block;
        padding-bottom: 5px;
        font-size: 14px;
        line-height: 24px;
        color: $heading-color;
    }
    input{
        width: 100%;
        max-width: 100%;
        -webkit-appearance: none;
        border-color: #e8e8e8;
        border-style: solid;
        border-width: 1px;
        height: 60px;
        margin: 0 0 15px;
        padding: 0 15px;
        background: #ffffff;
    }
    textarea{
        height: 150px;
        width: 100%;
        max-width: 100%;
        vertical-align: top;
        -webkit-appearance: none;
        border-color: #e8e8e8;
        border-style: solid;
        border-width: 1px;
        margin: 0 0 15px;
        background: #ffffff;
        padding: 15px;
    }
    .button-link{
        span{
            color: #ffffff;
        }
    }
}