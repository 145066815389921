/*----------------------------------------*/
/*  31. 404 CSS
/*----------------------------------------*/

/* 404 Error CSS */
.error-404-page-area{
    // Responsive
    @media #{$large-mobile}{
        padding: 50px 0;
    }
}
.error-content{
    // Responsive
    @media #{$large-mobile}{
        text-align: center;    
    }
   & h1{
        font-size: 86px;
        line-height: 1;
        font-weight: 700;
        margin: 0 0 5px;
        // Responsive
        @media #{$large-mobile}{

        }
   } 
   & h2{
       font-size: 46px;
       line-height: 64px;
       font-weight: 700;
       margin: 15px 0;
       // Responsive
       @media #{$large-mobile}{
            font-size: 26px;
            line-height: 36px;
       }
   }
   & p{
       font-size: 16px;
       line-height: 36px;
       margin-bottom: 0;
       // Responsive
       @media #{$large-mobile}{
            font-size: 14px;
            line-height: 28px;
       }
   }
   & a{
       text-transform: uppercase;
       margin-top: 40px;
       //Responsive
       @media #{$large-mobile}{
           margin-top: 20px;
           padding: 0 !important;
        }
       &::before{
           //Responsive
           @media #{$large-mobile}{
               display: none;
           }
       }
   }
}
.error-image{
    // Responsive
    @media #{$large-mobile}{
        margin-top: 30px;
    }
}
