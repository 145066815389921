/*----------------------------------------*/
/*  23. My Account CSS
/*----------------------------------------*/

.my-account-form-area{
  max-width: 500px;
  margin: 0px auto;
  & form{
    & h2{
      font-size: 34px;
      line-height: 47px;
      font-weight: 700;
      text-align: center;
    }
    & .single-input{
        margin-bottom: 30px;
        // Responsive
        @media #{$desktop-device}{
            margin-bottom: 20px;
        }
        @media #{$large-mobile}{
            margin-bottom: 20px;
        }
        & label{
            display: block;
            font-size: 14px;
            line-height: 24px;
            color: $heading-color;
            text-align: left;
            margin-bottom: 8px;
        }
        & input{
            width: 100%;
            height: 70px;
            padding: 10px 20px;
            font-size: 14px;
            text-align: left;
            border: 1px solid #CDCDCD;
            // Responsive
            @media #{$desktop-device}{
                height: 50px;
            }
            @media #{$large-mobile}{
                height: 50px;
            }
            &:focus{
                border-color: $heading-color;
            }
        }
    }
    & label{
      display: block;
      font-size: 14px;
      line-height: 24px;
      color: $heading-color;
      text-align: left;
      margin-bottom: 8px;
    }
    & .lost-pass{
      margin-top: 20px;
      & a{
        font-size: 14px;
        line-height: 24px;
        color: $theme-color-two;
      }
    }
  }
}
  
  .lost-password {
      & > a{
        color: #a43d21;
        &:hover {
          color: $theme-color;
        }
      }
  }
  