/*----------------------------------------*/
/*  11. Product CSS
/*----------------------------------------*/
/* Product Slider */
.product-slider-4 {
    & .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        border: none;
        background-color: $theme-color-two;
        color: $heading-color;
        width: 30px;
        height: 44px;
        border-radius: 6px;
        text-align: center;
        &::before, &::after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: $theme-color-two;
            transform: rotate(60deg);
            z-index: -1;
            border-radius: 6px;
        }
        &::after {
            transform: rotate(-60deg);
        }
        & i {
            font-size: 18px;
            margin-right: 0px;
            line-height: 25px;
            font-weight: 700;
            color: $heading-color;
        }
        &.slick-prev {
            left: 25px;
        }
        &.slick-next {
            right: 25px;
        }
    }
    & .slick-dots {
        width: 100%;
        padding: 0;
        margin: 20px 0 0;
        display: flex;
        justify-content: center;
        & li {
            margin: 0 5px;
            & button {
                display: block;
                padding: 0;
                width: 12px;
                height: 12px;
                border: none;
                background-color: $body-color-light;
                text-indent: -9999px;
                border-radius: 50px;
            }
            &.slick-active {
                & button {
                    background-color: $theme-color-two;
                }
            }
        }
    }
}

/*-- Product --*/
.single-grid-product{
    transition: 0.6s;
    position: relative;
    &.style-two{
        & .product-image{
            & a{
                position: relative;
                overflow: hidden;
                display: block;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(38, 38, 38, 0.6);
                    opacity: 0;
                    transition:  all 0.3s ease-in-out;
                }
            }
        }
        & .product-content{
            position: absolute;
            width: calc(100% - 20px);
            bottom: 0;
            left: 0;
            padding: 50px 40px;
            opacity: 0;
            transition: .5s;
            // Responsive
            @media #{$extra-device}{
                padding: 30px 20px;
                width: calc(100% - 40px);
            }
            @media #{$laptop-device}{
                width: 100%;
                padding: 30px 20px;
            }
            @media #{$tablet-device}{
                width: 100%;
                padding: 30px 20px;
            }
            @media #{$large-mobile}{
                padding: 15px 20px;
            }
            & .title{
                color: $white;
            }
            & .product-price{
                color: $white;
            }
            & .product-rating{
                & .rating{
                    color: $white;
                }
            }
            & .product-action{
                margin-top: 20px;
                // Responsive
                @media #{$laptop-device}{
                    margin-top: 10px;
                }
                &.action-rs-small{
                    // Responsive
                    @media #{$large-mobile}{
                        margin-top: 5px;
                    }
                    & ul{
                        & li{
                            & a{
                                // Responsive
                                @media #{$laptop-device}{
                                    width: 40px;
                                    height: 40px;
                                    line-height: 40px;
                                    font-size: 18px;
                                }
                                @media #{$tablet-device}{
                                    width: 40px;
                                    height: 40px;
                                    line-height: 40px;
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }
                & ul{
                    & li{
                        display: inline-block;
                        opacity: 1;
                        & a{
                            background-color: #fff;
                            color: #2F2F2F;
                            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
                            border-radius: 0;
                            width: 60px;
                            height: 60px;
                            line-height: 60px;
                            display: inline-block;
                            font-size: 18px;
                            font-weight: 300;
                            display: block;
                            padding: 0;
                            position: relative;
                            display: inline-block;
                            text-align: center;
                            margin: 3px;
                            vertical-align: middle;
                            // Responsive
                            @media #{$extra-device}{
                                width: 50px;
                                height: 50px;
                                line-height: 50px;
                            }
                            @media #{$large-mobile}{
                                width: 40px;
                                height: 40px;
                                line-height: 40px;
                            }
                            &:hover{
                                background-color: $heading-color;
                                color: $white;
                            }
                        }
                        &:last-child{
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        &:hover{
            & .product-image{
                & a{
                    &::before{
                        opacity: 1;
                    }
                }
            } 
            & .product-content{
                opacity: 1;
            }
        }
    }
    & .product-label{
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 2;
        span{
            font-size: 11px;
            font-weight: 300;
            color: #fff;
            text-align: center;
            line-height: 50px;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            display: block;
            margin-bottom: 15px;
            margin-left: auto;
            &:last-child{
                margin-bottom: 0;
            }
            &.new{
                background-color: $heading-color;
            }
    
            &.sale{
                background-color: #D97841;
            }
            &.out-stock{
                width: auto;
                border-radius: 0;
                padding: 8px 20px;
                height: auto;
                line-height: 1.8;
                background-color: $heading-color;
            }
        }
    }
    & .product-image{
        position: relative;
        margin-bottom: 20px;
        overflow: hidden;
        &.image-grayscale{
            filter: grayscale(100%);
        }
        & > a{
            display: block;
            width: 100%;

            & img{
                width: 100%;
                transition: 0.6s;
                &:nth-child(1){
                    visibility: visible;
                    opacity: 1;
                }
                &:nth-child(2){
                    position: absolute;
                    top: 0;
                    left: 0;
                    visibility: hidden;
                    opacity: 0;

                }
            }
        }
        & .product-action{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 22px;
            width: 100%;
            z-index: 2;
            text-align: center;
            &.product-action-two{
                & ul{
                    & li{
                        & a{
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                            font-size: 16px;
                        }
                    }
                }
            }
            & ul{
                & li{
                    display: inline-block;
                    transition: all 250ms ease-out;
                    transition-delay: 100ms;
                    transform: translate(40px, 0);
                    opacity: 0;
                    // Responsive
                    @media #{$desktop-device}{
                        transform: translate(20px, 0);
                    }
                    @media #{$large-mobile}{
                        transform: translate(20px, 0);
                    }
                    &:nth-child(2){
                        transition-delay: 150ms;
                    }
                    &:nth-child(3){
                        transition-delay: 200ms;
                    }
                    &:nth-child(4){
                        transition-delay: 200ms;
                    }
                    & a{
                        background-color: #fff;
                        color: #2F2F2F;
                        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
                        border-radius: 0;
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                        display: inline-block;
                        font-size: 18px;
                        font-weight: 300;
                        display: block;
                        padding: 0;
                        position: relative;
                        display: inline-block;
                        text-align: center;
                        margin: 3px;
                        vertical-align: middle;
                        // Responsive
                        @media #{$large-mobile}{
                            width: 45px;
                            height: 45px;
                            line-height: 45px;
                        }
                        &:hover{
                            background-color: $heading-color;
                            color: $white;
                        }
                    }
                    &:last-child{
                        padding-right: 0;
                    }
                }
            }
        }
        & .product-action-content{
            position: absolute;
            left: 0;
            right: 0;
            bottom: -70px;
            background-color: $heading-color;
            opacity: 0;
            visibility: hidden;
            width: 100%;
            z-index: 2;
            text-align: center;
            transition: all .6s;
            & ul{
                & li{
                    display: inline-block;
                    transition: all 250ms ease-out;
                    transition-delay: 100ms;
                    transform: translate(40px, 0);
                    opacity: 0;
                    // Responsive
                    @media #{$desktop-device}{
                        transform: translate(20px, 0);
                    }
                    @media #{$large-mobile}{
                        transform: translate(20px, 0);
                    }
                    &:nth-child(2){
                        transition-delay: 150ms;
                    }
                    &:nth-child(3){
                        transition-delay: 200ms;
                    }
                    &:nth-child(4){
                        transition-delay: 200ms;
                    }
                    & a{
                        & span{
                            display: block;
                            padding: 22px 15px;
                            visibility: visible;
                            opacity: .5;
                            position: relative;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 1;
                            color: $white;
                            // Responsive
                            @media #{$laptop-device}{
                                display: none;
                            }
                            @media #{$desktop-device}{
                                display: none;
                            }
                            @media #{$tablet-device}{
                                display: none;
                            }
                            @media #{$large-mobile}{
                                display: none;
                            }
                            &:hover{
                                opacity: 1;
                            }
                        }
                        & i{
                            display: none;
                            width: 60px;
                            height: 60px;
                            line-height: 60px;
                            font-size: 18px;
                            color: $white;
                            transition: all 0.3s ease-in-out;
                            // Responsive
                            @media #{$large-mobile}{
                                width: 50px;
                                height: 40px;
                                line-height: 40px;
                            }
                            &:hover{
                                color: $theme-color-two;
                            }
                            // Responsive
                            @media #{$laptop-device}{
                                display: block;
                            }
                            @media #{$desktop-device}{
                                display: block;
                            }
                            @media #{$tablet-device}{
                                display: block;
                            }
                            @media #{$large-mobile}{
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        
    }
    & .product-content{
        text-align: center;
        &.color-white{
            & .title{
                color: $white;
            }
            & p{
                color: $white;
            }
            & .product-rating{
                & span{
                    color: $white;
                }
            }
        }
        &.product-content-two{
            & .title{
                font-size: 14px;
                line-height: 24px;
            }
        }
        & .title{
            font-size: 16px;
            font-weight: 400;
            margin: 0 0 5px;
            a{
                display: block;
                &:hover{
                    color: #fc4f4f;
                }
            }
        }
    
        & p{
            line-height: 20px;
            display: inline-block;
            font-size: 18px;
            font-weight: 700;
            color: $heading-color;
            margin-bottom: 0;
            .main-price{
                &.discounted{
                    & del{
                        opacity: .6;
                    }
                }
    
            }
    
            .discounted-price{

            }
        }
        & .product-rating{
            line-height: 1;
            span{
                font-size: 12px;
                line-height: 14px;
                color: $heading-color;
            }
        }
    }
    
    &:hover{
        .product-image{
            &.image-grayscale{
                filter: grayscale(0);
            }
            & > a{
                img{
                    
                    &:nth-child(2){
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
            & .product-action{
                & ul{
                    & li{
                        opacity: 1;
                        transform: translate(0, 0);
                        & a{
                            
                        }
                    }
                }
            }
            & .product-action-content{
                bottom: 0;
                opacity: 1;
                visibility: visible;
                & ul{
                    & li{
                        opacity: 1;
                        transform: translate(0, 0);
                        & a{
                            
                        }
                    }
                }
            }
        }
    }
}

.shop-top-bar {
    .shop-topbar-left {
        display: flex;
        // Responsive
        @media #{$large-mobile} {
            display: block;
            overflow: hidden;
        }
        @media #{$extra-small-mobile} {
            display: block;
            overflow: hidden;
        }
        p{
            margin: 0 45px 0 0px;
            font-size: 12px;
            color: $heading-color;
            font-weight: 700;
        }
        .page-show {
            @media #{$large-mobile} {
                margin: 5px 0;
                overflow: hidden;
            }
            span {
                display: inline-block;
                float: left;
                font-size: 12px;
                color: $heading-color;
                margin-right: 14px;
            }
            ul {
                display: inline-block;
                float: left;
                li{
                    display: inline-block;
                    margin-right: 11px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        font-size: 12px;
                        color: $heading-color;
                        border-bottom: 1px solid transparent;
                        &:hover {
                            color: $theme-color;
                            border-bottom: 1px solid $theme-color;
                        }
                        &.active {
                            color: $theme-color;
                            border-bottom: 1px solid $theme-color;
                        }
                    }
                }
            }
        }
    }
    .shop-topbar-right {
        .filter-active {
            a {
                color: #343538;
                font-size: 12px;
                font-weight: 700;
                text-transform: capitalize;
                position: relative;
                padding-right: 25px;
                &:hover {
                    color: $theme-color-two;
                }
                &::after {
                    content: "\f107";
                    font-family: FontAwesome;
                    padding-left: 5px;
                    display: inline-block;
                    line-height: inherit;
                    font-style: normal;
                }
                &.active {
                    &::after {
                        content: "\f106";
                    }
                }
            }
        }
        .short-by {
            position: relative;
            & > a {
                color: #343538;
                font-size: 12px;
                font-weight: 700;
                text-transform: capitalize;
                position: relative;
                padding-right: 25px;
                &:hover {
                    color: $theme-color-two;
                    &::after {
                        content: "\f106";
                    }
                }
                &::after {
                    content: "\f107";
                    font-family: FontAwesome;
                    padding-left: 5px;
                    display: inline-block;
                    line-height: inherit;
                    font-style: normal;
                }
            }
            & .sort-by-dropdown{
                position: absolute;
                background-color: #fff;
                padding: 7px;
                z-index: 11;
                visibility: hidden;
                margin-top: 30px;
                opacity: 0;
                box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.2);
                transition: all 250ms ease-out;
                & li{
                    display: block;
                    & a{
                        padding: 5px 10px;
                        white-space: pre;
                        display: block;
                        border-radius: 4px;
                        font-size: 11px;
                        line-height: 24px;
                    }
                    &.active, &:hover{
                        & a{
                            background-color: $heading-color;
                            color: $white;
                        }
                    }
                }
            }
            &:hover{
                & .sort-by-dropdown{
                    opacity: 1;
                    visibility: visible;
                    margin-top: 7px;
                } 
            }
        }
        &.shop-tab-flex {
            display: flex;
            .shop-tab {
                a {
                    color: $heading-color;
                    font-size: 18px;
                    margin-left: 10px;
                    &.active {
                        color: $theme-color;
                    }
                }
            }
        }
    }
    &.shop-top-bar-flex {
        display: flex;
        justify-content: space-between; 
        @media #{$large-mobile} {
            display: flex;
        }
        @media #{$extra-small-mobile} {
            display: block;
        }
    }
    &.shop-top-bar-right {
        text-align: right;
    }
}
.product-filter-wrapper {
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;
    padding: 40px 40px 0;
    display: none;
    .single-filter-title {
        margin-bottom: 15px;
        h5 {
            font-weight: 700;
            text-transform: uppercase;
            margin: 0;
            font-size: 16px;
        }
    }
    .product-sort {
        ul {
            li {
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    color: $heading-color;
                    font-size: 12px;
                    &:hover {
                        color: $theme-color-two;
                    }
                }
            }
        }
    }
    .product-price-filter {
        ul {
            li {
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    color: $heading-color;
                    font-size: 12px;
                    &:hover {
                        color: $theme-color-two;
                    }
                }
            }
        }
    }
    .product-size {
        ul {
            li {
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    color: $heading-color;
                    font-size: 12px;
                    &:hover {
                        color: $theme-color-two;
                    }
                }
            }
        }
    }
    .product-color {
        ul {
            li {
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    .color-text {
                        color: $heading-color;
                        font-size: 12px;
                    }
                    &:hover .color-text {
                        color: $theme-color-two;
                    } 
                }
            }
        }
    }
    .product-brand {
        ul {
            li {
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    color: $heading-color;
                    font-size: 12px;
                    &:hover {
                        color: $theme-color-two;
                    }
                }
            }
        }
    }
}
/* Shop List CSS */
.shop-list-wrap {
    .product-img-list {
        position: relative;
        overflow: hidden;
        // Responsive
        @media #{$small-mobile}{
            margin-bottom: 0px;
        }
        @media #{$extra-small-mobile}{
            margin-bottom: 20px;
        }
        & .product-label{
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 9;
            span{
                font-size: 11px;
                font-weight: 300;
                color: #fff;
                text-align: center;
                line-height: 50px;
                width: 50px;
                height: 50px;
                border-radius: 100%;
                display: block;
                margin-bottom: 15px;
                margin-left: auto;
                &:last-child{
                    margin-bottom: 0;
                }
                &.new{
                    background-color: $heading-color;
                }
        
                &.sale{
                    background-color: #D97841;
                }
                &.out-stock{
                    width: auto;
                    border-radius: 0;
                    padding: 8px 20px;
                    height: auto;
                    line-height: 1.8;
                    background-color: $heading-color;
                }
            }
        }
        a {
            display: block;
            img {
                width: 100%;
            }
        }
        .hover-img {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            transition: opacity .5s ease,transform 2s cubic-bezier(0,0,.44,1.18);
            opacity: 0;
        }
        &:hover .hover-img {
            opacity: 1;
        }
        & .product-quickview-list {
            position: absolute;
            top: 50%;
            left: 50%;
            display: inline-block;
            text-align: center;
            z-index: 9;
            transform: translate(-50%, -50%);
            a {
                background-color: $heading-color;
                text-transform: uppercase;
                color: $white;
                letter-spacing: 1px;
                font-size: 12px;
                display: block;
                padding: 14px 10px 16px;
                min-width: 140px;
                text-align: center;
                opacity: 0;
                visibility: hidden;
                margin-top: 50px;
                transition: all .4s ease 0s;
                &:hover {
                    background-color: $theme-color-two;
                    color: $white;
                }
            }
        }
        &:hover .product-quickview-list a {
            opacity: 1;
            visibility: visible;
            margin-top: 0;
        }
    }
    .shop-list-content {
        .product-details-content {
            .product-list-price {
                margin: 20px 0 0px;
                & span{
                    font-size: 18px;
                    font-weight: 600;
                    color: $heading-color;
                }
                @media #{$large-mobile} {
                    margin: 12px 0 0px;
                }
            }
            p {
                margin: 20px 0 0;
                @media #{$large-mobile} {
                    margin: 10px 0 0;
                }
            }
            .pro-details-cart {
                margin: 0px 22px 0px 0px;
            }
            .pro-details-quality {
                margin: 40px 0 0px;
                // Responsive
                @media #{$laptop-device} {
                    margin: 30px 0 0px;
                }
                @media #{$desktop-device} {
                    margin: 20px 0 0px;
                }
                @media #{$tablet-device} {
                    margin: 20px 0 0px;
                }
                @media #{$large-mobile} {
                    margin: 20px 0 0px;
                }
            }
            &.quickview-content {
                @media #{$large-mobile} {
                    margin-top: 20;
                }
                @media #{$extra-small-mobile} {
                    margin-top: 0;
                }
            }
        }
    }
    @media #{$large-mobile} {
        &.mb-70 {
            margin-bottom: 30px !important;
        }
    }
}
/* Product Details Page Nav */
.product-nav{
    & ul{
        // Responsive
        @media #{$small-mobile}{
            justify-content: flex-start !important;
        }
        @media #{$extra-small-mobile}{
            justify-content: flex-start !important;
        }
        & li{
            & a{
                line-height: 20px;
                font-size: 20px;
                margin: 0 5px;
                display: block;
                color: $heading-color;
                &:hover{
                    color: $theme-color-two;
                }
            }
        }
    }
}
/* Product Details CSS */
.product-details-content {
    h2 {
        font-size: 32px;
        line-height: 45px;
        margin-bottom: 0px;
        // Responsive
        @media #{$tablet-device}{
            font-size: 20px;
            line-height: 28px;
        }
        @media #{$large-mobile} {
            font-size: 20px;
            line-height: 28px;
        }
    }
    h3 {
        color: $heading-color;
        font-weight: 400;
        font-size: 24px;
        margin: 0;
        @media #{$large-mobile} {
            font-size: 17px;
        }
        & a{
            &:hover{
                color: $theme-color-two;
            }
        }
    }
    & > .product-details-price{
        line-height: 32px;
        display: inline-block;
        font-size: 32px;
        font-weight: 700;
        color: $heading-color;
        margin-bottom: 0;
        // Responsive
        @media #{$large-mobile}{
            font-size: 24px;
            line-height: 24px;
        }
        .main-price{
            &.discounted{
                & del{
                    font-size: 24px;
                    line-height: 24px;
                    font-weight: 400;
                    opacity: .6;
                    // Responsive
                    @media #{$large-mobile}{
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
            }

        }

        .discounted-price{

        }
    }
    .pro-details-sku {
        padding-top: 15px;
        // Responsive
        @media #{$large-mobile}{
            padding-top: 0;
        }
        span {
            color: #343538;
            font-size: 12px;
        }
    }
    p {
        font-size: 14px;
        color: #333;
        margin: 38px 0 0;
        @media #{$desktop-device} {
            margin: 15px 0 0;
        }
        @media #{$tablet-device} {
            margin: 15px 0 0;
        }
        @media #{$large-mobile} {
            margin: 15px 0 0;
        }
    }
    & .fz-16{
        font-size: 16px;
        line-height: 30px;
        margin-top: 20px;
        // Responsive
        @media #{$tablet-device}{
            font-size: 14px;
            line-height: 24px;
            margin-top: 10px;
        }
        @media #{$large-mobile}{
            font-size: 14px;
            line-height: 24px;
            margin-top: 10px;
        }
    }
    .pro-details-quality {
        display: flex;
        align-items: center;
        margin: 40px 0 28px;
        // Responsive
        @media #{$laptop-device} {
            margin: 40px 0 0;
        }
        @media #{$large-mobile} {
            margin: 25px 0 25px;
        }
        @media #{$extra-small-mobile} {
            margin: 20px 0 20px;
            flex-wrap: wrap;
        }
    }
    .pro-details-cart {
        margin: 0px 22px 0px 11px;
        // Responsive
        @media #{$tablet-device} {
            margin: 0px 10px 0px 11px;
        }
        @media #{$large-mobile} {
            margin: 0px 10px 0px 11px;
        }
        a {
            padding: 15px 50px;
            font-weight: bold;
            line-height: 24px;
            float: left;
            text-transform: uppercase;
            color: #fff;
            font-size: 12px;
            transition: all 250ms ease-out;
            background-color: #343538;
            @media #{$tablet-device} {
                padding: 15px 20px;
            }
            @media #{$large-mobile} {
                padding: 15px 10px;
            }
            &:hover {
                background-color: $theme-color-two;
            }
        }
        &.pro-details-mrg-none {
            margin: 0px 22px 0px 0px;
        }
    }
    .pro-details-wishlist {
        margin-right: 22px;
        // Responsive
        @media #{$tablet-device} {
            margin-right: 10px;
        }
        @media #{$large-mobile} {
            margin-right: 10px;
        }
        a {
            position: relative;
            width: 54px;
            height: 54px;
            line-height: 54px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 0;
            font-size: 16px;
            text-align: center;
            &:hover {
                background: $theme-color-two;
                color: $white;
                border-color: $theme-color-two;
            }
        }
    }
    .pro-details-compare {
        position: relative;
        a {
            position: relative;
            width: 54px;
            height: 54px;
            line-height: 54px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 0;
            font-size: 16px;
            text-align: center;
            // Responsive
            @media #{$extra-small-mobile}{
                margin-top: 10px;
            }
            &:hover {
                background: $theme-color-two;
                color: $white;
                border-color: $theme-color-two;
            }
        }
    }
    .pro-details-meta {
        span {
            color: $heading-color;
            font-size: 12px;
            display: inline-block;
            float: left;
            margin-right: 5px;
        }
        ul {
            li {
                margin-right: 2px;
                display: inline-block;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    color: $heading-color;
                    font-size: 12px;
                    &:hover {
                        color: $theme-color-two;
                    }
                }
            }
        }
    }
    .product-html-custom{
        display: flex;
        align-items: center;
        padding-top: 28px;
        // Responsive
        @media #{$tablet-device}{
            flex-wrap: wrap;
        }
        @media #{$extra-small-mobile}{
            flex-wrap: wrap;
        }
        & a{
            margin-right: 20px;
            font-size: 12px;
            display: block;
            color: $heading-color;
            & i{
                font-size: 22px;
                padding-right: 8px;
                vertical-align: middle;
                font-weight: bold;
            }
        }
    }
    .pro-details-social {
        margin-top: 38px;
        margin-bottom: 30px;
        // Responsive
        @media #{$large-mobile}{
            margin-top: 25px;
            margin-bottom: 20px;
        }
        ul {
            display: flex;
            li {
                margin: 0 5px;
                &:first-child {
                    margin-left: 0px;
                }
                &:last-child {
                    margin-right: 0px;
                }
                a {
                    background: 0 0;
                    border-radius: 50%;
                    border: 1px solid #e1e1e1;
                    color: #262626;
                    width: 32px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 16px;
                    display: block;
                    text-align: center;
                    &.facebook{
                        &:hover{
                            background-color: $facebook;
                            color: $white;
                            border-color: $facebook;
                        }
                    }
                    &.twitter{
                        &:hover{
                            background-color: $twitter;
                            color: $white;
                            border-color: $twitter;
                        }
                    }
                    &.google{
                        &:hover{
                            background-color: $google-plus;
                            color: $white;
                            border-color: $google-plus;
                        }
                    }
                    &.pinterest{
                        &:hover{
                            background-color: $pinterest;
                            color: $white;
                            border-color: $pinterest;
                        }
                    }
                }
            }
        }
    }
    .product-rating-stock {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 25px 0 0;
        @media #{$tablet-device} {
            margin: 15px 0 0;
        }
        @media #{$large-mobile} {
            display: block;
            margin: 15px 0 0;
        }
        .product-dec-rating-reviews {
            display: flex;
            line-height: 1;
            .product-dec-rating {
                line-height: 1;
                i {
                    color: #f5b223;
                    font-size: 12px;
                }
            }
            .product-dec-reviews {
                margin-left: 5px;
                a {
                    font-size: 12px;
                    color: #8a8a8a;
                    &:hover {
                        color: $theme-color-two;
                    }
                }
            }
        }
        .pro-stock {
            span {
                font-size: 12px;
                color: $heading-color;
                text-transform: uppercase;
                i{
                    font-size: 14px;
                    position: relative;
                    top: 2px;
                    margin-right: 5px;
                }
            }
        }
    }
    .pro-details-group {
        margin: 40px 0 0;
        @media #{$large-mobile} {
            margin: 15px 0 0;
        }
        .single-group {
            display: flex;
            margin-bottom: 20px;
            .pro-dec-title-stock {
                margin: 5px 0 0 25px;
                .pro-dec-title {
                    h5 {
                        font-weight: bold;
                        font-size: 14px;
                        margin: 0;
                    }
                }
                .pro-dec-price-stock {
                    span {
                        color: #8a8a8a;
                        font-size: 12px;
                        &.pro-price {
                            margin-right: 15px;
                        }
                    }
                }
            }
        }
    }
    .pro-details-size-color{
        margin-top: 30px;
        & form{
            & .table{
                max-width: 422px;
                & tr{
                    margin-bottom: 20px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    display: flex;
                    padding: 10px 10px 10px 20px;
                    align-items: baseline;
                    font-size: 16px;
                    color: $heading-color;
                    // Responsive
                    @media #{$large-mobile}{
                        font-size: 14px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    & td.label{
                        padding: 0px;
                        border-bottom: 0;
                        min-width: auto;
                        display: inline-block;
                        width: auto !important;
                        margin-right: 15px;
                        flex-grow: 1;
                    }
                    & td.value{
                        padding: 0px;
                        border-bottom: 0;
                        min-width: auto;
                        display: inline-block;
                        width: auto !important;
                        margin-right: 0px;
                        flex-grow: 2;
                        & select{
                            height: auto;
                            width: 100%;
                            border: none;
                        }
                    }
                }
            }
        }
    }
    &.quickview-content {
        .product-details-price {
            margin: 15px 0 20px;
            @media #{$tablet-device} {
                margin: 10px 0 10px;
            }
            @media #{$large-mobile} {
                margin: 10px 0 10px;
            }
        }
    }
    &.pro-details-sidebar-style {
        @media #{$large-mobile} {
            .pro-details-cart {
                a { 
                    padding: 18px 20px;
                }
            }
        }
        
    }
}
.shop-list-content{
    & .product-details-content{
        & .pro-details-compare {
            & a{
                // Responsive
                @media #{$extra-small-mobile}{
                    margin-top: 0;
                }
            }
        }
       
    }
}
.product-tabs {
    .easyzoom-style {
        position: relative;
        & .product-label{
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 2;
            span{
                font-size: 11px;
                font-weight: 300;
                color: #fff;
                text-align: center;
                line-height: 50px;
                width: 50px;
                height: 50px;
                border-radius: 100%;
                display: block;
                margin-bottom: 15px;
                margin-left: auto;
                &:last-child{
                    margin-bottom: 0;
                }
                &.new{
                    background-color: $heading-color;
                }
        
                &.sale{
                    background-color: #D97841;
                }
                &.out-stock{
                    width: auto;
                    border-radius: 0;
                    padding: 8px 20px;
                    height: auto;
                    line-height: 1.8;
                    background-color: $heading-color;
                }
            }
        }
        a.easyzoom-pop-up {
            position: absolute;
            right: 20px;
            bottom: 15px;
            z-index: 2;
            background: $white;
            color: $heading-color;
            width: 58px;
            height: 58px;
            line-height: 58px;
            text-align: center;
            font-size: 19px;
            border-radius: 50%;
            display: block;
            margin: 5px;
            order: 2;
            border: 1px solid #CBCBCB;
            transition: all .3s;
            &:hover {
                background-color: $heading-color;
                border-color: $heading-color;
                color: $white;
            }
        }
        .easyzoom {
            > a {
                transition: none;
                img {
                    width: 100%;
                    transition: none;
                } 
            }
        }
        .easyzoom-popup > a img {
            width: 100%;
        }
    }
    
}
.product-dec-slider{
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 800px;
    & .product-dec-small{
        margin: 10px;
        cursor: pointer;
        &.slick-active {
            border: 1px solid transparent;
            // Responsive
            @media #{$large-mobile}{
                border: none;
            }
            &.slick-current {
                border: 1px solid $theme-color;
                // Responsive
                @media #{$large-mobile}{
                    border: none;
                }
            }
        }
    }
}
.product-dec-slider-2{
    & .product-dec-small{
        margin-bottom: 20px;
        &.slick-active {
            border: 1px solid transparent;
            &.slick-current {
                border: 1px solid $theme-color;
            }
        }
    }
}
.product-dec-right {
	width: calc(100% - 140px);
	padding-left: 30px;
    float: left;
    // Responsive
    @media #{$desktop-device} {
        width: calc(100% - 100px);
        padding-left: 15px;
    }
    @media #{$tablet-device} {
        width: calc(100% - 100px);
        padding-left: 15px;
    }
    @media #{$large-mobile} {
        width: calc(100% - 70px);
        padding-left: 15px;
    }
}
.product-dec-left {
	width: 140px;
	float: left;
    display: block;
    // Responsive
    @media #{$desktop-device} {
        width: 100px;
        padding-left: 15px;
    }
    @media #{$tablet-device} {
        width: 100px;
        padding-left: 15px;
    }
    @media #{$large-mobile} {
        width: 70px;
        padding-left: 15px;
    }
}
/* Shop Collection CSS */
.shop-collection-content{
    padding-bottom: 70px;
    // Responsive
    @media #{$laptop-device}{
        padding-bottom: 50px;
    }
    @media #{$desktop-device}{
        padding-bottom: 40px;
    }
    @media #{$tablet-device}{
        padding-bottom: 30px;
    }
    @media #{$large-mobile}{
        padding-bottom: 30px;
    }
    & .title{
        font-size: 76px;
        line-height: 106px;
        // Responsive
        @media #{$desktop-device}{
            font-size: 66px;
            line-height: 76px;
        }
        @media #{$tablet-device}{
            font-size: 56px;
            line-height: 66px;
        }
        @media #{$large-mobile}{
            font-size: 30px;
            line-height: 42px;
        }
    }
    & p{
        font-size: 16px;
        line-height: 32px;
        max-width: 770px;
        // Responsive
        @media #{$desktop-device}{
            max-width: 670px;
        }
        @media #{$tablet-device}{
            max-width: 670px;
        }
        @media #{$large-mobile}{
            font-size: 14px;
            line-height: 26px;
        }
    }
}

/* Product Description CSS */
.description-nav{
    & ul{
        margin: 30px auto 50px;
        // Responsive
        @media #{$tablet-device}{
            margin-top: 25px;
        }
        @media #{$large-mobile}{
            margin: 20px auto 25px;
        }
        & li{
            margin-right: 60px;
            transition: all .3s;
            // Responsive
            @media #{$desktop-device}{
                margin-right: 25px;
            }
            @media #{$tablet-device}{
                margin-right: 20px;
            }
            @media #{$large-mobile}{
                margin-right: 15px;
            }
            &:last-child{
                margin-right: 0px;
            }
            & a{
                font-size: 26px;
                font-weight: 400;
                padding: 0 0 30px;
                text-align: center;
                color: $heading-color;
                position: relative;
                transition: all .3s;
                // Responsive
                @media #{$desktop-device}{
                    font-size: 18px;
                    line-height: 20px;
                    padding: 0 0 18px;
                }
                @media #{$tablet-device}{
                    font-size: 16px;
                    line-height: 16px;
                    padding: 0 0 18px;
                }
                @media #{$large-mobile}{
                    font-size: 16px;
                    line-height: 16px;
                    padding: 0 0 18px;
                }
                &::after{
                    position: absolute;
                    width: 30%;
                    height: 5px;
                    background-color: $heading-color;
                    content: "";
                    left: 0;
                    bottom: 0;
                    opacity: 0;
                    transition: all .3s;
                }
                &.active{
                    font-weight: 700;
                    // Responsive
                    @media #{$large-mobile}{
                        font-weight: 400;
                    }
                    &::after{
                        opacity: 1;
                        width: 100%;
                        height: 5px;
                    }
                }
                &:hover{
                    &::after{
                        opacity: 1;
                        width: 100%;
                        height: 5px;
                    }
                }
            }
        }
    }
}
.single-product-tab {
    .decription-content {
        p {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 30px;
        }
        .list-container {
            list-style: none;
            margin-bottom: 30px;
            .list-items {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                // Responsive
                @media #{$large-mobile}{
                    margin-bottom: 10px;
                }
                .list-icon{
                    font-size: 8px;
                }
                .list-text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    margin-left: 5px;

                }
            }
        }
    }
    .product-review-inner {
        .title{
            font-size: 36px;
            display: block;
            font-weight: 400;
            margin: 0 0 5px;
            margin-bottom: 30px;
            // Responsive
            @media #{$large-mobile}{
                font-size: 24px;
                margin-bottom: 20px;
            }
        }
        .review-list {
            margin: 0;
            list-style: none;
            padding: 0;
            background: #f9f9f9;
            .review {
                padding: 30px 50px;
                display: flex;

                @media #{$large-mobile} {
                    padding: 29px 20px;
                    display: block;
                }

                img {
                    width: 90px;
                    height: 90px;
                    margin-right: 50px;
                    border-radius: 50%;
                }
                .content {
                    width: calc(100% - 140px);

                    @media #{$large-mobile} {
                        width: 100%;
                        margin-top: 24px;
                    }

                    .product-meta {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 30px;
                        @media #{$large-mobile} {
                            display: block;
                        }
                        .meta {
                            font-size: 12px;
                            margin-bottom: 0;
                            strong {
                                font-size: 14px;
                                color: #2f2f2f;
                            }
                        }
                        .rating {
                            color: #f5b223;
                            span {
                                display: inline-block;
                                line-height: 14px;
                                font-size: 14px;
                            }
                        }
                    }
                    .desc {
                        padding-bottom: 30px;
                        font-size: 16px;
                        margin: 0;
                    }
                }

                &.thread-odd{
                    background: #efefef;
                }
            }
        }
    }

    .information-list {
        padding: 0;
        list-style: none;
        width: 500px;
        margin: 0 auto;
        max-width: 100%;
        @media #{$large-mobile} {
            width: auto;
        }
        li {
            padding: 2px 0;
            font-size: 14px;
            line-height: 24px;
            span {
                float: right;
                text-align: left;
                width: 400px;
                @media #{$large-mobile} {
                    width: auto;
                    float: none;
                }
            }
        }
    }
}
.product-about{
    & .title{
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 15px;
        // Responsive
        @media #{$large-mobile}{
            font-size: 24px;
        }
    }
    & p{
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 30px;
    }
}
.product-shipping{
    & p{
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 30px;
    }
}
.review-form{
    padding-top: 50px;
    .title {
        h3 {
            color: #2f2f2f;
            font-size: 36px;
            display: block;
            font-weight: 400;
            margin: 0 0 5px;
            // Responsive
            @media #{$large-mobile}{
                font-size: 24px;
            }
        }
        p {
            font-style: italic;
            font-size: 14px;
            margin-bottom: 30px;
            color: #535353;
        }
    }
    .input-box {
        margin-bottom: 30px;
        label {
            display: block;
            padding-bottom: 5px;
            font-size: 14px;
            color: #535353;
            font-weight: 400;
        }
        input {
            width: 100%;
            max-width: 100%;
            -webkit-appearance: none;
            padding: 17px 25px;
            border-style: solid;
            border-width: 1px;
            border-color: #e8e8e8;
            height: 60px;
        }
        textarea {
            width: 100%;
            height: 150px;
            max-width: 100%;
            vertical-align: top;
            -webkit-appearance: none;
            border-style: solid;
            border-width: 1px;
            border-color: #e8e8e8;
            padding: 17px 25px;
        }

    }
}
/* Related Product CSS */
.related-product-slider{
    & .slick-dots{
        // Responsive
        @media #{$large-mobile}{
            margin-top: 0;
        }
    }
}