/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/

/*-- Google Font --*/
@import url('https://fonts.googleapis.com/css?family=Arimo:400,400i,700,700i|Playfair+Display:400,400i&display=swap');

/*-- Common Style --*/
*, *::after, *::before {
  box-sizing: border-box;
}
html, body, .site-wrapper {
    height: 100%;
}
body{
    background-color: $white;
    font-size: 16px;
    line-height: 32px;
    font-style: normal;
    font-weight: normal;
    visibility: visible;
    font-family: $body-font;
    color: $body-color;
    position: relative;
    &[data-rtl="rtl"] {
        direction: rtl;
        text-align: right;
    }
}
h1, h2, h3, h4, h5, h6 {
    font-family: $heading-font;
    color: $heading-color;
    font-weight: 400;
    margin-top: 0;
    line-height: 1.2;
}
h1 {
    font-size: 36px;
    
    // Responsive
    @media #{$large-mobile}{
        font-size: 32px;
    }
    @media #{$small-mobile}{
        font-size: 30px;
    }
}
h2 {
    font-size: 30px;
    
    // Responsive
    @media #{$large-mobile}{
        font-size: 26px;
    }
    @media #{$small-mobile}{
        font-size: 24px;
    }
}
h3 {
    font-size: 24px;
    
    // Responsive
    @media #{$large-mobile}{
        font-size: 22px;
    }
    @media #{$small-mobile}{
        font-size: 20px;
    }
}
h4 {
    font-size: 18px;
}
h5 {
    font-size: 14px;
}
h6 {
    font-size: 12px;
}
p:last-child {
    margin-bottom: 0;
}
a, button {
    color: inherit;
    display: inline-block;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
}
a, button, img, input, span {
    transition: all 0.3s ease 0s;
}
*:focus {
    outline: none !important;
}
a:focus {
    color: inherit;
    outline: none;
    text-decoration: none;
}
a:hover {
    text-decoration: none;
    color: $theme-color;
}
button{
    background: transparent;
    border: 1px solid #d4d4d4;
    transition: all 0.4s ease-out 0s;
    color: #555555;
    cursor: pointer;
    line-height: normal;
    font-size: 100%;
    margin: 0;
    max-width: 100%;
    vertical-align: baseline;
}
button, input[type="submit"] {
    cursor: pointer;
}
ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
img {
    max-width: 100%;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.coustom-col-4{
    flex: 0 0 25%;
    max-width: 25%;
    // Responsive
    @media #{$laptop-device}{
        flex: 0 0 33%;
        max-width: 33%;
    }
    @media #{$desktop-device}{
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media #{$tablet-device}{
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media #{$large-mobile}{
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media #{$small-mobile}{
        flex: 0 0 100%;
        max-width: 100%;
    }
    @media #{$extra-small-mobile}{
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.bg-image{
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.height-150{
    height: 150px;
    // Responsive
    @media #{$desktop-device}{
        height: 100px;
    }
    @media #{$tablet-device}{
        height: 100px;
    }
    @media #{$large-mobile}{
        height: 100px;
    }
}
.height-100vh{
    height: 100vh;
    // Responsive
    @media #{$large-mobile}{
        height: 100%;
    }
}
.fz-18{
    font-size: 18px;
    // Responsive
    @media #{$tablet-device}{
        font-size: 16px;
        line-height: 26px;
    }
}
.image-fixd{
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.plr-300{
    padding: 0 300px;
    // Responsive
    @media #{$laptop-device}{
        padding: 0 15px;
    }
    @media #{$desktop-device}{
        padding: 0 15px;
    }
    @media #{$tablet-device}{
        padding: 0 15px;
    }
    @media #{$large-mobile}{
        padding: 0 15px;
    }
}
.plr-250{
    padding: 0 250px;
    // Responsive
    @media #{$laptop-device}{
        padding: 0 15px;
    }
    @media #{$desktop-device}{
        padding: 0 15px;
    }
    @media #{$tablet-device}{
        padding: 0 15px;
    }
    @media #{$large-mobile}{
        padding: 0 15px;
    }
}
.plr-190{
    padding: 0 190px;
    // Responsive
    @media #{$laptop-device}{
        padding: 0 15px;
    }
    @media #{$desktop-device}{
        padding: 0 15px;
    }
    @media #{$tablet-device}{
        padding: 0 15px;
    }
    @media #{$large-mobile}{
        padding: 0 15px;
    }
}
.col-60{
    flex: 0 0 60%;
    max-width: 60%;
    // Responsive
    @media #{$tablet-device}{
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media #{$large-mobile}{
        flex: 0 0 100%;
        max-width: 100%;
    }
    @media #{$small-mobile}{
        flex: 0 0 100%;
        max-width: 100%;
    }
    @media #{$extra-small-mobile}{
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.col-40{
    flex: 0 0 40%;
    max-width: 40%;
    // Responsive
    @media #{$tablet-device}{
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media #{$large-mobile}{
        flex: 0 0 100%;
        max-width: 100%;
    }
    @media #{$small-mobile}{
        flex: 0 0 100%;
        max-width: 100%;
    }
    @media #{$extra-small-mobile}{
        flex: 0 0 100%;
        max-width: 100%;
    }
}
#scrollUp {
	background: #222;
	width: 60px;
	height: 60px;
	line-height: 60px;
    border-radius: 100%;
	bottom: 25px;
	right: 25px;
	color: #fff;
	text-align: center;
	font-size: 25px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
    &:hover{
        background: $theme-color;
    }
}
.box-layout{
    width: 1370px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-repeat: repeat;
    background-position: left top;
    background-attachment: scroll;
    background-color: $white;
}
.fix {
    overflow:hidden
}
.hidden {
    display: none;
}
.clear{
    clear: both;
}
.section, .main-wrapper {
    float: left;
    width: 100%;
}
.border-bottom{
    border-bottom: 1px solid #D8D8D8 !important;
}
.border-bottom-1{
    border-bottom: 1px solid #eee !important;
}
.demo-text{
    p{
        line-height: 1.5;
    }
    blockquote{
        border: 2px solid $theme-color;
        border-left-width: 10px;
        padding: 20px;
        font-size: 18px;
        margin: 24px 40px;
        // Responsive
        @media #{$tablet-device}{
            margin: 20px 30px;
        }
        // Responsive
        @media #{$large-mobile}{
            margin: 10px 15px;
        }
    }
}

.overlay{
    position: relative;
    z-index: 9;
    background-position: 50% 50%;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.6;
        z-index: -1;
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1200px
    }
    .row-five-column {
        & > [class*="col-xl-"] {
            max-width: 20%;
            flex: 0 0 20%;
        }
    }
}
@media #{$small-mobile}{
    .container {
        max-width: 450px;
    }
}
@media #{$extra-small-mobile}{
    .container {
        max-width: 300px;
    }
}
.no-gutters {
    margin-left: 0;
    margin-right: 0;
    & > .col, & > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
        margin: 0 !important;
    }
}


/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder, textarea:-moz-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
input::-moz-placeholder, textarea::-moz-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/*--
    - Background Color
------------------------------------------*/
.bg-white {
    background-color: #ffffff !important;
}
.bg-gray {
    background-color: #f9f9f9 !important;
}
.bg-md-gray{
    // Responsive
    @media #{$desktop-device}{
        background-color: #e7e7e7 !important;
    }
    @media #{$tablet-device}{
        background-color: #e7e7e7 !important;
    }
    @media #{$large-mobile}{
        background-color: #e7e7e7 !important;
    }
}
.extra-bg{
    background-color: transparent;
    background-image: linear-gradient(180deg, #eff3ef 80%, #ffffff 0%);
}
.bg-gray-two {
    background-color: #eeeeee !important;
}
.bg-black{
    background-color: #151517;
}
.bg-black-light{
    background-color: #2f2f2f;
}
.bg-dark {
    background-color: $heading-color !important;
}
.bg-dark-two {
    background-color: #1c2535 !important;
}
.bg-dark-three {
    background-color: #1d2736 !important;
}
.bg-dark-four {
    background-color: #1d2634 !important;
}
.bg-skyblue{
    background-color: #f3fbff !important;
}
.allbgcolor{
    background-color: #f9f9f9;
}

.bg-theme {
    background-color: $theme-color !important;
}

/*-- 
    - Tab Content & Pane Fix
------------------------------------------*/
.tab-content {
    width: 100%;
    & .tab-pane {
        display: block;
        height: 0;
        max-width: 100%;
        visibility: hidden;
        overflow: hidden;
        opacity: 0;
        &.active {
            height: auto;
            visibility: visible;
            opacity: 1;
            overflow: visible;
        }
    }
}

/*-- 
    - Main Wrapper
------------------------------------------*/
.main-wrapper {}

/*-- 
    - Section Title
------------------------------------------*/
.section-title {
    &.color-white{
        & h2{
            color: $white;
        }
    }
    &.before-none{
        & h2{
            &::before{
                display: none;
            }
        }
    }
    &.text-bold{
        & h2{
            font-size: 56px;
            font-weight: 700;
            text-transform: capitalize;
            line-height: 78px;
            padding: 0;
            margin-bottom: 10px;
            // Responsive
            @media #{$tablet-device}{
                font-size: 32px;
                line-height: 45px;
            }
            @media #{$large-mobile}{
                font-size: 26px;
                line-height: 33px;
            }
        }
    }
    &.text-normal{
        & h2{
            font-size: 56px;
            font-weight: 400;
            text-transform: capitalize;
            line-height: 78px;
            padding: 0;
            margin-bottom: 10px;
            // Responsive
            @media #{$tablet-device}{
                font-size: 32px;
                line-height: 45px;
            }
            @media #{$large-mobile}{
                font-size: 26px;
                line-height: 33px;
            } 
        }
    }
    & h2 {
        position: relative;
        font-size: 46px;
        line-height: 64px;
        padding: 0 0 22px 0;
        margin-bottom: 35px;
        // Responsive
        @media #{$desktop-device}{
            font-size: 32px;
            line-height: 45px;
        }
        @media #{$tablet-device}{
            font-size: 32px;
            line-height: 45px;
            padding: 0 0 15px 0;
            margin-bottom: 20px;
        }
        @media #{$large-mobile}{
            font-size: 26px;
            line-height: 33px;
            padding: 0 0 12px 0;
            margin-bottom: 15px;
        }
        &::before{
            content: "";
            bottom: 0;
            width: 75px;
            height: 5px;
            background-image: linear-gradient(to right, #DFD3D3, #FEDBC4);
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
            margin: auto;
        }
    }
    & p {
        max-width: 750px;
        @media #{$large-mobile}{
            font-size: 14px;
            line-height: 24px;
        }
    }
    &.full-width{
        & p {
            margin-bottom: 35px;
            max-width: 100%;
        }
    }
    &.text-center {
        text-align: center;
    background-position: top center;
        & p {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &.text-left {
        text-align: left;
        background-position: top left;
        & p {
            margin-left: 0;
            margin-right: auto;
        }
    }
    &.text-right {
        text-align: right;
        background-position: top right;
        & p {
            margin-left: auto;
            margin-right: 0;
        }
    }

    &-two{
        &.color-white{
            & h2{
                color: $white;
            }
            & p{
                color: $white;
            }
        }
        & h2{
            font-size: 56px;
            line-height: 78px;
            margin-bottom: 5px;
            // Responsive
            @media #{$tablet-device}{
                font-size: 32px;
                line-height: 45px;
            }
            @media #{$large-mobile}{
                font-size: 26px;
                line-height: 33px;
            } 
        }
        & p{
            font-size: 18px;
            line-height: 36px;
            max-width: 530px;
            margin: auto;
            margin-bottom: 15px;
            // Responsive
            @media #{$tablet-device}{
                font-size: 16px;
                line-height: 32px;
            }
            @media #{$large-mobile}{
                font-size: 16px;
                line-height: 32px;
            } 
        }
    }
    &-three{
        position: relative;
        &.color-white{
            & h2{
                color: $white;
            }
        }
        &.before-none{
            &::before{
                display: none;
            }
        }
        &::before{
            position: absolute;
            height: 30px;
            width: 4px;
            left: calc(50% - 2px);
            background: #1a1a1a;
            content: "";
            bottom: 0;
        }
        & h2{
            font-size: 56px;
            line-height: 78px;
            margin-bottom: 5px;
            // Responsive
            @media #{$desktop-device}{
                font-size: 38px;
                line-height: 53px;
            }
            @media #{$tablet-device}{
                font-size: 32px;
                line-height: 45px;
            }
            @media #{$large-mobile}{
                font-size: 26px;
                line-height: 33px;
            } 
        }
        & p{
            font-size: 18px;
            line-height: 36px;
            max-width: 530px;
            margin: auto;
            margin-bottom: 15px;
            // Responsive
            @media #{$tablet-device}{
                font-size: 16px;
                line-height: 32px;
            }
            @media #{$large-mobile}{
                font-size: 16px;
                line-height: 32px;
            } 
        }
    }
    &-four{
        & h2{
            font-size: 48px;
            line-height: 64px;
            margin-bottom: 30px;
            position: relative;
            &::before{
                content: '';
                position: absolute;
                top: 85px;
                left: 0;
                right: 0;
                text-align: center;
                width: 80px;
                height: 2px;
                background: #c2c2c2;
                margin: auto;
                // Responsive
                @media #{$desktop-device}{
                    top: 65px;
                }
                @media #{$tablet-device}{
                    top: 55px;
                }
                @media #{$large-mobile}{
                    top: 45px;
                }
            }
            // Responsive
            @media #{$desktop-device}{
                font-size: 38px;
                line-height: 53px;
                margin-bottom: 20px;
            }
            @media #{$tablet-device}{
                font-size: 32px;
                line-height: 45px;
                margin-bottom: 20px;
            }
            @media #{$large-mobile}{
                font-size: 26px;
                line-height: 33px;
                margin-bottom: 20px;
            } 
        }
        & p{
            font-size: 18px;
            line-height: 36px;
            max-width: 530px;
            margin: auto;
            margin-bottom: 15px;
            // Responsive
            @media #{$tablet-device}{
                font-size: 16px;
                line-height: 32px;
            }
            @media #{$large-mobile}{
                font-size: 14px;
                line-height: 26px;
            } 
        }
    }
    &-five{
        & .section-title-icon{
            margin-bottom: 15px;
            & i{
                width: 70px;
                height: 70px;
                line-height: 70px;
                font-size: 26px;
                text-align: center;
                border: 1px solid $heading-color;
                border-radius: 50%;
            }
        }
        & h2{
            font-size: 32px;
            line-height: 45px;
            display: inline-block;
            position: relative;
            padding: 0 140px;
            // Responsive
            @media #{$large-mobile}{
                font-size: 20px;
                line-height: 28px;
                padding: 0 50px;
            }
            &::before{
                background-color: #D8D8D8;
                width: 70px;
                height: 2px;
                content: "";
                position: absolute;
                top: .7em;
                left: 0px;
                // Responsive
                @media #{$large-mobile}{
                    width: 30px;
                    top: .6em;
                }
            }
            &::after{
                background-color: #D8D8D8;
                width: 70px;
                height: 2px;
                content: "";
                position: absolute;
                top: .7em;
                right: 0px;
                // Responsive
                @media #{$large-mobile}{
                    width: 30px;
                    top: .6em;
                }
            }
        }
    }
    &-six{
        & h2{
            font-size: 36px;
            line-height: 50px;
            margin-bottom: 30px;
            position: relative;
            // Responsive
            @media #{$desktop-device}{
                font-size: 36px;
                line-height: 53px;
                margin-bottom: 20px;
            }
            @media #{$tablet-device}{
                font-size: 32px;
                line-height: 45px;
                margin-bottom: 20px;
            }
            @media #{$large-mobile}{
                font-size: 26px;
                line-height: 33px;
                margin-bottom: 20px;
            } 
        }
    }
}

/*-- 
    - Tab  Menu
------------------------------------------*/
.tab-menu{
    & ul{
        li{
            margin: 0 50px;
            // Responsive
            @media #{$laptop-device}{
                margin: 0 35px;
            }
            @media #{$desktop-device}{
                margin: 0 35px;
            }
            @media #{$tablet-device}{
                margin: 0 25px;
            }
            @media #{$large-mobile}{
                margin: 0 25px;
            }
            a{
                position: relative;
                width: 210px;
                padding: 10px 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 46px;
                font-weight: 400;
                line-height: 51px;
                padding-bottom: 22px;
                // Responsive
                @media #{$laptop-device}{
                    width: 100%;
                    text-align: center;
                }
                @media #{$desktop-device}{
                    font-size: 32px;
                    line-height: 35px;
                    width: 100%;
                    text-align: center;
                }
                @media #{$tablet-device}{
                    font-size: 32px;
                    line-height: 35px;
                    width: 100%;
                    text-align: center;
                }
                @media #{$large-mobile}{
                    font-size: 24px;
                    line-height: 30px;
                    width: 100%;
                    text-align: center;
                    padding: 10px 0px;
                }
                &::before{
                    content: "";
                    bottom: 0;
                    width: 25px;
                    height: 5px;
                    background-image: linear-gradient(to right, #DFD3D3, #FEDBC4);
                    position: absolute;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    margin: auto;
                    opacity: 0;
                    transition: all 0.3s;
                }
                &:hover, &.active{
                    &::before{
                        opacity: 1;
                        width: 75px;
                        height: 5px;
                    }
                }
            }
        }
    }
}

.tab-menu-two{
    margin-top: 20px;
    &.border-radius{
        & ul{
            & li{
                & a{
                    font-size: 16px;
                    padding: 15px 30px 15px 30px;
                    // Responsive
                    @media #{$desktop-device}{
                        font-size: 14px;
                        padding: 12px 25px 12px 25px;
                    }
                    @media #{$tablet-device}{
                        font-size: 14px;
                        padding: 10px 20px 10px 20px;
                    }
                    @media #{$large-mobile}{
                        font-size: 14px;
                        padding: 8px 15px 8px 15px;
                    }
                    &.active{
                        border: 1px solid #b4b4b4;
                        border-radius: 50px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    &.color-white{
        & ul{
            & li{
                & a{
                    color: #b4b4b4;
                    &.active{
                        color: $white;
                    }
                }
            }
        }
    }
    &.border-radius-bold{
        & ul{
            & li{
                & a{
                    font-size: 18px;
                    font-weight: 700;
                    padding: 20px 40px 20px 40px;
                    // Responsive
                    @media #{$laptop-device}{
                        padding: 15px 35px 15px 35px;
                    }
                    @media #{$desktop-device}{
                        font-size: 14px;
                        padding: 12px 25px 12px 25px;
                    }
                    @media #{$tablet-device}{
                        font-size: 14px;
                        padding: 10px 20px 10px 20px;
                    }
                    @media #{$large-mobile}{
                        font-size: 14px;
                        padding: 8px 15px 8px 15px;
                    }
                    &.active{
                        border: 1px solid #b4b4b4;
                        border-radius: 50px;
                        font-weight: 700;
                    }
                }
            }
        }
    }
    &.text-bold{
        & ul{
            & li{
                & a{
                    padding: 20px 30px 20px 30px;
                    font-weight: 700;
                    // Responsive
                    @media #{$laptop-device}{
                        font-size: 14px;
                        line-height: 24px;
                    }
                    @media #{$desktop-device}{
                        font-size: 14px;
                        line-height: 24px;
                    }
                    @media #{$tablet-device}{
                        font-size: 14px;
                        line-height: 24px;
                        padding: 8px 15px 8px 15px;
                    }
                    @media #{$large-mobile}{
                        font-size: 14px;
                        line-height: 24px;
                        padding: 8px 15px 8px 15px;
                    }
                }
            }
        }
    }
    & ul{
        & li{
            & a{
                padding: 20px 45px 20px 45px;
                color: $heading-color;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 0.8px;
                line-height: 30px;
                // Responsive
                @media #{$laptop-device}{
                    padding: 15px 35px 15px 35px;
                }
                @media #{$desktop-device}{
                    padding: 10px 20px 10px 20px;
                }
                @media #{$tablet-device}{
                    padding: 8px 15px 8px 15px;
                }
                @media #{$large-mobile}{
                    padding: 8px 15px 8px 15px;
                }
                &.active{
                    border: 1px solid #b4b4b4;
                }
            }
        }
    }
}
.tab-menu-three{
    & ul{
        li{
            padding: 20px 40px 20px 40px;
            // Responsive
            @media #{$laptop-device}{
                margin: 0 35px;
            }
            @media #{$desktop-device}{
            }
            @media #{$tablet-device}{
                padding: 10px 20px 10px 20px;
            }
            @media #{$large-mobile}{
                padding: 5px 10px 5px 10px;
            }
            a{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 46px;
                font-weight: 400;
                line-height: 78px;
                color: #b4b4b4;
                padding-bottom: 22px;
                // Responsive
                @media #{$laptop-device}{
                    width: 100%;
                    text-align: center;
                }
                @media #{$desktop-device}{
                    font-size: 32px;
                    line-height: 35px;
                    width: 100%;
                    text-align: center;
                }
                @media #{$tablet-device}{
                    font-size: 32px;
                    line-height: 35px;
                    width: 100%;
                    text-align: center;
                }
                @media #{$large-mobile}{
                    font-size: 16px;
                    line-height: 30px;
                    width: 100%;
                    text-align: center;
                    padding: 10px 0px;
                }
                &::before{
                    content: "";
                    bottom: 0;
                    width: 25px;
                    height: 2px;
                    background-color: #C2C2C2;
                    position: absolute;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    margin: auto;
                    opacity: 0;
                    transition: all 0.3s;
                }
                &:hover, &.active{
                    color: $heading-color;
                    &::before{
                        opacity: 1;
                        width: 80px;
                        height: 2px;
                        // Responsive
                        @media #{$large-mobile}{
                            width: 45px;
                        }
                    }
                }
            }
        }
    }
}
.filter-menu{
    margin-top: 20px;
    & ul{
        & li{
            & button{
                padding: 20px 45px 20px 45px;
                color: $heading-color;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 0.8px;
                line-height: 30px;
                font-weight: 700;
                border: 0;
                // Responsive
                @media #{$laptop-device}{
                    padding: 15px 35px 15px 35px;
                }
                @media #{$desktop-device}{
                    padding: 10px 20px 10px 20px;
                }
                @media #{$tablet-device}{
                    padding: 8px 15px 8px 15px;
                }
                @media #{$large-mobile}{
                    padding: 8px 15px 8px 15px;
                }
                &.is-checked{
                    border: 1px solid #b4b4b4;
                    background-color: #f9f9f9;
                    border-radius: 50px;
                }
            }
        }
    }
}
/*-- 
    - Read  Button
------------------------------------------*/
.read-btn{
    &.border{
        border: 0 !important;
        padding: 0px 0px 0px 140px;
        &::before{
            right: auto;
            left: 0;
        }
    }
    &.border-none{
        &::before{
            // Responsive
            @media #{$large-mobile}{
                display: none;
            }
        }
    }
    position: relative;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    padding: 0px 140px 0px 0px;
    // Responsive
    @media #{$large-mobile}{
        font-size: 14px;
        line-height: 24px;
        padding-right: 0;
    }
    &::before{
        position: absolute;
        right: 0;
        top: 9px;
        content: "";
        background: $heading-color;
        width: 120px;
        height: 2px;
        // Responsive
        @media #{$large-mobile}{
        }
    }
    &:hover{
        text-decoration: underline;
    }
}

.view-more-btn{
    text-align: center;
    & a{
        font-size: 16px;
        font-weight: 400;
        text-decoration: underline;
        color: $heading-color;
        &:hover{
            color: $theme-color-two;
        }
    }
}

.load-more-btn{
    & a{
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1em;
        color: #1a1a1a;
        background-color: rgba(0,0,0,0);
        padding: 15px 40px 15px 40px;
        border: 1px solid rgba(0,0,0,0.31);
        &:hover{
            color: $white;
            background-color: $heading-color;
        }
    }
}
.load-more-btn-two{
    &.color-white{
        & a{
            border-color: rgba(255,255,255,0.3);
            color: $white;
            font-size: 14px;
            line-height: 14px;
            &:hover{
                color: #000000;
                background-color: #ffe49e;
                border-color: #ffe49e;
            }
        }
    }
    & a{
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1em;
        color: #1a1a1a;
        background-color: rgba(0,0,0,0);
        padding: 25px 60px 25px 60px;
        border: 2px solid rgba(0,0,0,0.31);
        // Responsive
        @media #{$laptop-device}{
            padding: 20px 50px 20px 50px;
        }
        @media #{$desktop-device}{
            padding: 15px 40px 15px 40px;
        }
        @media #{$tablet-device}{
            font-size: 14px;
            padding: 15px 40px 15px 40px;
        }
        @media #{$large-mobile}{
            font-size: 12px;
            padding: 10px 30px 10px 30px;
        }
        &:hover{
            color: $white;
            background-color: $heading-color;
        }
    }
}
.work-btn{
    padding: 45px 0px 45px 0px;
    display: block;
    background-color: #7e7e7e;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: $white;
    text-align: center;
    &:hover{
        color: $white;
    }
}
.animate-btn{
    position: relative;
    background: rgba(0,0,0,0) !important;
    border: 2px solid rgba(0,0,0,0.3);
    padding: 25px 60px 25px 60px;
    font-size: 16px;
    text-transform: uppercase;
    color: $heading-color;
    line-height: 1;
    font-weight: 700;
    z-index: 1;
    // Responsive
    @media #{$desktop-device}{
        padding: 20px 40px;
    }
    @media #{$tablet-device}{
        padding: 20px 40px;
    }
    @media #{$large-mobile}{
        padding: 15px 30px 15px 30px;
    }
    &::after{
        content: "";
        position: absolute;
        height: 0%;
        left: 50%;
        top: 50%;
        width: 150%;
        z-index: -1;
        transition: all 0.75s ease 0s;
    }
    &::before{
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 0px;
        z-index: -1;
        content: '';
        background: $heading-color;
        color: $white;
        transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
    }

    &:hover{
        background: transparent !important;
        border-color: $heading-color;
        color: $white;
        &::before{
            right: 0%;
            left: auto;
            width: 100%;
        }
        &::after{

        }
    }
}
.shop-btn{
    position: relative;
    background: $heading-color;
    border: 2px solid $heading-color;
    padding: 20px 40px;
    font-size: 16px;
    text-transform: uppercase;
    color: $white;
    line-height: 1;
    font-weight: 700;
    z-index: 1;
    // Responsive
    @media #{$desktop-device}{
        padding: 20px 40px;
    }
    @media #{$tablet-device}{
        padding: 20px 40px;
    }
    @media #{$large-mobile}{
        padding: 15px 30px 15px 30px;
    }

    &:hover{
        background: $white;
        border-color: $white;
        color: $heading-color;
    }
}
.submit-btn{
    min-width: 120px;
    padding: 15px 18px;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    text-align: center;
    &:hover{
        background-color: $theme-color-two;
        border-color: $theme-color-two;
        color: $white;
    }
}
.cart-btn{
    border: 0;
    padding: 0 10px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    color: $heading-color;
    &:hover{
        color: $theme-color-two;
    }
}
.load-more-area{
    text-align: center;
    & a{
        font-size: 20px;
        line-height: 34px;
        color: $heading-color;
        display: inline-block;
        &:hover{
            color: $theme-color-two;
            text-decoration: underline;
        }
    }
}




/*-- 
    - Button
------------------------------------------*/
.btn {
    &:focus {
        box-shadow: none;
        outline: none;
    }
    background-color: rgba(0,0,0,0);
    font-family: $heading-font;
    color: $heading-color;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    padding: 20px 60px 20px 60px;
    text-transform: capitalize;
    border: 2px solid rgba(0,0,0,0.34);;
    border-radius: 30px;
    position: relative;
    transition: all 0.3s ease 0s;
    & i{
        margin-left: 5px;
        font-size: 14px;
    }
    &:hover {
        background-color: $heading-color;
        color: $white;
    }
    // Responsive
    @media #{$large-mobile}{
        font-size: 13px;
        line-height: 1.2;
        padding: 10px 15px;
        &:hover {
            &::before {
                left: 6px;
                top: 6px;
            }
            &::after {
                left: -6px;
                top: -6px;
            }
        }
    }
    @media #{$small-mobile}{
    }
    @media #{$extra-small-mobile}{
    }
}
/* Pentagon Icon CSS */
.pentagon-icon{
    & .icon{
        position: relative;
        width: 50px;
        height: 77px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        background-color: $theme-color;
        border-radius: 6px;
        margin: 0 20px;
        // Responsive
        @media #{$large-mobile}{
            width: 38px;
            height: 57px;
        }
        &::before, &::after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: $theme-color;
            transform: rotate(60deg);
            z-index: -1;
            border-radius: 6px;
        }
        &::after {
            transform: rotate(-60deg);
        }
        & i{
            font-size: 26px;
            color: $heading-color;
        }
    }
}


/*-- 
    - Page Banner Section
------------------------------------------*/
.breadcrumb-section {
    padding: 70px 0 72px;
    background-repeat: repeat;
    background-position: left top;
    background-color: #f9f9f9;
    position: relative;
    z-index: 1;
    // Responsive
    @media #{$desktop-device}{
        padding: 60px 0 62px;
    }
    @media #{$tablet-device}{
        padding: 55px 0 52px;
    }
    @media #{$large-mobile}{
        padding: 50px 0 42px;
    }
    &-two{
        padding-top: 260px;
        padding-bottom: 190px;
        // Responsive
        @media #{$laptop-device}{
            padding-top: 200px;
            padding-bottom: 130px;
        }
        @media #{$desktop-device}{
            padding: 60px 0 62px;
        }
        @media #{$tablet-device}{
            padding: 55px 0 52px;
        }
        @media #{$large-mobile}{
            padding: 50px 0 42px;
        }
    }
}
.breadcrumb-title {
    & h1 {
        font-size: 46px;
        line-height: 54px;
        // Responsive
        @media #{$tablet-device}{
            font-size: 36px;
            line-height: 1;
        }
        @media #{$large-mobile}{
            font-size: 30px;
            line-height: 1;
        }
        @media #{$small-mobile}{
            font-size: 24px;
            line-height: 1;
        }
    }
    &-two{
        & h1{
            font-size: 56px;
            line-height: 65px;
            color: $white;
            // Responsive
            @media #{$tablet-device}{
                font-size: 36px;
                line-height: 1;
            }
            @media #{$large-mobile}{
                font-size: 30px;
                line-height: 1;
            }
            @media #{$small-mobile}{
                font-size: 24px;
                line-height: 1;
            }
        }
    }
}
.page-breadcrumb {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    & li {
        color: $heading-color;
        font-size: 14px;
        font-family: $heading-font;
        line-height: 24px;
        margin-top: 10px;
        &::after {
            content: ">";
            margin: 0 10px;
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
        & a {
            &:hover {
                color: $theme-color-two;
            }
        }
    }
    &-two{
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        & li {
            color: $white;
            font-size: 14px;
            font-family: $heading-font;
            line-height: 24px;
            margin-top: 10px;
            &::after {
                content: ">";
                margin: 0 10px;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
            & a {
                &:hover {
                    color: $theme-color-two;
                }
            }
        } 
    }
    &-three{
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 10px 0;
        & li {
            color: $heading-color;
            font-size: 16px;
            font-family: $heading-font;
            line-height: 27px;
            margin-top: 10px;
            // Responsive
            @media #{$tablet-device}{
                font-size: 14px;
                line-height: 18px;
            }
            &::after {
                content: ">";
                margin: 0 10px;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
            & a {
                &:hover {
                    color: $theme-color-two;
                }
            }
        }
    }
}

/*-- 
    - Page Pagination
------------------------------------------*/
.page-pagination {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 20px 0;
    & li {
        margin: 0 5px 0 0;
        & a {
            font-size: 16px;
            text-align: center;
            display: block;
            position: relative;
            background: #fff;
            padding: 15px 10px;
            line-height: 20px;
            min-width: 50px;
            transition: all .3s;
            border: 1px solid rgba(0, 0, 0, 0.1);
            color: #535353;
        }
        // Responsive
        &:hover {
            & a {
                color: $white;
                background-color: $heading-color;
            }
        }
        &.active {
            & a {
                color: $white;
                background-color: $heading-color; 
            }
        }
    }
}

.grid-filter {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    & button {
        background-color: transparent;
        color: $heading-color;
        border: none;
        padding: 0;
        font-size: 14px;
        font-weight: 600;
        margin: 0 10px 10px;
        text-transform: capitalize;
        line-height: 1;
        padding-bottom: 5px;
        position: relative;
        &::before {
            content: "";
            height: 6px;
            width: 0;
            position: absolute;
            left: 0;
            bottom: 4px;
            background-color: $theme-color;
            z-index: -1;
            transition: all 0.3s ease 0s;
        }
        &:hover, &.active {
            &::before {
                width: 100%;
            }
        }
    }
    
    &.center {
        justify-content: center;
        & button {
            margin: 0 10px 10px;
        }
    }
    &.left {
        justify-content: flex-start;
        & button {
            margin-left: 0;
            margin-right: 20px;
            margin-bottom: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &.right {
        justify-content: flex-end;
        & button {
            margin-left: 20px;
            margin-right: 0;
            margin-bottom: 10px;
            &:last-child {
                margin-left: 0;
            }
        }
    }
}



.slick-slider {
    & .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        border: none;
        background-color: rgba(255, 255, 255, 1);
        color: $heading-color;
        padding: 10px;
        width: 60px;
        height: 60px;
        border-radius: 0;
        text-align: center;
        opacity: 0;
        & i {
            font-size: 24px;
            line-height: 30px;
            display: block;
        }
        &.slick-prev {
            left: 40px;
            margin-left: 30px;
            // Responsive
            @media #{$laptop-device}{
                left: 8px;
            }
            @media #{$desktop-device}{
                left: 8px;
            }
            @media #{$tablet-device}{
                left: 8px;
            }
            @media #{$large-mobile}{
                left: 8px;
            }
        }
        &.slick-next {
            right: 40px;
            margin-right: 30px;
            // Responsive
            @media #{$laptop-device}{
                right: 8px;
            }
            @media #{$desktop-device}{
                right: 8px;
            }
            @media #{$tablet-device}{
                right: 8px;
            }
            @media #{$large-mobile}{
                right: 8px;
            }
        }
        &:hover {
            background-color: rgba(255,255,255,0.75);
            color: $heading-color;
        }
    }
    &:hover {
        & .slick-arrow {
            opacity: 1;
            &.slick-prev {
                margin-left: 0;
            }
            &.slick-next {
                margin-right: 0;
            }
        }
    }
    
    & .slick-dots {
        width: 100%;
        padding: 0;
        margin: 35px 0 0;
        display: flex;
        justify-content: center;
        & li {
            margin: 0 15px;
            & button {
                width: 14px;
                height: 14px;
                position: absolute;
                background: rgba(153, 153, 153, 0);
                border: 2px solid rgba(180, 180, 180, 1);
                border-radius: 50%;
                cursor: pointer;
                box-sizing: content-box;
                box-shadow: 0px 0px 2px 1px rgba(130,130,130, 0.3);
                padding: 0;
                text-indent: -9999px;
            }
            &.slick-active {
                & button {
                    background-color: $heading-color;
                    border-color: $heading-color;
                }
            }
        }
    }
}


.gallery-item {
    position: relative;
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: $heading-color;
        z-index: 1;
        opacity: 0;
        transition: all 0.3s ease 0s;
    }
    & img {
        width: 100%;
    }
    & .plus {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 3;
        opacity: 0;
        &::before, &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            background-color: $white;
            transition: all 0.3s ease 0s;
        }
        &::before {
            width: 150px;
            height: 1px;
        }
        &::after {
            width: 1px;
            height: 150px;
        }
    }
    &:hover {
        &::before {
            opacity: 0.75;
        }
        & .plus {
            opacity: 1;
            &::before {
                width: 40px;
            }
            &::after {
                height: 40px;
            }
        }
    }
}


blockquote.blockquote {
    background-color: #f1f2f3;
    padding: 30px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    &::before {
        position: absolute;
        content: url(../images/icons/quote-left.png);
        left: -5px;
        top: -10px;
        z-index: -1;
        opacity: 0.07;
    }
    & p {
        font-size: 18px;
        font-style: italic;
    }
    & .author {
        font-size: 14px;
        display: block;
        line-height: 18px;
    }
}