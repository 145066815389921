/*----------------------------------------*/
/*  20. Countdown CSS
/*----------------------------------------*/
.countdown-content{
    & .title{
        font-size: 56px;
        line-height: 67px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 20px;
        & br{
            // Responsive
            @media #{$tablet-device}{
                display: none;
            }
            @media #{$large-mobile}{
                display: none;
            }
        }
        // Responsive
        @media #{$tablet-device}{
            font-size: 32px;
            line-height: 45px;
            margin-bottom: 10px;
        }
        @media #{$large-mobile}{
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 10px;
        }
    }
    & p{
        font-size: 18px;
        line-height: 36px;
        @media #{$large-mobile}{
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0;
        }
    }
}
.countdown-area{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    & .single-countdown{
        flex-flow: unset;
        align-items: baseline;
        margin: 50px 40px 60px 0px;
        text-align: left;
        // Responsive
        @media #{$desktop-device}{
            margin: 30px 25px 40px 0px;
        }
        @media #{$tablet-device}{
            margin: 30px 25px 40px 0px;
        }
        @media #{$large-mobile}{
            margin: 30px 15px 30px 0px;
        }
        @media #{$small-mobile}{
            margin: 20px 6px 20px 0px;
        }
        & .single-countdown-time{
            color: $heading-color;
            font-size: 24px;
            font-weight: 400;
            font-style: italic;
            margin: 0px 10px 0px 0px;
            // Responsive
            @media #{$small-mobile}{
                font-size: 18px;
                margin: 0px;
            }
        }
        & .single-countdown-text{
            color: $heading-color;
            font-size: 14px;
            font-weight: 400;
            font-style: italic;
            // Responsive
            @media #{$small-mobile}{
                font-size: 12px;
            }
        }
    }
}
.countdown-banner{
    position: relative;
}
.banner-offer{
    position: absolute;
    top: 40%;
    left: 0;
    max-width: 100%;
    & .banner-sale-headline{
        color: #d94141;
        font-size: 56px;
        line-height: 96px; 
        font-weight: 400;
        letter-spacing: 2px;
        text-align: left;
        display: block;
        // Responsive
        @media #{$desktop-device}{
            font-size: 48px;
            line-height: 82px; 
        }
        @media #{$large-mobile}{
            font-size: 32px;
            line-height: 40px;
        }
    }
    & .normal-headline{
        color: $heading-color;
        font-size: 24px;
        font-style: italic;
        letter-spacing: 0.8px;
        text-align: left;
        display: block;
        // Responsive
        @media #{$desktop-device}{
            font-size: 16px;
            line-height: 27px; 
        }
        @media #{$large-mobile}{
            font-size: 18px;
        }
    }
}
.offer-product-name{
    text-align: right;
    top: 44%;
    right: 0px;
    max-width: 100%;
    width: 170px;
    & p{
        font-size: 16px;
        line-height: 32px;
        color: $heading-color;
        text-align: right;
        // Responsive
        @media #{$small-mobile}{
            font-size: 12px;
        }
    }
}
.banner-image{
    margin: 0px 0px 0px 160px;
    max-width: 100%;
    text-align: center;
    // Responsive
    @media #{$large-mobile}{
        margin: 0px 0px 0px 60px;
    }
    img{
        width: 311px;
    }
}