/*----------------------------------------*/
/*  27. Team CSS
/*----------------------------------------*/

/* Single Team CSS */
.single-team {
    position: relative;
    z-index: 2;
    & .thumbnail{
        position: relative;
        &::before{
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 2;
            transition: all .4s .1s;
            content: "";
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0;
        }
        & .hover-action{
            text-align: center;
            z-index: 2;
            bottom: 20px;
            opacity: 0;
            transition: all .4s .1s;
            transform: translateY(20px);
            -webkit-transform: translateY(20px);
            position: absolute;
            width: 100%;
            & .social-icons{
                margin: -5px;
                & a{
                    background-color: transparent;
                    border: 1px solid $white;
                    color: $white;
                    width: 38px;
                    height: 38px;
                    line-height: 38px;
                    cursor: pointer;
                    border-radius: 50%;
                    font-size: 16px;
                    display: inline-block;
                    margin: 5px;
                    &:hover{
                        color: $white;
                        background-color: $theme-color-two;
                        border-color: $theme-color-two;
                    }
                }
            }
        }
    }
    & .info{
        text-align: left;
        padding-top: 25px;
        padding-bottom: 15px;
        // Responsive
        @media #{$tablet-device}{
            padding-top: 15px;
            padding-bottom: 10px;
        }
        @media #{$large-mobile}{
            padding-top: 15px;
            padding-bottom: 10px;
        }
        & .title{
            font-size: 24px;
            line-height: 24px;
            margin: 0px 0px 8px 0px;
            // Responsive
            @media #{$tablet-device}{
                font-size: 18px;
                line-height: 20px;
                margin-bottom: 0;
            }
            @media #{$large-mobile}{
                font-size: 20px;
                line-height: 22px;
                margin-bottom: 0;
            }
        }
        & .desc{
            color: #919191;
            font-size: 16px;
            line-height: 27px;
            font-style: italic;
            // Responsive
            @media #{$tablet-device}{
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    &:hover{
        & .thumbnail{
            &::before{
                opacity: 0.5;
            }
            & .hover-action{
                opacity: 1;
                transform: translateY(0);
                -webkit-transform: translateY(0);
            }
        }
    }
}